import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Typography } from "@app/components/typography";

import styles from "../index.module.css";
import { showMappedReasons } from "../show-mapped-reasons";
import type { InputFieldPartialProps } from "../types";

import { FieldError } from "@app/components/field-error";
import inputStyles from "./input-field.module.css";
import clsx from "clsx";

export const InputField = ({
	name,
	formControl,
	required = false,
	minLength,
	maxLength,
	pattern,
	mappedName,
	fieldRowClassName,
	showLabel,
	title,
	icon,
	...props
}: InputFieldPartialProps) => {
	return (
		<Controller
			name={name}
			control={formControl}
			rules={{
				required: required ? "This field is required" : false,
				minLength,
				maxLength,
				pattern,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				const mappedReason = showMappedReasons(
					mappedName ?? field.name,
					props.mappedReasons,
				);
				const hasError = error || mappedReason;

				return (
					<div className={clsx(fieldRowClassName, styles.inputRow)}>
						{showLabel ? (
							<Typography theme="textSm" className={styles.formLabel}>
								{title + (required && !props.hideAsterisk ? "*" : "")}
							</Typography>
						) : (
							<></>
						)}
						<input
							className={twMerge(
								inputStyles.input,
								props.className,
								styles.formBuilderInput,
								hasError && inputStyles.error,
								props.disabled ? styles.inputDisabled : "",
							)}
							data-has-icon={!!icon}
							style={{
								paddingRight: props.actions ? "52px" : undefined,
							}}
							required={required}
							type={props.type ?? "text"}
							inputMode={props.inputMode}
							{...field}
							value={field.value ?? props.value}
							placeholder={props.placeholder}
							onChange={(event) => {
								let value = event.target.value;

								if (props.inputMode === "numeric") {
									value = value.replace(/\D/g, "");
								}

								field.onChange(value);
								if (props.onChange) props.onChange(value);
							}}
							onBlur={(event) => {
								field.onBlur();
								if (props.onBlur) props.onBlur(event);
							}}
							onFocus={props.onFocus}
							disabled={props.disabled}
						/>

						{props.actions}
						{icon && <div className={inputStyles.icon}>{icon}</div>}
						{error ? <FieldError>{error.message}</FieldError> : mappedReason}
					</div>
				);
			}}
		/>
	);
};
