import { LogoutResumeLaterModal } from "@app/features/onboarding/logout-resume-later-modal";
import * as Popover from "@radix-ui/react-popover";

import chevronUpSrc from "./chevron-up.svg";
import globeIconSrc from "./globe.svg";
import mailIconSrc from "./mail.svg";
import settingsIconSrc from "./settings.svg";
import addAccountIconSrc from "./add-account.svg";

import { ProfilePicture } from "@app/components/profile-picture";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import { useUserProfile } from "@app/features/user-settings/use-user-profile";
import { useAuth } from "@app/hooks/use-auth";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { useProfilePicture } from "@app/hooks/use-profile-picture";
import { useState } from "react";
import { FiHelpCircle, FiLogOut } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { useClients } from "@app/hooks/use-clients";
import { isAccountMenuEnabled } from "@app/constants/feature-flags";

export const UserItem = ({
	variant,
	isSettings = false,
	hasMultipleClients = false,
}: {
	variant: "default" | "onboarding";
	isSettings?: boolean;
	hasMultipleClients?: boolean;
}) => {
	const { data: userProfile } = useUserProfile();
	const { data: clientProfile } = useClientProfile();
	const { count } = useClients();
	const [isOpen, setIsOpen] = useState(false);
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const navigate = useNavigate();
	const { onLogout } = useAuth();

	const { data: profilePicture } = useProfilePicture();

	const onSettingsClick = () => {
		if (variant === "default") {
			navigate(paths().userSettings());
		} else {
			navigate(paths().onboarding.userSettings);
		}
	};

	const isSingleIndividualClient =
		isAccountMenuEnabled &&
		count === 1 &&
		clientProfile?.entity_type === "individual";

	const name = hasMultipleClients
		? userProfile?.email
		: (clientProfile?.display_name ?? userProfile?.email);

	return (
		<>
			<Popover.Root open={isOpen} onOpenChange={setIsOpen}>
				<Popover.Trigger className={styles.trigger} aria-label="User menu">
					<ProfilePicture src={profilePicture?.base64} />
					{name}
					<img
						src={chevronUpSrc}
						alt=""
						width={17}
						height={17}
						className={styles.chevron}
						data-open={isOpen}
					/>
				</Popover.Trigger>
				<Popover.Content sideOffset={8} align="end" className={styles.popover}>
					<ul className={styles.list}>
						{!isSettings && (
							<li>
								<button
									className={styles.item}
									type="button"
									onClick={() => onSettingsClick()}
								>
									<img src={settingsIconSrc} alt="" />
									Settings
								</button>
							</li>
						)}
						<li>
							<a
								className={styles.item}
								href={links.contact}
								target="_blank"
								rel="noreferrer"
							>
								<img src={mailIconSrc} alt="" />
								Contact us
							</a>
						</li>
						{isSingleIndividualClient && (
							<li>
								<Link
									className={styles.item}
									to={paths().onboarding.individual.addClient}
								>
									<img width={20} height={20} src={addAccountIconSrc} alt="" />
									Add account
								</Link>
							</li>
						)}
						<li>
							<a
								className={styles.item}
								href={links.forexFaq}
								target="_blank"
								rel="noreferrer"
							>
								<FiHelpCircle size={20} color="#56A7A2" />
								FAQs
							</a>
						</li>
						<li>
							<a
								className={styles.item}
								href={links.website}
								target="_blank"
								rel="noreferrer"
							>
								<img src={globeIconSrc} alt="" />
								Visit website
							</a>
						</li>
						<li className={styles.seperator} />
						<li>
							<button
								className={styles.item}
								type="button"
								onClick={() => {
									const isOnboardingRoute =
										location.pathname.includes("onboarding");

									if (isOnboardingRoute) {
										setShowLogoutModal(true);
									} else {
										onLogout();
									}
								}}
							>
								<FiLogOut size={20} color="#56A7A2" />
								Log out
							</button>
						</li>
					</ul>
				</Popover.Content>
			</Popover.Root>

			{showLogoutModal && (
				<LogoutResumeLaterModal
					onBack={() => setShowLogoutModal(false)}
					onClose={() => setShowLogoutModal(false)}
				/>
			)}
		</>
	);
};
