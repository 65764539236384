import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { SingleSelectDialog } from "@app/components/single-select-dialog";
import { SettlementAccount } from "@app/hooks/use-settlement-account-options";

import styles from "./index.module.css";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { Dropdown } from "@app/components/controls";

export const SettlementAccountDropdown = ({
	options,
	value,
	onChange,
	error,
}: {
	options?: SettlementAccount[];
	value?: number;
	onChange: (value: number) => void;
	error?: false | JSX.Element;
}) => {
	const isMobile = useMediaQuery();
	if (!options || options.length <= 1) return null;

	const selectedOption = options.find(
		(option) => option.bank_account_id === value,
	);
	return (
		<div className={styles.container}>
			<div className={styles.label}>
				<p>Settlement Account</p>
				<MoreInfoTooltip name="Current Account" hasIcon maxWidth={300}>
					Your account type is actually a "Trading account", but for purposes of
					depositing funds from other banks you should select "Current” as the
					account option.
				</MoreInfoTooltip>
			</div>
			{isMobile ? (
				<SingleSelectDialog
					className={styles.trigger}
					options={options.map((option) => ({
						name: `${option.account_type} (${option.account_number})`,
						id: option.bank_account_id,
					}))}
					renderOption={(option) => {
						const current = options?.find(
							(x) => x.bank_account_id === option.id,
						);
						return (
							<div className={styles.dialogOption}>
								<div>
									<p>{current?.account_type}</p>
									<p>({current?.account_number})</p>
								</div>
								{current?.primary_account && (
									<span className={styles.primaryTag}>Primary</span>
								)}
							</div>
						);
					}}
					renderValue={(option) => {
						const current = options?.find(
							(x) => x.bank_account_id === option.id,
						);
						return (
							<div className={styles.dialogOption}>
								<div>
									<p>{current?.account_type}</p>
									<p>({current?.account_number})</p>
								</div>
								{current?.primary_account && (
									<span className={styles.primaryTag}>Primary</span>
								)}
							</div>
						);
					}}
					value={
						selectedOption
							? {
									name: selectedOption.account_type,
									id: selectedOption.bank_account_id,
								}
							: undefined
					}
					onChange={(option) => {
						onChange(option.id as number);
					}}
					title="Select a settlement account"
					placeholder="Select a settlement account"
				/>
			) : (
				<Dropdown
					className={styles.dropdown}
					options={options.map((x) => x.bank_account_id) ?? []}
					value={value}
					valueTemplate={() => {
						const current = options?.find(
							(option) => option.bank_account_id === value,
						);
						return current ? (
							<p className={styles.option}>
								{current.account_type} ({current.account_number})
								{current.primary_account && (
									<span className={styles.primaryTag}>Primary</span>
								)}
							</p>
						) : (
							<p>Select a settlement account</p>
						);
					}}
					itemTemplate={(option) => {
						if (option) {
							const current = options?.find(
								(x) => x.bank_account_id === option,
							);
							if (current)
								return (
									<p className={styles.option}>
										{current.account_type} ({current.account_number})
										{current.primary_account && (
											<span className={styles.primaryTag}>Primary</span>
										)}
									</p>
								);
						}
					}}
					onChange={(bankAccountId: number) => {
						onChange(bankAccountId);
					}}
				/>
			)}
			{error}
		</div>
	);
};
