import { useParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";

export type PersonalInformation = {
	first_name: string;
	middle_names: string;
	last_name: string;
	email_address: string;
	contact_number: string;
	id_number: string;
	tax_number: string;
	id_document: string;
	id_selfie: string;
};

export type PersonalInformationUpdate = {
	first_name: string;
	middle_names: string;
	last_name: string;
	email_address: string;
	contact_number: string;
	id_number: string;
	tax_number: string;
};

export const usePersonalInformation = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { clientId } = useParams();
	const activeClientId = searchParams.get("clientId") ?? clientId;

	const { data, error, mutate } = useSWR<PersonalInformation>(
		activeClientId
			? `/onboarding/${activeClientId}/personal-information/`
			: null,
	);

	const setClientIdInParams = (newClientId: string) => {
		const newParams = new URLSearchParams(searchParams);
		newParams.set("clientId", newClientId);
		setSearchParams(newParams);
	};

	const handleUpdate = async (
		newData: Partial<PersonalInformationUpdate>,
		clientIdOverride?: number,
	) => {
		try {
			await api.patch(
				`/onboarding/${clientIdOverride ?? activeClientId}/personal-information/`,
				newData,
			);
			mutate();
		} catch (error: any) {
			return getFormErrors(error);
		}
	};

	return {
		data,
		error,
		clientIdOverride: activeClientId,
		create: async (newData: Partial<PersonalInformationUpdate>) => {
			try {
				const { data } = await api.post("/clients/", {
					first_name: newData.first_name,
					last_name: newData.last_name,
				});
				const clientID = data.client_id;
				setClientIdInParams(clientID);
				return handleUpdate(newData, clientID);
			} catch (error: any) {
				return getFormErrors(error);
			}
		},
		update: handleUpdate,
		submit: async (newData: PersonalInformationUpdate) => {
			try {
				await api.put(
					`/onboarding/${activeClientId}/personal-information/`,
					newData,
				);
				mutate();
			} catch (error: any) {
				return getFormErrors(error);
			}
		},
	};
};
