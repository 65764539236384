import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { isSuperTooltipEnabled } from "@app/constants/feature-flags";

import styles from "./super-tooltip.module.css";
import { useMediaQuery } from "@app/hooks/use-media-query";

const superTooltipMap = {
	Invoice: (
		<div className={styles.content}>
			<p>
				Please make sure your Invoice includes all of the following information:
			</p>
			<ul>
				<li>Supplier/beneficiary full company name</li>
				<li>
					Supplier/beneficiary full physical address (street Address, PO Box
					Addresses not Accepted)
				</li>
				<li>Client’s full company name</li>
				<li>
					Client’s full physical address (street address, PO box addresses not
					accepted)
				</li>
				<li>Invoice must include foreign currency amount</li>
				<li>
					Only invoices (pro forma & commercial) will be accepted – (no quotes /
					statements/ reservations)
				</li>
			</ul>
		</div>
	),
	"SAD 500": (
		<div className={styles.content}>
			<p>
				Please make sure your SAD500 includes all of the following information
			</p>
			<ul>
				<li>Supplier/beneficiary full company name</li>
				<li>
					Supplier/beneficiary full physical address (street address, PO box
					addresses not accepted)
				</li>
				<li>Client’s full company name</li>
				<li>
					Client’s full physical address (street address, PO box addresses not
					accepted)
				</li>
				<li>Supplier invoice number and date</li>
				<li>MRN and LRN number</li>
			</ul>
		</div>
	),
	"EDI Notification": (
		<div className={styles.content}>
			<p>
				Please make sure your EDI includes all of the following information:
			</p>
			<ul>
				<li>Supplier/beneficiary full company name </li>
				<li>Client’s full company name</li>
				<li>MRN and LRN number</li>
				<li>Master transport document number</li>
				<li>EDI status must state – “released”</li>
			</ul>
		</div>
	),
	"Customs Worksheet": (
		<div className={styles.content}>
			<p>
				Please make sure your Customs Worksheet includes all of the following
				information:
			</p>
			<ul>
				<li>Supplier/beneficiary full company name </li>
				<li>
					Supplier/beneficiary full physical address (street address, PO box
					addresses not accepted)
				</li>
				<li>Client’s full company name</li>
				<li>
					Client’s full physical address (street address, PO box addresses not
					accepted)
				</li>
				<li>MRN and LRN number</li>
				<li>Invoice number/s</li>
				<li>Transport document number</li>
				<li>Correct invoiced amounts</li>
				<li>Waybill/bill of lading document number</li>
			</ul>
		</div>
	),
	"Bill of Lading": (
		<div className={styles.content}>
			<p>
				Please make sure your Bill of Lading includes all of the following
				information:
			</p>
			<ul>
				<li>Supplier/beneficiary full company name </li>
				<li>Client’s full company name</li>
				<li>Invoice number/s</li>
				<li>Waybill/bill of lading number</li>
				<li>Correct invoiced amounts</li>
			</ul>
		</div>
	),
};

export const isSuperTooltip = (documentName?: string) => {
	if (!isSuperTooltipEnabled || !documentName) return false;
	return !!superTooltipMap[documentName as keyof typeof superTooltipMap];
};

export const SuperTooltip = ({
	documentName,
	variant = "inline",
}: { documentName?: string; variant?: "inline" | "modal" }) => {
	const isMobile = useMediaQuery();
	if (!isSuperTooltip(documentName)) return null;
	const showBreak = variant === "modal" || isMobile;
	const text =
		variant === "modal"
			? "Ensure your document meets all requirements."
			: "Please review document requirements";
	return (
		<p className={styles.superTooltip} data-variant={variant}>
			{text}
			{showBreak && <br />} (
			<MoreInfoTooltip
				maxWidth={480}
				variant="persistent"
				name={documentName}
				className={styles.superTooltipButton}
				indicator="view requirements"
			>
				{superTooltipMap[documentName as keyof typeof superTooltipMap]}
			</MoreInfoTooltip>
			)
		</p>
	);
};
