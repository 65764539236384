import clsx from "clsx";

import styles from "./default-cell.module.css";

export const DefaultCell = ({
	children,
}: {
	children?: React.ReactNode;
}) => {
	const isEmpty = !children || children === "N/A";
	return <span className={clsx(isEmpty && styles.empty)}>{children}</span>;
};
