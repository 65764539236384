import { useSearchParams } from "react-router-dom";
import { InitOTPDeviceDisableFlow } from "../init-otp-device-disable-flow";
import { InitOTPDeviceFlow } from "../init-otp-device-flow";
import { ConfirmationEnableOtpModal } from "../modals/confirmation-enable-otp-modal";
import { TrustDeviceFlow } from "../trust-device-flow";
import { useState } from "react";

export const OtpOnboarding = ({ onComplete }: { onComplete: () => void }) => {
	const [searchParams] = useSearchParams();
	const [showOTPConfirmationModal, setShowOTPConfirmationModal] = useState(
		searchParams.get("otp") === "init",
	);
	const [showDisableOTP, setShowDisableOTP] = useState(false);
	const [showEnableOTP, setShowEnableOTP] = useState(false);

	const showTrustDeviceFlow = searchParams.get("otp") === "trust";

	return (
		<>
			<ConfirmationEnableOtpModal
				isOpen={showOTPConfirmationModal}
				onClose={() => {
					setShowOTPConfirmationModal(false);
					setShowDisableOTP(true);
				}}
				onConfirm={() => {
					setShowOTPConfirmationModal(false);
					setShowEnableOTP(true);
				}}
			/>

			<InitOTPDeviceFlow
				isOpen={showEnableOTP}
				onClose={() => {
					setShowEnableOTP(false);
					setShowOTPConfirmationModal(true);
				}}
				onComplete={onComplete}
			/>

			<InitOTPDeviceDisableFlow
				isOpen={showDisableOTP}
				onClose={() => {
					setShowDisableOTP(false);
					setShowOTPConfirmationModal(true);
				}}
				onComplete={onComplete}
			/>

			<TrustDeviceFlow isOpen={showTrustDeviceFlow} onComplete={onComplete} />
		</>
	);
};
