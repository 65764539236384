import { useEffect, useState } from "react";
import { ConfirmedDeviceUntrustedModal } from "../modals/confirmed-device-untrusted-modal";
import { ConfirmationDeviceUntrustedModal } from "../modals/confirmation-device-untrusted-modal";

export const UntrustDeviceFlow = ({
	onComplete,
	isOpen,
}: {
	onComplete: () => void;
	isOpen: boolean;
}) => {
	const [step, setStep] = useState<"confirm" | "confirmed" | undefined>(
		undefined,
	);

	useEffect(() => {
		setStep(isOpen ? "confirm" : undefined);
	}, [isOpen]);

	return (
		<>
			{/* Step 1: Confirm revoke trust for this device */}
			<ConfirmationDeviceUntrustedModal
				isOpen={step === "confirm"}
				onCancel={onComplete}
				onConfirm={() => setStep("confirmed")}
			/>

			{/* Step 2: Confirmation of trust revoked */}
			<ConfirmedDeviceUntrustedModal
				isOpen={step === "confirmed"}
				onClose={onComplete}
			/>
		</>
	);
};
