import styles from "./index.module.css";

import checkedSrc from "./checked.svg";

export const SingleSelectIndicator = ({
	isChecked,
}: {
	isChecked: boolean;
}) => {
	if (isChecked) {
		return <img src={checkedSrc} alt="" width={16} height={16} />;
	}

	return <div className={styles.unchecked} />;
};
