import { ReactNode } from "react";

import styles from "./index.module.css";

import inProgressSrc from "./in-progress.svg";
import completeSrc from "./complete.svg";
export const TransactionStatus = ({
	children,
}: {
	children: ReactNode;
}) => {
	if (typeof children !== "string") return null;

	const variant = children.toLowerCase();

	return (
		<div className={styles.container} data-variant={variant}>
			{variant === "in progress" && (
				<img src={inProgressSrc} alt="" width={16} height={16} />
			)}
			{variant === "complete" && (
				<img src={completeSrc} alt="" width={16} height={16} />
			)}
			{children}
		</div>
	);
};
