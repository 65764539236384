interface EnvironmentConfig {
	hostName: string;
	mainUrl: string;
	wsUrl: string;
}

export const ENVIRONMENTS = {
	PROD: {
		hostName: "account.futureforex.co.za",
		mainUrl: "https://main.futureforex.co.za/",
		wsUrl: "wss://main.futureforex.co.za/",
	},
	DEMO: {
		hostName: "demo-account.futureforex.co.za",
		mainUrl: "https://demo-main.futureforex.co.za/",
		wsUrl: "wss://demo-main.futureforex.co.za/",
	},
	TEST: {
		hostName: "test-account.futureforex.co.za",
		mainUrl: "https://test-main.futureforex.co.za/",
		wsUrl: "wss://test-main.futureforex.co.za/",
	},
	TEST2: {
		hostName: "test2-account.futureforex.co.za",
		mainUrl: "https://test2-main.futureforex.co.za/",
		wsUrl: "wss://test2-main.futureforex.co.za/",
	},
	TEST3: {
		hostName: "test3-account.futureforex.co.za",
		mainUrl: "https://test3-main.futureforex.co.za/",
		wsUrl: "wss://test3-main.futureforex.co.za/",
	},
} as const satisfies Record<string, EnvironmentConfig>;

const checkIsIOS = () => {
	const IOS_PLATFORMS = [
		"iPad Simulator",
		"iPhone Simulator",
		"iPod Simulator",
		"iPad",
		"iPhone",
		"iPod",
	] as const;

	return (
		IOS_PLATFORMS.includes(
			navigator.platform as (typeof IOS_PLATFORMS)[number],
		) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	);
};

// Environment detection
export const isLocalhost = window.location.hostname === "localhost";
export const isProd = window.location.hostname === ENVIRONMENTS.PROD.hostName;
export const isDemo = window.location.hostname === ENVIRONMENTS.DEMO.hostName;
export const isTest = window.location.hostname === ENVIRONMENTS.TEST.hostName;
export const isTest2 = window.location.hostname === ENVIRONMENTS.TEST2.hostName;
export const isTest3 = window.location.hostname === ENVIRONMENTS.TEST3.hostName;
export const isDevServer = import.meta.env.DEV;
export const isPWA = !!(window.navigator as Navigator).standalone;
export const isLocalOrTest = isLocalhost || isTest || isTest2 || isTest3;
export const isIOS = checkIsIOS();
export const isAppBeta = isPWA && isLocalOrTest;

// API URLs
export const arbitrageApiUrl = "https://srv.futureforex.co.za/";

// Determine environment-specific URLs
const getCurrentEnvironment = (): EnvironmentConfig => {
	if (isProd) return ENVIRONMENTS.PROD;
	if (isTest2) return ENVIRONMENTS.TEST2;
	if (isTest3) return ENVIRONMENTS.TEST3;
	if (isDemo) return ENVIRONMENTS.DEMO;
	return ENVIRONMENTS.TEST;
};

const currentEnv = getCurrentEnvironment();
export const apiUrl = currentEnv.mainUrl;
export const wsUrl = currentEnv.wsUrl;
