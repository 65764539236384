import { NavigationStep } from "@app/components/navigation-steps";
import { paths } from "@app/constants/paths";
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountManagerRow } from "./account-manager-row";
import chevronDown from "./chevron-down.svg";
import styles from "./index.module.css";
import { ProgressCirlce } from "./progress-circle";
import { UserRow } from "./user-row";
import { isAccountsEnabled } from "@app/constants/feature-flags";
import { useClients } from "@app/hooks/use-clients";
import { AccountsBanner } from "../mobile-header/accounts-banner";

export const MobileStepsNavigation = ({
	step,
	steps,
	children,
	onLogout,
	title,
	subtitle,
	id,
	onShowMenuChange,
	showMenu,
	isAddClient = false,
}: {
	step: number;
	steps: Array<NavigationStep>;
	children: ReactNode;
	onLogout: () => void;
	title: string;
	subtitle?: string;
	id?: string;
	onShowMenuChange: (showMenu: boolean) => void;
	showMenu: boolean;
	isAddClient?: boolean;
}) => {
	const navigate = useNavigate();
	const progressValue = ((step + 1) / steps.length) * 100;
	const { count } = useClients();
	const location = useLocation();
	const isAccountsRoute = location.pathname.includes("/accounts");
	const isSettingsRoute = location.pathname.includes("/user-settings");

	const hasMultipleClients =
		isAccountsEnabled &&
		typeof count === "number" &&
		count > 1 &&
		!isAccountsRoute &&
		!isSettingsRoute;

	useEffect(() => {
		document.body.style.overflow = showMenu ? "hidden" : "auto";
	}, [showMenu]);

	return (
		<>
			<div className={styles.container}>
				{hasMultipleClients && <AccountsBanner isAddClient={isAddClient} />}
				<header
					id={id}
					className={styles.header}
					data-menu-open={showMenu}
					onClick={() => onShowMenuChange(!showMenu)}
				>
					<div className={styles.progress}>
						<ProgressCirlce value={progressValue} />
						{step + 1}/{steps.length}
					</div>
					<div className={styles.content}>
						<h1 className={styles.title}>{title}</h1>
						{subtitle && <p>{subtitle}</p>}
					</div>
					<button
						type="button"
						aria-label="menu"
						className={styles.menuButton}
						data-open={showMenu}
						onClick={(event) => {
							event.stopPropagation();
							onShowMenuChange(!showMenu);
						}}
					>
						<img src={chevronDown} alt="" width={24} height={24} />
					</button>
				</header>
			</div>

			<div
				className={styles.menu}
				data-open={showMenu}
				data-has-multiple-clients={hasMultipleClients}
			>
				{children}
				<div className={styles.userMenu}>
					<UserRow
						onSettings={() => {
							onShowMenuChange(false);
							navigate(paths().onboarding.userSettings);
						}}
						onLogout={() => {
							onShowMenuChange(false);
							onLogout();
						}}
					/>
					<div className={styles.divider} />
					<AccountManagerRow />
				</div>
			</div>
		</>
	);
};
