import { Title } from "@app/components/title";
import { LoginSelection } from "../login/login-selection";
import { useLogin } from "../login/use-login";
import { paths } from "@app/constants/paths";

export const SelectLogin = () => {
	const { onGoToArb } = useLogin();

	return (
		<>
			<Title>Welcome back</Title>
			<LoginSelection
				onForexClick={() => {
					window.location.href = paths().root;
				}}
				onArbClick={onGoToArb}
				isLoading={false}
			/>
		</>
	);
};
