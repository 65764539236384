import useSWR from "swr";

export type ExchangeDetails = {
	transaction_id: number;
	fx_amount: string;
	zar_amount: string;
	currency_code: string;
	direction: string;
	payment_ids: number[];
	transaction_date: string;
	reference_id: string;
	recipient_name: string;
	zar_amount_tooltip: string;
	progress_status?: {
		id: number;
		description: string;
		stage: number;
		add_information: boolean;
	};
	exchange_rate_components: {
		fx_component: string;
		zar_component: string;
	};
	spread: string;
	settlement_account: string;
	fec: {
		is_fec: boolean;
		start_date: string;
		end_date: string;
		maturity_date: string;
		fec_type: string;
	};
	deal_confirmation_ready: boolean;
	exchange_rate: string;
};

export const tempExchangeDetailsMapping = (data?: ExchangeDetails) => {
	if (!data) return undefined;
	return {
		currencyCode: data.currency_code,
		dealConfirmationReady: data.deal_confirmation_ready,
		direction: data.direction,
		paymentIds: data.payment_ids,
		exchangeRateComponents: {
			fxComponent: data.exchange_rate_components.fx_component,
			zarComponent: data.exchange_rate_components.zar_component,
		},
		fec: {
			endDate: data.fec.end_date,
			fecType: data.fec.fec_type,
			isFec: data.fec.is_fec,
			maturityDate: data.fec.maturity_date,
			startDate: data.fec.start_date,
		},
		fxAmount: data.fx_amount,
		settlementAccount: data.settlement_account,
		spread: data.spread,
		transactionDate: data.transaction_date,
		referenceId: data.reference_id,
		transactionId: data.transaction_id,
		zarAmount: data.zar_amount,
		exchangeRate: data.exchange_rate,
	};
};

export const useExchangeDetails = (
	transactionId?: number | null,
	isEnabled = true,
) =>
	useSWR<ExchangeDetails>(
		transactionId && isEnabled
			? `/transactions/${transactionId}/exchange-details/`
			: null,
	);
