import { ConfirmedModal } from "@app/components/confirmed-modal";

export const ConfirmedOTPNumberChangedModal = ({
	onClose,
	isOpen,
}: {
	onClose: () => void;
	isOpen: boolean;
}) => {
	return (
		<ConfirmedModal
			isOpen={isOpen}
			onClose={onClose}
			title="OTP number changed"
			description="The mobile number used for OTP authentication has been changed successfully."
		/>
	);
};
