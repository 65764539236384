import { ConfirmedModal } from "../../../../components/confirmed-modal";

export const ConfirmedDeviceTrustedModal = ({
	onClose,
	isOpen = true,
	completeText = "Continue",
}: {
	onClose: () => void;
	isOpen?: boolean;
	completeText?: string;
}) => {
	return (
		<ConfirmedModal
			isOpen={isOpen}
			onClose={onClose}
			title="Device trusted"
			completeText={completeText}
			description="This is now a trusted device and OTP authentication will not be required for future logins."
		/>
	);
};
