import {
	FloatingPortal,
	autoUpdate,
	flip,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useMergeRefs,
	useRole,
	useTransitionStyles,
} from "@floating-ui/react";
import type { Placement } from "@floating-ui/react";
import {
	type HTMLProps,
	type ReactNode,
	cloneElement,
	createContext,
	forwardRef,
	isValidElement,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

interface TooltipOptions {
	initialOpen?: boolean;
	placement?: Placement;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	delay?: number;
	persistent?: boolean;
}

export function useTooltip({
	initialOpen = false,
	placement = "right-start",
	open: controlledOpen,
	delay = 200,
	onOpenChange: setControlledOpen,
	persistent = false,
}: TooltipOptions = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
	const [isPersistent, setIsPersistent] = useState(false);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		placement,
		open,
		onOpenChange: (isOpen) => {
			if (isPersistent && !isOpen) return;
			setOpen(isOpen);
		},
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(6),
			flip({
				fallbackPlacements: ["right-end", "left-start", "left-end"],
				crossAxis: false,
				fallbackAxisSideDirection: "none",
				padding: 6,
			}),
			shift({ padding: 6 }),
		],
	});

	const context = data.context;

	const { isMounted, styles } = useTransitionStyles(context, {
		duration: 300,
	});

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null,
		delay,
	});

	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});

	const click = useClick(context, {
		enabled: controlledOpen == null,
		event: persistent ? "click" : undefined,
		toggle: !persistent,
		ignoreMouse: !persistent,
	});

	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const interactions = useInteractions([hover, focus, dismiss, role, click]);

	useEffect(() => {
		if (!persistent) return;

		const handleClick = (e: MouseEvent) => {
			const referenceEl = context.refs.reference.current as Element | null;
			const floatingEl = context.refs.floating.current as Element | null;

			// If tooltip is already open and we click the trigger, make it persistent
			if (open && referenceEl?.contains(e.target as Node)) {
				setIsPersistent(true);
				e.stopPropagation();
			}

			// If in persistent mode and clicking outside both tooltip and trigger, close it
			if (
				isPersistent &&
				floatingEl &&
				!floatingEl.contains(e.target as Node) &&
				referenceEl &&
				!referenceEl.contains(e.target as Node)
			) {
				setIsPersistent(false);
				setOpen(false);
			}
		};

		document.addEventListener("click", handleClick);
		return () => document.removeEventListener("click", handleClick);
	}, [context.refs, open, isPersistent, persistent, setOpen]);

	return useMemo(
		() => ({
			open,
			setOpen,
			isMounted,
			styles,
			isPersistent,
			setIsPersistent,
			persistent,
			...interactions,
			...data,
		}),
		[
			open,
			setOpen,
			interactions,
			data,
			isMounted,
			styles,
			isPersistent,
			persistent,
		],
	);
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

export const useTooltipContext = () => {
	const context = useContext(TooltipContext);

	if (context == null) {
		throw new Error("Tooltip components must be wrapped in <Tooltip />");
	}

	return context;
};

export function Tooltip({
	children,
	...options
}: { children: ReactNode } & TooltipOptions) {
	const tooltip = useTooltip(options);
	return (
		<TooltipContext.Provider value={tooltip}>
			{children}
		</TooltipContext.Provider>
	);
}

export const TooltipTrigger = forwardRef<
	HTMLElement,
	HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
	const context = useTooltipContext();
	const childrenRef = (children as any).ref;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

	// `asChild` allows the user to pass any element as the anchor
	if (asChild && isValidElement(children)) {
		return cloneElement(
			children,
			context.getReferenceProps({
				ref,
				...props,
				...children.props,
				"data-state": context.open ? "open" : "closed",
				"data-persistent": context.isPersistent ? "true" : "false",
			}),
		);
	}

	return (
		<button
			type="button"
			ref={ref}
			// The user can style the trigger based on the state
			data-state={context.open ? "open" : "closed"}
			data-persistent={context.isPersistent ? "true" : "false"}
			{...context.getReferenceProps(props)}
		>
			{children}
		</button>
	);
});

export const TooltipContent = forwardRef<
	HTMLDivElement,
	HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, className, ...props }, propRef) {
	const context = useTooltipContext();
	const ref = useMergeRefs([context.refs.setFloating, propRef]);

	if (!context.open || !context.isMounted) return null;

	return (
		<FloatingPortal>
			<div
				ref={ref}
				style={{
					...context.floatingStyles,
					...context.styles,
					...style,
				}}
				data-persistent={context.isPersistent ? "true" : "false"}
				className={className}
				{...context.getFloatingProps(props)}
			/>
		</FloatingPortal>
	);
});
