import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export const useTrustDevice = () => {
	const { data, mutate, error, isLoading } = useSWR<{
		is_trusted: boolean;
	}>("/otp/device/trusted/");

	return {
		isTrusted: data?.is_trusted ?? false,
		mutate,
		error,
		isLoading,
		trustDevice: async () => {
			try {
				await api.post("/otp/device/trust/");
			} catch (error) {
				return getFormErrors(error);
			}
		},
		untrustDevice: async () => {
			try {
				await api.post("/otp/device/delete/");
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
