import { HamburgerMenu } from "@app/components/hamburger-menu";
import { useMediaQuery } from "@app/hooks/use-media-query";

import SendOrRecieveIcon from "./send-or-recieve.svg?react";
import RecipientsIcon from "./recipients.svg?react";
import AddFundsIcon from "./add-funds.svg?react";
import WithdrawZarIcon from "./withdraw-zar.svg?react";
import DownloadStatementIcon from "./download-statement.svg?react";
import { Account } from "../use-accounts";

import styles from "./index.module.css";

export const OverflowMenu = ({
	account,
	isMenuDisabled,
}: {
	account: Account;
	isMenuDisabled: boolean;
}) => {
	const isMobile = useMediaQuery();

	return (
		<HamburgerMenu
			iconVariant={isMobile ? "default" : "borderless"}
			className={styles.menu}
			items={[
				{
					icon: (
						<div className={styles.menuItem}>
							<SendOrRecieveIcon height={20} width={20} color="#56a7a2" />
							Send/Receive
						</div>
					),
					command: () => {
						window.location.href = `/${account.client_id}/send-receive-funds/`;
					},
				},
				{
					icon: (
						<div className={styles.menuItem}>
							<RecipientsIcon height={20} width={20} color="#56a7a2" />
							Recipients
						</div>
					),
					command: () => {
						window.location.href = `/${account.client_id}/recipients/`;
					},
				},
				{
					icon: (
						<div className={styles.menuItem}>
							<AddFundsIcon height={20} width={20} color="#56a7a2" />
							Add funds
						</div>
					),
					command: () => {
						window.location.href = `/${account.client_id}?action=add-funds`;
					},
				},
				{
					icon: (
						<div className={styles.menuItem}>
							<WithdrawZarIcon height={20} width={20} color="#56a7a2" />
							Withdraw ZAR
						</div>
					),
					command: () => {
						window.location.href = `/${account.client_id}?action=withdraw-zar`;
					},
				},
				{
					icon: (
						<div className={styles.menuItem}>
							<DownloadStatementIcon height={20} width={20} color="#56a7a2" />
							Download statement
						</div>
					),
					command: () => {
						window.location.href = `/${account.client_id}?action=download-statement`;
					},
				},
			]}
			disabled={isMenuDisabled}
		/>
	);
};
