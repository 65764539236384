import { ConfirmedModal } from "@app/components/confirmed-modal";

export const ConfirmedDeviceNotTrustedModal = ({
	onClose,
	isOpen = true,
}: {
	onClose: () => void;
	isOpen?: boolean;
}) => {
	return (
		<ConfirmedModal
			onClose={onClose}
			isOpen={isOpen}
			title="Device not trusted"
			iconVariant="error"
			completeText="Continue"
			description="OTP authentication will be required for future logins."
		/>
	);
};
