import { useEffect, useState } from "react";
import { ConfirmedDeviceTrustedModal } from "../modals/confirmed-device-trusted-modal";
import { ConfirmationTrustDeviceModal } from "../modals/confirmation-trust-device-modal";
import { ConfirmedDeviceNotTrustedModal } from "../modals/confirmed-device-not-trusted-modal";

export const TrustDeviceFlow = ({
	onComplete,
	isOpen,
	variant = "default",
}: {
	onComplete: () => void;
	isOpen: boolean;
	variant?: "default" | "settings";
}) => {
	const [showTrustDeviceModal, setShowTrustDeviceModal] = useState(isOpen);
	const [showConfirmedTrustDeviceModal, setShowConfirmedTrustDeviceModal] =
		useState(false);
	const [showConfimedNotTrustDeviceModal, setShowConfimedNotTrustDeviceModal] =
		useState(false);

	useEffect(() => {
		if (isOpen) {
			setShowTrustDeviceModal(true);
		} else {
			setShowTrustDeviceModal(false);
			setShowConfirmedTrustDeviceModal(false);
			setShowConfimedNotTrustDeviceModal(false);
		}
	}, [isOpen]);

	return (
		<>
			{/* Step 1: Confirm that you want to trust this device */}
			<ConfirmationTrustDeviceModal
				isOpen={showTrustDeviceModal}
				onConfirm={() => {
					setShowTrustDeviceModal(false);
					setShowConfirmedTrustDeviceModal(true);
				}}
				onReject={() => {
					setShowTrustDeviceModal(false);
					setShowConfimedNotTrustDeviceModal(true);
				}}
				onCancel={onComplete}
				variant={variant}
			/>

			{/* Step 2: Confirmation result */}
			<ConfirmedDeviceTrustedModal
				isOpen={showConfirmedTrustDeviceModal}
				onClose={onComplete}
				completeText={variant === "settings" ? "Done" : "Continue"}
			/>

			<ConfirmedDeviceNotTrustedModal
				isOpen={showConfimedNotTrustDeviceModal}
				onClose={onComplete}
			/>
		</>
	);
};
