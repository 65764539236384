import { ConfirmedModal } from "@app/components/confirmed-modal";

export const RecipientSuccessModal = ({
	variant,
	onClose,
}: {
	variant: "edit" | "add";
	onClose: () => void;
}) => {
	return (
		<ConfirmedModal
			onClose={onClose}
			title={variant === "add" ? "New recipient added" : "Recipient updated"}
			description={
				variant === "add"
					? "You can now send funds to this recipient."
					: "Sending funds to this recipient will use the updated details."
			}
		/>
	);
};
