import clsx from "clsx";
import styles from "./index.module.css";
import { isLegalDisclaimerEnabled } from "@app/constants/feature-flags";

export const LegalDisclaimer = ({ className }: { className?: string }) => {
	if (!isLegalDisclaimerEnabled) return null;
	return (
		<p className={clsx(styles.legalDisclaimer, className)}>
			Future Forex SA (Pty) Ltd is an authorised FSP
		</p>
	);
};
