import { ReactNode } from "react";
import { DefaultCell } from "./default-cell";

import notTransactionIcon from "./not-transacting.svg";
import inProgressIcon from "./in-progress.svg";
import needsAttentionIcon from "./needs-attention.svg";

import styles from "./transaction-status-cell.module.css";

export const TransactionStatusCell = ({
	children,
	variant = "badge",
}: {
	children: ReactNode;
	variant?: "badge" | "default";
}) => {
	const isEmpty = !children || children === "N/A";
	if (isEmpty) return <DefaultCell>{children}</DefaultCell>;
	const type =
		children === "Not Transacting"
			? "default"
			: children === "In Progress"
				? "in-progress"
				: "error";

	const iconSize = variant === "badge" ? 16 : 20;

	const iconSrc =
		type === "default"
			? notTransactionIcon
			: type === "in-progress"
				? inProgressIcon
				: needsAttentionIcon;
	return (
		<div className={styles.container} data-type={type} data-variant={variant}>
			<img src={iconSrc} alt="" width={iconSize} height={iconSize} />
			{children}
		</div>
	);
};
