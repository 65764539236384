import { useEffect } from "react";
import { useParams } from "react-router-dom";

/**
 * Checks if the browser is outdated.
 * We check for specific features that are not supported in outdated browsers rather than trying use the user agent string.
 * This is because the user agent string can be spoofed.
 *
 * @returns true if the browser is outdated
 */
export const isOutDatedBrowser = () => {
	try {
		const isLocalStorageSupported = !!window.localStorage;
		const isSessionStorageSupported = !!window.sessionStorage;
		const isFetchSupported = !!window.fetch;
		const isPromiseSupported = !!window.Promise;
		const isIE = !!window.navigator.userAgent.match(/MSIE|Trident/);
		const isReplaceAllSupported = !!String.prototype.replaceAll;

		return (
			isIE ||
			!isLocalStorageSupported ||
			!isSessionStorageSupported ||
			!isFetchSupported ||
			!isPromiseSupported ||
			!isReplaceAllSupported
		);
	} catch {
		// If the checks throw an error, we assume the browser is outdated
		return true;
	}
};

export const useUnsupportedBrowserRedirect = () => {
	const params = useParams<{ clientId?: string }>();
	const isOutdated = isOutDatedBrowser();
	useEffect(() => {
		if (isOutdated) {
			window.location.href = params.clientId
				? `/${params.clientId}/unsupported-browser`
				: "/unsupported-browser";
		}
	}, [isOutdated, params.clientId]);
};
