import { type ReactNode } from "react";

import styles from "./index.module.css";
import clsx from "clsx";

const InfoIcon = () => (
	<svg
		role="presentation"
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<g clipPath="url(#clip0_11617_22738)">
			<path
				d="M9.99984 13.3327V9.99935M9.99984 6.66602H10.0082M18.3332 9.99935C18.3332 14.6017 14.6022 18.3327 9.99984 18.3327C5.39746 18.3327 1.6665 14.6017 1.6665 9.99935C1.6665 5.39698 5.39746 1.66602 9.99984 1.66602C14.6022 1.66602 18.3332 5.39698 18.3332 9.99935Z"
				stroke="var(--color-teal-550)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_11617_22738">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const NeedHelp = ({
	email = "info@futureforex.co.za",
	phone = "021 518 0558",
	children,
	maxWidth = "100%",
	className,
}: {
	email?: string;
	phone?: string;
	children: ReactNode;
	maxWidth?: number | string;
	className?: string;
}) => {
	const hasAllDetails = email && phone;
	return (
		<div className={clsx(styles.container, className)} style={{ maxWidth }}>
			<p className={styles.info}>
				<InfoIcon />
				{children}
			</p>
			<ul className={styles.links}>
				<li>
					<a className={styles.link} href={`mailto:${email}`}>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
						>
							<path
								d="M14.334 12.0013L9.90541 8.0013M6.09589 8.0013L1.66734 12.0013M1.33398 4.66797L6.77726 8.47826C7.21804 8.78681 7.43844 8.94108 7.67816 9.00084C7.88991 9.05362 8.11139 9.05362 8.32314 9.00084C8.56287 8.94108 8.78326 8.78681 9.22404 8.47826L14.6673 4.66797M4.53398 13.3346H11.4673C12.5874 13.3346 13.1475 13.3346 13.5753 13.1166C13.9516 12.9249 14.2576 12.6189 14.4493 12.2426C14.6673 11.8148 14.6673 11.2547 14.6673 10.1346V5.86797C14.6673 4.74786 14.6673 4.18781 14.4493 3.75999C14.2576 3.38366 13.9516 3.0777 13.5753 2.88596C13.1475 2.66797 12.5874 2.66797 11.4673 2.66797H4.53398C3.41388 2.66797 2.85383 2.66797 2.426 2.88596C2.04968 3.0777 1.74372 3.38366 1.55197 3.75999C1.33398 4.18781 1.33398 4.74786 1.33398 5.86797V10.1346C1.33398 11.2547 1.33398 11.8148 1.55197 12.2426C1.74372 12.6189 2.04968 12.9249 2.426 13.1166C2.85383 13.3346 3.41388 13.3346 4.53398 13.3346Z"
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						{email}
					</a>
				</li>
				{hasAllDetails && (
					<li>
						<div className={styles.divider} />
					</li>
				)}
				<li>
					<a className={styles.link} href={`tel:${phone}`}>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
						>
							<path
								d="M5.58685 5.90223C6.05085 6.86865 6.68337 7.77441 7.48443 8.57546C8.28548 9.37651 9.19124 10.009 10.1577 10.473C10.2408 10.5129 10.2823 10.5329 10.3349 10.5482C10.5218 10.6027 10.7513 10.5636 10.9096 10.4502C10.9542 10.4183 10.9923 10.3802 11.0685 10.304C11.3016 10.071 11.4181 9.95443 11.5353 9.87824C11.9772 9.59091 12.5469 9.59091 12.9889 9.87824C13.106 9.95443 13.2226 10.071 13.4556 10.304L13.5856 10.4339C13.9398 10.7882 14.117 10.9654 14.2132 11.1556C14.4046 11.534 14.4046 11.9809 14.2132 12.3592C14.117 12.5495 13.9399 12.7266 13.5856 13.0809L13.4805 13.186C13.1274 13.5391 12.9508 13.7156 12.7108 13.8505C12.4445 14.0001 12.0308 14.1077 11.7253 14.1068C11.45 14.1059 11.2619 14.0525 10.8856 13.9457C8.86333 13.3718 6.95509 12.2888 5.36311 10.6968C3.77112 9.10479 2.68814 7.19655 2.11416 5.17429C2.00735 4.79799 1.95395 4.60984 1.95313 4.33455C1.95222 4.02906 2.0598 3.6154 2.20941 3.34907C2.34424 3.10904 2.52078 2.9325 2.87386 2.57942L2.97895 2.47433C3.33325 2.12004 3.5104 1.94289 3.70065 1.84666C4.07903 1.65528 4.52587 1.65528 4.90424 1.84666C5.0945 1.94289 5.27164 2.12004 5.62594 2.47433L5.75585 2.60424C5.98892 2.83732 6.10546 2.95385 6.18165 3.07104C6.46898 3.51296 6.46898 4.08268 6.18165 4.52461C6.10546 4.6418 5.98892 4.75833 5.75585 4.9914C5.67964 5.06761 5.64154 5.10571 5.60965 5.15026C5.4963 5.30854 5.45717 5.53805 5.51165 5.72495C5.52698 5.77754 5.54694 5.81911 5.58685 5.90223Z"
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						{phone}
					</a>
				</li>
			</ul>
		</div>
	);
};
