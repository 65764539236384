import { Button } from "@app/components/button";
import { IconButton } from "@app/components/icon-button";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import styles from "./index.module.css";
import { FieldError } from "@app/components/field-error";
import { formatFileSize } from "@app/utils";
import { TextTruncate } from "../text-truncate";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import clsx from "clsx";
import { FieldError as RHFFieldError } from "react-hook-form";
import uploadedDocumentSrc from "./uploaded-document.svg";
import documentIconSrc from "./document.svg";

const getErrorMessage = (str?: string) => {
	if (str === "field required") return "This document is required";
	return str;
};

export interface DocumentFieldContentProps
	extends Omit<ComponentPropsWithoutRef<"div">, "onChange"> {
	label: string;
	tooltip?: ReactNode;
	tooltipWidth?: number;
	error?: RHFFieldError;
	disabled?: boolean;
	className?: string;
	multiple?: boolean;
	files: Array<{
		document_id: string;
		name: string;
		size?: number;
	}>;
	uploadedFiles: Array<File>;
	isLoading?: boolean;
	removeId?: string | null;
	downloadId?: string | null;
	onShowUpload: () => void;
	onRemove: (documentId: string) => void;
	onDownload: (documentId: string) => void;
}

export const DocumentFieldContent = ({
	label,
	tooltip,
	tooltipWidth = 260,
	error,
	disabled,
	className,
	multiple = false,
	files,
	uploadedFiles,
	isLoading,
	removeId,
	downloadId,
	onShowUpload,
	onRemove,
	onDownload,
	...rest
}: DocumentFieldContentProps) => {
	const isMobile = useMediaQuery();
	const hasFiles = files.length > 0;

	const buttonText = hasFiles
		? multiple
			? "Change file(s)"
			: "Change file"
		: multiple
			? "Add file(s)"
			: "Add file";
	return (
		<div {...rest} className={clsx(styles.wrapper, className)}>
			<div
				className={styles.container}
				data-error={!!error}
				data-active={hasFiles}
			>
				<div className={styles.title} data-empty={!hasFiles}>
					<img
						className={styles.icon}
						src={hasFiles ? uploadedDocumentSrc : documentIconSrc}
						data-has-tooltip={!!tooltip && !hasFiles}
						width={24}
						height={24}
						alt=""
					/>
					<div>
						<span>{label}*</span>
						{tooltip && !hasFiles && (
							<p className={styles.tooltipDescription}>{tooltip}</p>
						)}
					</div>
					{tooltip && hasFiles && (
						<MoreInfoTooltip name={label} hasIcon maxWidth={tooltipWidth}>
							{tooltip}
						</MoreInfoTooltip>
					)}
				</div>

				{isMobile ? (
					<IconButton
						className={styles.addButton}
						variant="primary"
						data-empty={!hasFiles}
						disabled={disabled || isLoading}
						onClick={onShowUpload}
					>
						<FiPlus size={24} color="inherit" />
					</IconButton>
				) : (
					<Button
						className={styles.addButton}
						variant={hasFiles ? "secondary" : "primary"}
						disabled={disabled || isLoading}
						onClick={onShowUpload}
					>
						{buttonText}
					</Button>
				)}

				{hasFiles && (
					<ul className={styles.uploadedFiles}>
						{files.map((current) => {
							const fileMatch = uploadedFiles.find(
								(file) => file.name === current.name,
							);
							return (
								<li className={styles.row} key={current.document_id}>
									{isMobile && (
										<li className={styles.name}>
											<TextTruncate text={current.name} maxWidth={224} />
										</li>
									)}
									<ul className={styles.fileInformation}>
										{!isMobile && (
											<>
												<li className={styles.name}>
													<TextTruncate text={current.name} maxWidth={286} />
												</li>
												<li className={styles.divider} />
											</>
										)}

										{(fileMatch || current.size) && (
											<>
												<li>
													{formatFileSize(fileMatch?.size || current.size!)}
												</li>
												<li className={styles.divider} />
											</>
										)}

										<li>
											<button
												className={styles.button}
												type="button"
												disabled={downloadId === current.document_id}
												onClick={() => onDownload(current.document_id)}
											>
												{downloadId === current.document_id
													? "Downloading"
													: "Download"}
											</button>
										</li>
										{!isMobile && (
											<>
												<li className={styles.divider} />
												<li>
													<button
														className={styles.button}
														type="button"
														disabled={removeId === current.document_id}
														onClick={() => onRemove(current.document_id)}
													>
														{removeId === current.document_id
															? "Removing"
															: "Remove"}
													</button>
												</li>
											</>
										)}
									</ul>

									{isMobile && (
										<IconButton
											className={styles.removeButton}
											onClick={() => onRemove(current.document_id)}
											disabled={disabled}
										>
											<FiTrash2 size={24} color="#56A7A2" />
										</IconButton>
									)}
								</li>
							);
						})}
					</ul>
				)}
			</div>
			{error && <FieldError>{getErrorMessage(error.message)}</FieldError>}
		</div>
	);
};
