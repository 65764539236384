import type { GenericFailureResponse, MappedReasons } from "@app/services";

import { snakeCaseToCamelCase } from "./snake-case-to-camel-case";

export const getMappedReasons = (error: GenericFailureResponse) => {
	if (!error?.detail || !Array.isArray(error.detail)) {
		return undefined;
	}

	const fieldReasons = error.detail;
	const mappedReasons = fieldReasons.reduce((result, item) => {
		if (item.loc.length > 0) {
			let itemField = item.loc[item.loc.length - 1];
			if (itemField.split(".").length > 1) {
				itemField = itemField.split(".")[itemField.split(".").length - 1];
			}
			const itemFieldCamelCase = snakeCaseToCamelCase(itemField);

			let message = item.msg;
			if (item.type === "value_error.any_str.min_length") {
				message = "This field is required";
			}
			result[itemFieldCamelCase] = [
				...(result[itemFieldCamelCase] || []),
				message,
			];
		}
		return result;
	}, {} as MappedReasons);
	return mappedReasons;
};
