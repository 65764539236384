import { ReactNode } from "react";
import { DefaultCell } from "./default-cell";

import styles from "./status-cell.module.css";
import clsx from "clsx";

const statusColorMap = {
	Onboarding: "#FDB022",
	Active: "#12B76A",
	Inactive: "#D1D1D1",
};

export const StatusCell = ({
	children,
	className,
}: {
	children: ReactNode;
	className?: string;
}) => {
	const isEmpty = !children || children === "N/A";
	const isValidStatus =
		children && statusColorMap[children as keyof typeof statusColorMap];
	if (isEmpty || !isValidStatus) return <DefaultCell>{children}</DefaultCell>;
	return (
		<span className={clsx(styles.container, className)}>
			<span
				className={styles.indicator}
				style={{
					backgroundColor:
						statusColorMap[children as keyof typeof statusColorMap],
				}}
			/>
			{children}
		</span>
	);
};
