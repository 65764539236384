import { Button } from "@app/components/button";
import AnimateHeight from "react-animate-height";

import styles from "./index.module.css";
import { Label } from "@app/components/label";
import { MultiSelectNew } from "@app/features/onboarding/business-company-details/multi-select-new";
import { StatusCell } from "../status-cell";
import { TransactionStatusCell } from "../transaction-status-cell";
import { AccountStatus, AccountTransactionStatus } from "../use-accounts";
import { useEffect, useState } from "react";
import { STATUS_OPTIONS, TRANSACTION_STATUS_OPTIONS } from "../constants";

export const FiltersSection = ({
	show,
	onClearFilters,
	onApplyFilter,
	status,
	transactionStatus,
}: {
	status?: Array<AccountStatus>;
	transactionStatus?: Array<AccountTransactionStatus>;
	show: boolean;
	onClearFilters: () => void;
	onApplyFilter: (
		status: Array<AccountStatus> | undefined,
		transactionStatus: Array<AccountTransactionStatus> | undefined,
	) => void;
}) => {
	const [currentStatus, setCurrentStatus] = useState<
		Array<AccountStatus> | undefined
	>(status);
	const [currentTransactionStatus, setCurrentTransactionStatus] = useState<
		Array<AccountTransactionStatus> | undefined
	>(transactionStatus);

	useEffect(() => {
		setCurrentStatus(status);
		setCurrentTransactionStatus(transactionStatus);
	}, [status, transactionStatus]);

	return (
		<AnimateHeight duration={200} height={show ? "auto" : 0}>
			<div className={styles.filtersSection}>
				<h3 className={styles.filtersHeading}>Filter by</h3>
				<div className={styles.filtersRow}>
					<div>
						<Label htmlFor="status">Status</Label>
						<MultiSelectNew
							searchable={false}
							mobileHeading="Select a status"
							tagsClassName={styles.tags}
							placeholder="Select a status"
							options={STATUS_OPTIONS.map((status) => ({
								id: status,
								name: status,
								render: <StatusCell>{status}</StatusCell>,
							}))}
							striped={false}
							tagPadding={56}
							onChange={(value) => {
								setCurrentStatus(
									value.map((current) => current.id as AccountStatus),
								);
							}}
							value={
								currentStatus
									? currentStatus.map((current) => ({
											id: current,
											name: current,
											render: <StatusCell>{current}</StatusCell>,
										}))
									: undefined
							}
						/>
					</div>
					<div>
						<Label htmlFor="transaction-status">Transaction status</Label>
						<MultiSelectNew
							searchable={false}
							striped={false}
							tagPadding={72}
							mobileHeading="Select a transaction status"
							placeholder="Select a transaction status"
							tagsClassName={styles.tags}
							options={TRANSACTION_STATUS_OPTIONS.map((status) => ({
								id: status,
								name: status,
								render: (
									<TransactionStatusCell variant="default">
										{status}
									</TransactionStatusCell>
								),
							}))}
							onChange={(value) => {
								setCurrentTransactionStatus(
									value.map(
										(current) => current.id as AccountTransactionStatus,
									),
								);
							}}
							value={
								currentTransactionStatus
									? currentTransactionStatus.map((current) => ({
											id: current,
											name: current,
											render: (
												<TransactionStatusCell variant="default">
													{current}
												</TransactionStatusCell>
											),
										}))
									: undefined
							}
						/>
					</div>
				</div>
				<div className={styles.filtersActions}>
					<Button
						variant="secondary"
						className={styles.filterActionButton}
						onClick={onClearFilters}
					>
						Clear all
					</Button>
					<Button
						className={styles.filterActionButton}
						onClick={() =>
							onApplyFilter(currentStatus, currentTransactionStatus)
						}
					>
						Apply
					</Button>
				</div>
			</div>
		</AnimateHeight>
	);
};
