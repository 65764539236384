import { type ReactNode } from "react";

import { BottomMenuBar } from "@app/components/bottom-menu-bar";
import { MobileHeader } from "@app/components/mobile-header";
import { Sidebar } from "@app/components/sidebar";
import { Toast } from "@app/components/toasts";
import { TopBar } from "@app/components/top-bar";
import { useMediaQuery } from "@app/hooks/use-media-query";

import { useAccountManager } from "@app/hooks/use-account-manager";
import { StatusProvider } from "@app/hooks/use-status";
import { joinClasses } from "@app/utils";
import clsx from "clsx";
import { OverScroll } from "../over-scroll";
import { ScrollRestore } from "../scroll-restore";
import styles from "./index.module.css";
import { Status } from "./status";

export const SignedInLayout = ({
	childChildContainerClassName,
	childHeader,
	children,
	footer,
	title,
	hasBackButton,
	mobileRightSection,
	onBack,
	variant = "default",
	hideBottomNav = false,
	childHeaderClassName,
	content,
	mobileHeader,
	overscrollClassName,
	overscrollDisabled = false,
	isAddClient = false,
}: {
	childChildContainerClassName?: string;
	childHeader?: ReactNode;
	hideBottomNav?: boolean;
	childHeaderClassName?: string;
	children?: ReactNode;
	footer?: ReactNode;
	onBack?: () => void;
	title?: string;
	hasBackButton?: boolean;
	mobileRightSection?: ReactNode;
	overscrollDisabled?: boolean;
	variant?: "default" | "onboarding";
	content?: ReactNode;
	mobileHeader?: ReactNode;
	overscrollClassName?: string;
	isAddClient?: boolean;
}) => {
	const isMobile = useMediaQuery();
	const { data: accountManager } = useAccountManager();

	return (
		<StatusProvider>
			<Status />
			<ScrollRestore>
				{isMobile && (
					<>
						{mobileHeader ?? (
							<MobileHeader
								isAddClient={isAddClient}
								hasBackButton={hasBackButton}
								onBack={onBack}
								rightSection={isMobile && mobileRightSection}
							>
								{title}
							</MobileHeader>
						)}
					</>
				)}
				<main id="content" className={styles.root} data-variant={variant}>
					{!isMobile && (
						<Sidebar
							accountManager={accountManager}
							content={content}
							disableLogoLink={variant === "onboarding"}
						/>
					)}
					<div className={styles.content}>
						{!isMobile && (
							<TopBar variant={variant} isAddClient={isAddClient} />
						)}
						<div className={styles.inner}>
							{childHeader && (
								<div
									className={joinClasses(
										styles.contentContainer,
										childHeaderClassName,
									)}
								>
									{childHeader}
								</div>
							)}
							<OverScroll
								disabled={overscrollDisabled}
								className={clsx(
									styles.contentContainer,
									childChildContainerClassName,
								)}
								overscrollClassName={overscrollClassName}
							>
								<Toast autoClose={2000} />
								{children}
							</OverScroll>
						</div>

						{!isMobile && footer && (
							<div className={styles.footer}>{footer}</div>
						)}
					</div>
				</main>
				{isMobile && (
					<div className={styles.footer}>
						{footer}
						{!hideBottomNav && <BottomMenuBar />}
					</div>
				)}
			</ScrollRestore>
		</StatusProvider>
	);
};
