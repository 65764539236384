import { Button } from "@app/components/button";
import AddIcon from "@app/icons/add.svg?react";
import { useState } from "react";
import { EditNicknameModal } from "./edit-nickname-modal";

import styles from "./index.module.css";

export const NicknameRow = ({
	value,
	recipientId,
	onAfterEdit,
}: {
	value?: string;
	onAfterEdit: () => void;
	recipientId?: number;
}) => {
	const [showModal, setShowModal] = useState(false);

	const hasNickname = !!value;
	return (
		<div className={styles.container}>
			{hasNickname ? (
				<p className={styles.value}>
					<span className={styles.valueText}>{value}</span> (
					<button
						className={styles.editButton}
						onClick={() => setShowModal(true)}
						type="button"
					>
						Edit nickname
					</button>
					)
				</p>
			) : (
				<Button
					variant="tertiary"
					inline
					noPadding
					onClick={() => setShowModal(true)}
				>
					<AddIcon width={20} height={20} />
					Add nickname
				</Button>
			)}

			<EditNicknameModal
				isOpen={showModal}
				isEditNickname={hasNickname}
				nickname={value}
				recipientId={recipientId}
				onNicknameSaved={() => {
					setShowModal(false);
					onAfterEdit();
				}}
				onCancel={() => setShowModal(false)}
			/>
		</div>
	);
};
