import type { CurrencyOption } from "@app/entities";

import { Button } from "@app/components/button";
import { Label } from "@app/components/label";
import { MultiSelectNew } from "@app/features/onboarding/business-company-details/multi-select-new";
import { useCountries } from "@app/hooks/use-countries";
import { MappedCurrency, useCurrencies } from "@app/hooks/use-currencies";
import AnimateHeight from "react-animate-height";
import styles from "./index.module.css";

export interface FilterSectionState {
	selectedCurrencies?: CurrencyOption[];
	selectedCountries?: string[];
}

export const FilterSection = (props: {
	isOpen: boolean;
	className?: string;
	selectedCountries?: string[];
	selectedCurrencies?: MappedCurrency[];
	onApplyFilters: () => void;
	onChangeCountryFilters: (value: string[]) => void;
	onChangeCurrencyFilters: (value: MappedCurrency[]) => void;
	onClearFilters: () => void;
	onRemoveCountryFilterTag: (option?: string, runFilter?: boolean) => void;
	onRemoveCurrencyFilterTag: (
		option?: MappedCurrency,
		runFilter?: boolean,
	) => void;
}) => {
	const { data: countries } = useCountries();
	const { data: currencies, orderedMappedCurrencies } = useCurrencies();

	const handleApplyFilter = () => {
		props.onApplyFilters();
	};

	const onClearFilters = () => {
		props.onClearFilters();
	};

	const countriesValue =
		countries && props.selectedCountries && props.selectedCountries?.length > 0
			? props.selectedCountries.map((countryCode) => ({
					name: countryCode,
					id: countryCode,
				}))
			: [];

	const currenciesValue =
		currencies &&
		props.selectedCurrencies &&
		props.selectedCurrencies?.length > 0
			? props.selectedCurrencies.map((currency) => ({
					name: currency.verboseName,
					id: currency.currencyCode,
					countryCode: currency.countryCode,
				}))
			: [];

	return (
		<AnimateHeight duration={200} height={props.isOpen ? "auto" : 0}>
			<div className={styles.container}>
				<h3 className={styles.heading}>Filter by</h3>
				<div className={styles.row}>
					<div>
						<Label htmlFor="currency">Currency</Label>
						<MultiSelectNew
							tagsClassName={styles.tags}
							mobileHeading="Select currencies"
							placeholder="Select currencies"
							options={
								orderedMappedCurrencies?.map((current) => ({
									id: current.currency_code,
									countryCode: current.country_code,
									name: current.verbose_name,
								})) ?? []
							}
							onChange={(value) => {
								const values = value.map((value) => ({
									currencyCode: value.id.toString(),
									countryCode: value.countryCode!,
									verboseName: value.name,
								}));
								props.onChangeCurrencyFilters(values);
							}}
							value={currenciesValue}
						/>
					</div>
					<div>
						<Label htmlFor="countries">Country</Label>
						<MultiSelectNew
							tagsClassName={styles.tags}
							mobileHeading="Select countries"
							placeholder="Select countries"
							options={
								countries?.map((country) => ({ id: country, name: country })) ??
								[]
							}
							onChange={(value) => {
								const values = value.map((country) => country.id.toString());
								props.onChangeCountryFilters(values);
							}}
							value={countriesValue}
						/>
					</div>
				</div>
				<div className={styles.actions}>
					<Button
						variant="secondary"
						className={styles.actionButton}
						onClick={onClearFilters}
					>
						Clear all
					</Button>
					<Button className={styles.actionButton} onClick={handleApplyFilter}>
						Apply
					</Button>
				</div>
			</div>
		</AnimateHeight>
	);
};
