import { useEffect, useState } from "react";

import { ConfirmationDisableOtpModal } from "../modals/confirmation-disable-otp-modal";
import { ConfirmedDisableModal } from "../modals/confirmed-disable-modal";
import { useOTP } from "../use-otp";

export const InitOTPDeviceDisableFlow = ({
	onComplete,
	onClose,
	isOpen,
}: {
	onComplete: () => void;
	onClose: () => void;
	isOpen: boolean;
}) => {
	const { disableOTPDevice } = useOTP();
	const [status, setStatus] = useState<
		"confirmation" | "confirmed" | undefined
	>(undefined);

	useEffect(() => {
		if (isOpen) {
			setStatus("confirmation");
		} else {
			setStatus(undefined);
		}
	}, [isOpen]);
	return (
		<>
			<ConfirmationDisableOtpModal
				isOpen={status === "confirmation"}
				onConfirm={async () => {
					const errors = await disableOTPDevice();
					if (errors) return errors;
					setStatus("confirmed");
				}}
				onClose={onClose}
			/>
			<ConfirmedDisableModal
				isOpen={status === "confirmed"}
				onClose={onComplete}
			/>
		</>
	);
};
