import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

export const ConfirmChangesModal = ({
	onBack,
	onConfirm,
	isOpen,
}: {
	onBack: () => void;
	onConfirm: () => void;
	isOpen: boolean;
}) => {
	return (
		<Dialog
			size={440}
			isOpen={isOpen}
			title="Confirm changes"
			onClose={onBack}
			description="Are you sure the recipient's updated details are correct?"
			actions={
				<>
					<Button minWidth={110} variant="secondary" onClick={onBack}>
						Back
					</Button>
					<Button minWidth={110} onClick={onConfirm}>
						Confirm
					</Button>
				</>
			}
		/>
	);
};
