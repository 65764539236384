import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

export type Client = {
	client_id: number;
	name: string;
	bank_account_id: number;
};

export type Clients = {
	items: Array<Client>;
	count: number;
};

export const CLIENT_STORAGE_KEY = "active-client-id";
const MAX_CLIENTS = 1;

export const getActiveClientFromSession = () => {
	const stored = window.sessionStorage.getItem(CLIENT_STORAGE_KEY);
	return stored && Number.parseInt(stored, 10)
		? Number.parseInt(stored, 10)
		: "";
};

export const useClients = (isEnabled = true) => {
	const result = useSWRImmutable<Clients>(
		isEnabled ? `/clients/?limit=${MAX_CLIENTS}` : null,
	);

	const params = useParams<{ clientId?: string }>();
	const paramClientId =
		params.clientId && !Number.isNaN(Number.parseInt(params.clientId, 10))
			? Number.parseInt(params.clientId, 10)
			: undefined;

	const targetClientId = paramClientId ?? result.data?.items[0]?.client_id;

	const clientResult = useSWRImmutable(
		targetClientId ? `/clients/${targetClientId}/` : null,
	);

	const activeClientId = useMemo(() => {
		if (clientResult.isLoading) return;

		if (clientResult.data?.client_id) {
			return clientResult.data.client_id;
		}
		return targetClientId;
	}, [targetClientId, clientResult]);

	useEffect(() => {
		if (activeClientId) {
			window.sessionStorage.setItem(
				CLIENT_STORAGE_KEY,
				activeClientId.toString(),
			);
		}
	}, [activeClientId]);

	return {
		data: result.data,
		error: result.error || clientResult.error,
		isLoading: result.isLoading || clientResult.isLoading,
		activeClientId,
		count: result.data?.count,
		activeClient: clientResult.data,
		activeBankAccountId: clientResult.data?.bank_account_id,
	};
};
