import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { useOTP } from "../../use-otp";
import { useState } from "react";
import { ApiErrors } from "@app/components/api-errors";

/**
 * A modal dialog that prompts the user to trust the current device for future OTP authentication.
 *
 * @param onReject - A callback function to be called when the user chooses not to trust the device.
 * @param onConfirm - A callback function to be called when the user chooses to trust the device.
 */
export const ConfirmationTrustDeviceModal = ({
	onReject,
	onConfirm,
	onCancel,
	isOpen = true,
	variant = "default",
}: {
	onReject: () => void;
	onConfirm: () => void;
	onCancel?: () => void;
	isOpen?: boolean;
	variant?: "default" | "settings";
}) => {
	const [errors, setErrors] = useState<Array<string>>([]);
	const { trustDevice } = useOTP();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleTrustDevice = async () => {
		setIsSubmitting(true);
		const errors = await trustDevice();
		if (errors) {
			setErrors(errors.apiErrors);
		} else {
			onConfirm();
		}
		setIsSubmitting(false);
	};
	return (
		<Dialog
			isOpen={isOpen}
			title="Trust this device?"
			description={
				'If you trust this device, OTP authentication will be skipped on future logins. Only select "Trust" if this is your private device and not shared.'
			}
			actions={
				<>
					{variant === "settings" ? (
						<Button
							onClick={onCancel}
							disabled={isSubmitting}
							variant="secondary"
							minWidth={110}
						>
							Cancel
						</Button>
					) : (
						<Button
							disabled={isSubmitting}
							variant="secondary"
							minWidth={110}
							onClick={onReject}
						>
							Don't trust
						</Button>
					)}
					<Button
						minWidth={110}
						disabled={isSubmitting}
						onClick={handleTrustDevice}
					>
						{isSubmitting ? "Trusting" : "Trust"}
					</Button>
				</>
			}
		>
			<ApiErrors errors={errors} />
		</Dialog>
	);
};
