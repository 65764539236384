import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";

import { MobileHelp } from "@app/components/mobile-help";
import { Notifications } from "@app/components/notifications";
import { Paginator } from "@app/components/paginator";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { Search } from "@app/components/search";
import { Button } from "@app/components/button";
import { SortableTable } from "@app/components/sortable-table";
import { useShowToast } from "@app/helpers";
import { TransactionStatusCell } from "./transaction-status-cell";
import { isAccountsEnabled } from "@app/constants/feature-flags";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { IconButton } from "@app/components/icon-button";
import { Pagination } from "@app/components/pagination";
import { SortModal } from "@app/components/sort-modal";

import {
	AccountStatus,
	AccountTransactionStatus,
	useAccounts,
} from "./use-accounts";
import { DefaultCell } from "./default-cell";
import { StatusCell } from "./status-cell";
import { OverflowMenu } from "./overflow-menu";
import { FiltersSection } from "./filters-section";
import { AccountsList } from "./accounts-list";
import { AppliedTags } from "./applied-tags";
import { FilterModal } from "./filter-modal";
import AddIcon from "./add.svg?react";

import styles from "./index.module.css";
import { paths } from "@app/constants/paths";
import { OtpOnboarding } from "../otp/otp-onboarding";

const DEFAULT_LIMIT = 25;
const DEFAULT_SORT_FIELD = "name";
const DEFAULT_SORT_ORDER = -1;

export const Accounts = () => {
	const isMobile = useMediaQuery();
	const [showSortModal, setShowSortModal] = useState(false);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [sortField, setSortField] = useState<string>(DEFAULT_SORT_FIELD);
	const [sortOrder, setSortOrder] = useState<number>(DEFAULT_SORT_ORDER);
	const [search, setSearch] = useState("");
	const [debouncedSearch, setDebouncedSearch] = useState("");
	const [status, setStatus] = useState<Array<AccountStatus> | undefined>(
		undefined,
	);
	const [transactionStatus, setTransactionStatus] = useState<
		Array<AccountTransactionStatus> | undefined
	>(undefined);
	const [limit, setLimit] = useState(DEFAULT_LIMIT);
	const [offset, setOffset] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [showToast] = useShowToast();
	const navigate = useNavigate();

	const debouncedSetSearch = useMemo(
		() =>
			debounce((newSearch) => {
				setDebouncedSearch(newSearch);
			}, 300),
		[],
	);

	const handleSearchChange = useCallback(
		(event) => {
			const newSearch = event.target.value;
			setSearch(newSearch);
			debouncedSetSearch(newSearch);
		},
		[debouncedSetSearch],
	);

	const { data, isLoading, error } = useAccounts({
		limit,
		offset,
		search: debouncedSearch,
		status: status && status.length > 0 ? status.join(",") : undefined,
		transaction_status:
			transactionStatus && transactionStatus.length > 0
				? transactionStatus.join(",")
				: undefined,
		ordering:
			(sortOrder === 1 || sortOrder === -1) && sortField
				? `${sortOrder === -1 ? "-" : ""}${sortField}`
				: undefined,
	});

	const handleApplyFilter = (
		status: Array<AccountStatus> | undefined,
		transactionStatus: Array<AccountTransactionStatus> | undefined,
	) => {
		setStatus(status);
		setTransactionStatus(transactionStatus);
		setShowFilterModal(false);
	};

	const handleClearFilters = () => {
		setStatus(undefined);
		setTransactionStatus(undefined);
	};

	const handleClearSearch = () => {
		setSearch("");
		setDebouncedSearch("");
	};

	const handleOTPComplete = () => {
		window.location.href = paths().accounts;
	};

	useEffect(() => {
		if (error) {
			showToast("Failed to retrieve accounts", "error");
		}
	}, [error, showToast]);

	const currentPage = offset / limit + 1;

	useEffect(() => {
		if (!isAccountsEnabled) {
			window.location.href = "/";
		}
	}, []);

	const appliedFiltersCount = useMemo(() => {
		return (status?.length ?? 0) + (transactionStatus?.length ?? 0);
	}, [status, transactionStatus]);

	const formattedData = useMemo(() => {
		if (!data) return [];

		return data.items.map((account) => {
			const isMenuDisabled = account.status === "Onboarding";
			return {
				name: <DefaultCell>{account.name}</DefaultCell>,
				status: <StatusCell>{account.status}</StatusCell>,
				balance: <DefaultCell>{account.balance}</DefaultCell>,
				transaction_status: (
					<TransactionStatusCell>
						{account.transaction_status}
					</TransactionStatusCell>
				),
				view: (
					<Button
						size="sm"
						className={styles.viewButton}
						variant="secondary"
						onClick={() => {
							window.location.href = `/${account.client_id}`;
						}}
					>
						View
					</Button>
				),
				menu: (
					<OverflowMenu account={account} isMenuDisabled={isMenuDisabled} />
				),
			};
		});
	}, [data]);

	return (
		<SignedInLayout
			title="Accounts"
			mobileRightSection={
				<>
					<MobileHelp />
					<Notifications />
				</>
			}
			footer={
				!isMobile && (
					<footer className={styles.footer}>
						<Paginator
							onNavigatePage={(page, rowsPerPage) => {
								setLimit(rowsPerPage);
								setOffset(Math.max(0, (page - 1) * rowsPerPage));
							}}
							numberPerPage={limit}
							totalRecords={data?.count ?? 0}
							currentPage={currentPage}
						/>
					</footer>
				)
			}
		>
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<h2 className={styles.title}>Accounts</h2>
					<div className={styles.actions}>
						<Search
							placeholder="Search accounts"
							className={styles.search}
							value={search}
							onChange={handleSearchChange}
							onClear={handleClearSearch}
						/>

						{isMobile ? (
							<IconButton
								variant="primary"
								className={styles.addButton}
								onClick={() =>
									navigate(paths().onboarding.individual.addClient)
								}
							>
								<AddIcon width={24} height={24} />
							</IconButton>
						) : (
							<Button
								className={styles.filterButton}
								variant="secondary"
								onClick={() => setShowFilters(!showFilters)}
							>
								{showFilters ? "Hide" : "Show"} filters
								<svg
									data-open={showFilters}
									className={styles.filterButtonIcon}
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									role="presentation"
								>
									<path
										d="M5 7.5L10 12.5L15 7.5"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Button>
						)}
					</div>
				</div>

				<div className={styles.mobileActions}>
					<Button
						variant="secondary"
						block
						onClick={() => setShowFilterModal(true)}
					>
						Filter {appliedFiltersCount > 0 && `(${appliedFiltersCount})`}
					</Button>
					<Button variant="secondary" onClick={() => setShowSortModal(true)}>
						Sort
					</Button>
				</div>

				<div className={styles.appliedFiltersRow}>
					<p className={styles.count}>
						{data?.count ?? "-"} Account
						{data?.count !== 1 && "s"}
					</p>
					{!isMobile && (
						<AppliedTags
							status={status}
							transactionStatus={transactionStatus}
							onRemoveStatus={(status) =>
								setStatus((current) => current?.filter((s) => s !== status))
							}
							onRemoveTransactionStatus={(status) =>
								setTransactionStatus((current) =>
									current?.filter((s) => s !== status),
								)
							}
						/>
					)}
				</div>

				<FiltersSection
					show={showFilters}
					onClearFilters={handleClearFilters}
					onApplyFilter={handleApplyFilter}
					status={status}
					transactionStatus={transactionStatus}
				/>

				{isMobile ? (
					<AccountsList items={data?.items} />
				) : (
					<SortableTable
						isLoading={isLoading}
						data={formattedData}
						emptyText="No accounts to display"
						columns={[
							{
								field: "name",
								header: "Client name",
								sortable: true,
							},
							{
								field: "status",
								header: "Status",
							},
							{
								field: "balance",
								header: "Balance",
								sortable: true,
							},
							{
								field: "transaction_status",
								header: "Transaction status",
							},
							{
								field: "view",
								width: 82,
							},
							{
								field: "menu",
								width: 62,
							},
						]}
						onSort={(sortOrder, sortField) => {
							setSortOrder(sortOrder);
							setSortField(sortField);
						}}
						sortOrder={sortOrder}
						sortField={sortField}
					/>
				)}
				{isMobile && (
					<Pagination
						onNavigatePage={(page) => {
							setOffset(Math.max(0, (page - 1) * page));
						}}
						limit={limit}
						count={data?.count ?? 0}
						page={currentPage}
					/>
				)}
			</div>

			<SortModal
				title="Sort Accounts"
				fieldOptions={[
					{
						label: "Name",
						isDefault: true,
						key: "name",
					},
					{
						label: "Balance",
						isDefault: false,
						key: "balance",
					},
				]}
				sortField={sortField}
				sortOrder={sortOrder}
				onChangeSort={(sortOrder, sortField) => {
					setSortOrder(sortOrder);
					setSortField(sortField);
				}}
				onBack={() => setShowSortModal(false)}
				isOpen={showSortModal}
			/>

			<FilterModal
				isOpen={showFilterModal}
				onClose={() => setShowFilterModal(false)}
				onClearFilters={handleClearFilters}
				onApplyFilter={handleApplyFilter}
				status={status}
				transactionStatus={transactionStatus}
			/>

			<OtpOnboarding onComplete={handleOTPComplete} />
		</SignedInLayout>
	);
};
