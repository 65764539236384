import { SwiftCodeCountry } from "@app/entities";

export type SwiftCodeMode = "sortCode" | "routingNumber" | "none";

export const getSwiftCodeMode = (swiftCode: string): SwiftCodeMode => {
	const swiftCodeCountry: SwiftCodeCountry = (
		swiftCode.substring(4, 6) ?? ""
	).toLowerCase() as SwiftCodeCountry;
	if (swiftCodeCountry === "gb") return "sortCode";
	if (swiftCodeCountry === "us") return "routingNumber";
	return "none";
};
