import useSWR from "swr";

export interface Account {
	client_id: number;
	name: string;
	bank_account_id: number;
	id_number: string;
	status: string;
	balance: string;
	transaction_status: string;
}

export type AccountStatus = "Onboarding" | "Inactive" | "Active";
export type AccountTransactionStatus =
	| "Needs Attention"
	| "In Progress"
	| "Not Transacting"
	| "N/A";

interface AccountQuery {
	limit: number;
	offset: number;
	ordering: string;
	search?: string;
	status?: string;
	transaction_status?: string;
}

export const useAccounts = (query?: Partial<AccountQuery>) => {
	return useSWR<{
		items: Array<Account>;
		count: number;
	}>(query ? ["/clients/", { params: query }] : null);
};
