import { type CSSProperties, type ReactNode, useState } from "react";
import { FiAlertCircle, FiInfo } from "react-icons/fi";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { joinClasses } from "@app/utils";
import type { TooltipOptions } from "primereact/tooltip/tooltipoptions";
import { TooltipDialog } from "../tooltip-dialog";
import styles from "./index.module.css";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export const MoreInfoTooltip = ({
	indicator,
	children,
	hasIcon = false,
	maxWidth = "unset",
	size = 20,
	style,
	variant = "info",
	className,
	disabled = false,
	name,
	...rest
}: {
	indicator?: ReactNode;
	children: ReactNode;
	style?: CSSProperties;
	hasIcon?: boolean;
	maxWidth?: string | number;
	size?: number;
	variant?: "alert" | "info" | "persistent";
	className?: string;
	disabled?: boolean;
	name?: ReactNode;
} & TooltipOptions) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const isMobile = useMediaQuery();

	if (!children) return null;

	const trigger = indicator ? (
		indicator
	) : variant === "alert" ? (
		<FiAlertCircle size={size} color="#FDB022" />
	) : (
		<FiInfo size={size} />
	);

	if (name && isMobile) {
		return (
			<>
				<button
					type="button"
					aria-disabled={disabled}
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
						setShowTooltip(true);
					}}
					className={joinClasses(styles.button, className)}
					style={style}
				>
					{trigger}
				</button>
				<TooltipDialog
					isOpen={showTooltip}
					title={name}
					onBack={() => setShowTooltip(false)}
				>
					{children}
				</TooltipDialog>
			</>
		);
	}

	const isPersistent = variant === "persistent";

	return (
		<Tooltip persistent={isPersistent} {...rest}>
			<TooltipTrigger
				aria-disabled={disabled}
				className={joinClasses(styles.button, className)}
				style={style}
			>
				{trigger}
			</TooltipTrigger>
			<TooltipContent className={styles.tooltip}>
				<div className={styles.inner} style={{ maxWidth }}>
					{hasIcon && <FiInfo className={styles.icon} size={20} />}
					{children}
				</div>
			</TooltipContent>
		</Tooltip>
	);
};
