interface Values {
	first_name: string;
	last_name: string;
}

export const hasFirstAndLastName = (values: Values | undefined): boolean => {
	if (!values) return false;

	const { first_name, last_name } = values;
	if (!first_name || !last_name) return false;
	return first_name.trim().length > 0 && last_name.trim().length > 0;
};
