import { isOTPErrorTypeEnabled } from "@app/constants/feature-flags";

const ERROR_TEXT = "We have sent an OTP";
const OTP_ERROR_TYPE = "error.otp_required";

export const getIsOTPError = (errors?: Array<string>) => {
	if (!errors || errors.length === 0) return false;

	return isOTPErrorTypeEnabled
		? errors.some((error) => error?.includes(OTP_ERROR_TYPE))
		: errors.some((error) => error?.includes(ERROR_TEXT));
};
