import { useEffect, useState } from "react";
import { DeviceModal } from "../modals/device-modal";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { ConfirmationEnableOtpModal } from "../modals/confirmation-enable-otp-modal";
import { ConfirmedEnableModal } from "../modals/confirmed-enable-modal";
import { OTPMethod } from "../use-otp";

export const EnableOTPDeviceFlow = ({
	onComplete,
	onClose,
	isOpen,
}: {
	onComplete: () => void;
	onClose: () => void;
	isOpen: boolean;
}) => {
	const [startResendTimer, setStartResendTimer] = useState(false);
	const [method, setMethod] = useState<OTPMethod>();
	const [status, setStatus] = useState<
		"confirmation" | "device" | "otp" | "resend" | "confirmed" | undefined
	>(undefined);
	const [contactNumber, setContactNumber] = useState("");

	useEffect(() => {
		if (isOpen) {
			setStatus("confirmation");
			setStartResendTimer(false);
		} else {
			setStatus(undefined);
		}
	}, [isOpen]);

	return (
		<>
			<ConfirmationEnableOtpModal
				closeText="Cancel"
				isOpen={status === "confirmation"}
				onBack={onClose}
				onClose={onClose}
				onConfirm={() => {
					setStatus("device");
				}}
			/>

			<DeviceModal
				isOpen={status === "device"}
				onClose={onClose}
				onConfirm={(contactNumber, method) => {
					setContactNumber(contactNumber);
					setMethod(method);
					setStatus("otp");
					setStartResendTimer(true);
				}}
			/>

			<OTPInputModal
				contactNumber={contactNumber}
				method={method}
				isOpen={status === "otp"}
				onResend={() => {
					setStatus("resend");
				}}
				variant="device"
				onClose={() => {
					setStatus("device");
				}}
				onConfirm={() => {
					setStatus("confirmed");
				}}
			/>

			<ResendOTPModal
				isOpen={status === "resend"}
				hasOTPBeenSent={startResendTimer}
				contactNumber={contactNumber}
				variant="device"
				onConfirm={() => {
					setStatus("otp");
				}}
				onClose={() => {
					setStatus("otp");
				}}
			/>

			{status === "confirmed" && <ConfirmedEnableModal onClose={onComplete} />}
		</>
	);
};
