import { useAuth } from "@app/hooks/use-auth";
import { useVerifySession } from "@app/wrappers/auth/use-verify-session";
import { useEffect, useRef, useState, useCallback } from "react";
import { Button } from "../button";
import { Dialog } from "../dialog";
import timeoutClockSrc from "./timeout-clock.svg";

import styles from "./index.module.css";

const THIRTY_SECONDS = 30000;
const THIRTY_MINUTES = 30 * 60 * 1000;
const DEFAULT_COUNT = 30;
const USER_ACTIVITY_EVENTS = ["mousedown", "keypress", "touchstart"];

export const SessionTimeout = () => {
	const { onLogout } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const [countdown, setCountdown] = useState<number>(DEFAULT_COUNT);
	const [showInactiveModal, setShowInactiveModal] = useState(false);
	const { mutate } = useVerifySession();

	const resetSessionTimeout = useCallback(() => {
		if (timeoutRef.current) {
			setShowInactiveModal(false);
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			setShowInactiveModal(true);
			setCountdown(DEFAULT_COUNT);
		}, THIRTY_MINUTES - THIRTY_SECONDS);
	}, []);

	const handleUserActivity = useCallback(() => {
		if (!showInactiveModal) {
			resetSessionTimeout();
		}
	}, [resetSessionTimeout, showInactiveModal]);

	useEffect(() => {
		resetSessionTimeout();
	}, [resetSessionTimeout]);

	useEffect(() => {
		USER_ACTIVITY_EVENTS.forEach((event) => {
			window.addEventListener(event, handleUserActivity);
		});

		return () => {
			USER_ACTIVITY_EVENTS.forEach((event) => {
				window.removeEventListener(event, handleUserActivity);
			});
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [handleUserActivity]);

	useEffect(() => {
		if (showInactiveModal && countdown > 0) {
			const timeoutId = setTimeout(() => {
				setCountdown((prevCount) => prevCount - 1);
			}, 1000);
			return () => clearTimeout(timeoutId);
		}
	}, [showInactiveModal, countdown]);

	useEffect(() => {
		if (countdown <= 0) {
			onLogout("?expired=true");
		}
	}, [countdown, onLogout]);

	const handleLogout = async () => {
		setIsLoading(true);
		await onLogout("?expired=true");
		setIsLoading(false);
	};

	const handleStayLoggedIn = async () => {
		setIsLoading(true);
		if (countdown > 0) {
			await mutate();
			resetSessionTimeout();
		} else {
			await onLogout("?expired=true");
		}
		setIsLoading(false);
	};

	return (
		<>
			<Dialog
				isOpen={showInactiveModal}
				actions={
					<>
						<Button
							variant="secondary"
							onClick={handleLogout}
							disabled={isLoading}
						>
							Log out
						</Button>
						<Button onClick={handleStayLoggedIn} disabled={isLoading}>
							Stay logged in
						</Button>
					</>
				}
			>
				<img
					className={styles.icon}
					src={timeoutClockSrc}
					alt=""
					width={60}
					height={60}
				/>
				<h1 className={styles.title}>Are you still there?</h1>
				<p className={styles.description}>
					You’ve been inactive for a while. For your security, you’ll be logged
					out in{" "}
					<strong>
						{countdown} {countdown === 1 ? "second" : "seconds"}
					</strong>
					.
				</p>
			</Dialog>
		</>
	);
};
