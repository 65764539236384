import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

import styles from "./index.module.css";
import { FormErrors } from "@app/utils/get-form-errors";
import { useState } from "react";
import { ApiErrors } from "@app/components/api-errors";

export const ConfirmationEnableOtpModal = ({
	onClose,
	onConfirm,
	closeText = "Skip",
	onBack,
	isOpen,
}: {
	onClose: () => void;
	onBack?: () => void;
	// biome-ignore lint: Need to support async and sync callbacks
	onConfirm: () => void | Promise<FormErrors | void>;
	closeText?: string;
	isOpen: boolean;
}) => {
	const [errors, setErrors] = useState<Array<string>>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleConfirm = async () => {
		setIsSubmitting(true);
		const errors = await onConfirm();
		setIsSubmitting(false);
		if (errors) {
			setErrors(errors.apiErrors);
		}
	};

	return (
		<Dialog
			isOpen={isOpen}
			onClose={onBack}
			title="Enable OTP"
			description="To secure your account, we strongly recommend enabling OTP (One-Time Pin) authentication."
			actions={
				<>
					<Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
						{closeText}
					</Button>
					<Button onClick={handleConfirm} disabled={isSubmitting}>
						Continue
					</Button>
				</>
			}
		>
			<p className={styles.description}>
				This will send a code to your mobile number when logging into a new
				device or changing sensitive data.
			</p>
			<ApiErrors errors={errors} />
		</Dialog>
	);
};
