import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NotFoundModal } from "@app/components/not-found-modal";
import { paths } from "@app/constants/paths";
import { tempCompletedTransactionMapping } from "@app/hooks/use-completed-transactions";
import { tempExchangeDetailsMapping } from "@app/hooks/use-exchange-details";
import { tempPaymentStatusMapping } from "@app/hooks/use-payment-status";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { ViewTransactionView } from "./view-transaction-view";

const ViewTransaction = ({
	enableEditButtons,
	onEditPayment,
	onEditDocument,
	onEditBop,
	apiErrors,
}: {
	enableEditButtons?: boolean;
	onEditPayment?: () => void;
	onEditDocument?: () => void;
	onEditBop?: () => void;
	children?: ReactNode;
	apiErrors?: Array<ReactNode>;
}) => {
	const [showNotFoundModal, setShowNotFoundModal] = useState(false);
	const transactionId = useTransactionId();
	const {
		transaction,
		exchangeDetails,
		paymentStatus,
		exchangeDetailsError,
		payment,
	} = useTransaction(transactionId);
	const navigate = useNavigate();

	const onBack = () => navigate(paths().transactions());

	useEffect(() => {
		if (exchangeDetailsError && exchangeDetailsError.status === 404) {
			setShowNotFoundModal(true);
		}
	}, [exchangeDetailsError]);

	return (
		<>
			<ViewTransactionView
				onEditBop={onEditBop}
				onEditDocument={onEditDocument}
				onEditPayment={onEditPayment}
				enableEditButtons={enableEditButtons}
				apiErrors={apiErrors}
				onBack={onBack}
				payment={payment}
				transaction={
					transaction ? tempCompletedTransactionMapping(transaction) : undefined
				}
				paymentStatus={
					paymentStatus ? tempPaymentStatusMapping(paymentStatus) : undefined
				}
				exchangeDetails={tempExchangeDetailsMapping(exchangeDetails)}
			/>
			<NotFoundModal
				isOpen={showNotFoundModal}
				description="The requested transaction was not found."
			/>
		</>
	);
};

export default ViewTransaction;
