import { FiX } from "react-icons/fi";
import { StatusCell } from "../status-cell";
import { TransactionStatusCell } from "../transaction-status-cell";
import { AccountStatus, AccountTransactionStatus } from "../use-accounts";

import styles from "./index.module.css";

export const AppliedTags = ({
	status = [],
	transactionStatus = [],
	onRemoveStatus,
	onRemoveTransactionStatus,
}: {
	status?: Array<AccountStatus>;
	transactionStatus?: Array<AccountTransactionStatus>;
	onRemoveStatus: (status: AccountStatus) => void;
	onRemoveTransactionStatus: (status: AccountTransactionStatus) => void;
}) => {
	return (
		<ul className={styles.container}>
			{status.map((status) => (
				<li key={status} className={styles.tag}>
					<StatusCell>{status}</StatusCell>
					<button
						aria-label="Remove"
						type="button"
						onClick={() => onRemoveStatus(status)}
					>
						<FiX size={14} color="#B0B0B0" />
					</button>
				</li>
			))}
			{transactionStatus.map((status) => (
				<li key={status} className={styles.tag} data-variant={status}>
					<TransactionStatusCell variant="default">
						{status}
					</TransactionStatusCell>
					<button
						aria-label="Remove"
						type="button"
						onClick={() => onRemoveTransactionStatus(status)}
					>
						<FiX size={14} color="#B0B0B0" />
					</button>
				</li>
			))}
		</ul>
	);
};
