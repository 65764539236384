import { useClients } from "@app/hooks/use-clients";
import { Form } from "./form";

export const IndividualPersonalInformation = ({
	isAddClient = false,
}: {
	isAddClient?: boolean;
}) => {
	const { activeClientId } = useClients(!isAddClient);

	return <Form activeClientId={activeClientId} isAddClient={isAddClient} />;
};
