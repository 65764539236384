import { type ReactNode, useState } from "react";
import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Dropdown } from "@app/components/controls";
import { Typography } from "@app/components/typography";
import type { DropdownOption } from "@app/entities";

import { FieldError } from "@app/components/field-error";
import { FiCheck } from "react-icons/fi";
import styles from "../index.module.css";
import { showMappedReasons } from "../show-mapped-reasons";
import type { DropDownOptionPartialProps } from "../types";
import { SingleSelectDialog } from "@app/components/single-select-dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";

const splitter = "___SPLITTER____";

export const DropDownOption = (props: DropDownOptionPartialProps) => {
	const isMobile = useMediaQuery();
	const itemTemplate = (option: DropdownOption, value: any): ReactNode => {
		if (option.value === (value as number)) {
			return (
				<>
					{option.label}
					<FiCheck size={20} color="#888" />
				</>
			);
		}

		return <>{option.label}</>;
	};

	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				const [focused, setFocused] = useState(false);

				const hasError =
					!!error ||
					showMappedReasons(
						props.mappedName ?? field.name,
						props.mappedReasons,
					) !== false;

				return (
					<div
						className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}
					>
						{props.showLabel ? (
							<div className={styles.dropdownLabel}>
								<Typography
									theme="textSm"
									className={`${styles.formLabel} ${
										focused ? "text-teal-550" : "text-gray-1100"
									}`}
								>
									{props.title +
										(props.required && !props.hideAsterisk ? "*" : "")}
								</Typography>
								{props.popover && props.popover}
							</div>
						) : (
							<></>
						)}

						<div className={styles.fieldInputContainer}>
							{isMobile ? (
								<SingleSelectDialog
									className={props.className}
									title={props.title || props.placeholder}
									placeholder={props.placeholder}
									actions={props.mobileActions}
									value={
										field.value
											? {
													id: field.value,
													name: field.value,
												}
											: undefined
									}
									options={props.options.map((current) => {
										const [label, subtext] = current.label.split(splitter);

										return {
											id: current.value,
											name: label,
											subtext,
										};
									})}
									onChange={(option) => {
										const value = option?.id;
										field.onChange(value);
										if (props.onChange) props.onChange(value);
									}}
									error={hasError}
								/>
							) : (
								<Dropdown
									containerClassName="flex-1"
									appendToParent={props.appendToParent}
									className={twMerge(
										props.className,
										focused && "border-teal-550",
									)}
									panelClassName={twMerge(
										"form-dropdown",
										props.filter && "filterable",
										props.panelClassName ?? "",
									)}
									disabled={props.disabled}
									options={props.options || []}
									{...field}
									filter={props.filter}
									filterPlaceholder={props.filterPlaceholder}
									iconColour={props.iconColour}
									iconSize={props.iconSize}
									optionLabel={props.optionLabel || undefined}
									placeholder={props.placeholder}
									itemTemplate={props.itemTemplate ?? itemTemplate}
									onChange={(value) => {
										if (props.onChange) props.onChange(value);
										field.onChange(value);
									}}
									onHide={() => setFocused(false)}
									onShow={() => setFocused(true)}
									valueTemplate={props.valueTemplate}
									panelFooterTemplate={props.panelFooterTemplate}
									dropdownRef={props.dropdownRef}
									error={hasError}
								/>
							)}

							{props.actions}
						</div>
						{error && <FieldError>{error.message}</FieldError>}
						{!error &&
							showMappedReasons(
								props.mappedName ?? field.name,
								props.mappedReasons,
							)}
					</div>
				);
			}}
		/>
	);
};
