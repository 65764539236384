import { Button } from "@app/components/button";
import { ExchangeDetailsBlock } from "@app/components/exchange-details-block";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { useMediaQuery } from "@app/hooks/use-media-query";

import type {
	GetExchangeDetails,
	ListTransactionInProgress,
} from "@app/entities";
import { FiChevronLeft } from "react-icons/fi";
import { BopDetailsBlock } from "./bop-details-block";

import { ApiErrors } from "@app/components/api-errors";
import { StatusTag } from "@app/components/status-tag";
import { tempPaymentStatusMapping } from "@app/hooks/use-payment-status";
import { ReactNode } from "react";
import { PaymentDetailsBlock } from "../../components/payment-details-block";
import { Payment } from "../transaction-details/payment-details/use-payment";
import { DocumentsDetailsBlock } from "./documents-details-block";
import { DownloadDropdown } from "./download-dropdown";
import styles from "./index.module.css";
import { TransactionProgress } from "./transaction-progress";
import { LegalDisclaimer } from "@app/components/legal-disclaimer";

const Transaction = ({
	transaction,
	exchangeDetails,
	paymentStatus,
	enableEditButtons,
	onBack,
	onEditPayment,
	onEditBop,
	onEditDocument,
	apiErrors,
}: {
	enableEditButtons?: boolean;
	onBack: () => void;
	transaction?: ListTransactionInProgress;
	exchangeDetails?: GetExchangeDetails;
	paymentStatus?: ReturnType<typeof tempPaymentStatusMapping>;
	onEditPayment?: () => void;
	onEditBop?: () => void;
	onEditDocument?: () => void;
	apiErrors?: Array<ReactNode>;
}) => {
	const isMobile = useMediaQuery();

	const showDownloadButton =
		exchangeDetails?.dealConfirmationReady && !enableEditButtons && !isMobile;

	return (
		<div className={styles.container}>
			{!enableEditButtons && (
				<div className={styles.heading}>
					<Button noPadding inline onClick={onBack} variant="tertiary">
						<FiChevronLeft size={20} color="#56A7A2" />
						Back to transactions
					</Button>

					{exchangeDetails?.referenceId ? (
						<p className={styles.reference}>
							ID: {exchangeDetails?.referenceId}
						</p>
					) : (
						<div />
					)}
				</div>
			)}
			<div className={styles.content}>
				<div className={styles.contentHeading}>
					<div className={styles.contentHeadingInner}>
						<h2 className={styles.title}>
							{enableEditButtons ? "Review transaction" : "View transaction"}
						</h2>
						<StatusTag status={paymentStatus?.statusTag} />
					</div>
					{showDownloadButton ? <DownloadDropdown /> : <div />}
					{isMobile && exchangeDetails?.referenceId && (
						<p className={styles.reference}>
							ID: {exchangeDetails?.referenceId}
						</p>
					)}
				</div>
				<TransactionProgress data={paymentStatus?.inProgressDetails} />
				<ExchangeDetailsBlock
					variant={
						enableEditButtons || paymentStatus?.statusTag !== "complete"
							? "default"
							: "view"
					}
					transaction={transaction}
					exchangeDetails={exchangeDetails}
				/>
				<div className={styles.contentContainer}>
					<div className={styles.content}>
						<PaymentDetailsBlock
							transactionId={exchangeDetails?.transactionId}
							onEdit={onEditPayment}
						/>
					</div>
					<div className={styles.aside}>
						<BopDetailsBlock
							transactionId={exchangeDetails?.transactionId}
							onEdit={onEditBop}
						/>
						<DocumentsDetailsBlock
							transactionId={exchangeDetails?.transactionId}
							onEdit={onEditDocument}
						/>
					</div>
				</div>
			</div>
			<ApiErrors autoFocus className={styles.apiErrors} errors={apiErrors} />
		</div>
	);
};

export const ViewTransactionView = (props: {
	enableEditButtons?: boolean;
	onBack: () => void;
	transaction?: ListTransactionInProgress;
	exchangeDetails?: GetExchangeDetails;
	paymentStatus?: ReturnType<typeof tempPaymentStatusMapping>;
	onEditPayment?: () => void;
	payment?: Payment;
	onEditBop?: () => void;
	onEditDocument?: () => void;
	apiErrors?: Array<ReactNode>;
}) => {
	if (props.enableEditButtons) return <Transaction {...props} />;
	return (
		<SignedInLayout
			title="View Transaction"
			hasBackButton
			mobileRightSection={<DownloadDropdown />}
		>
			<Transaction {...props} />
			<LegalDisclaimer className={styles.legalDisclaimer} />
		</SignedInLayout>
	);
};
