import { DocumentUploadModal } from "@app/components/document-upload-modal";

import { ComponentPropsWithoutRef, ReactNode, useMemo, useState } from "react";

import {
	OnboardingDocumentType,
	useOnboardingDocument,
} from "./use-onboarding-document";

import { abortRequests } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { AxiosProgressEvent } from "axios";

import { FieldError as RHFFieldError } from "react-hook-form";

import { DocumentFieldContent } from "@app/components/new-document-field/document-field-content";

export const DocumentField = ({
	type,
	label,
	value,
	onChange,
	tooltip,
	error,
	tooltipWidth = 260,
	relatedEntityId,
	disabled,
	className,
	...rest
}: {
	type: OnboardingDocumentType;
	label: string;
	value?: string | null;
	onChange?: () => Promise<void>;
	tooltip?: ReactNode;
	error?: RHFFieldError;
	tooltipWidth?: number;
	disabled?: boolean;
	className?: string;
	relatedEntityId?: number;
} & Omit<ComponentPropsWithoutRef<"div">, "onChange">) => {
	const [removeId, setRemoveId] = useState<string | null>(null);
	const [downloadId, setDownloadId] = useState<string | null>(null);
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const { uploadDocument, downloadDocument, mutate, data, deleteDocument } =
		useOnboardingDocument(type, relatedEntityId);
	const [showUpload, setShowUpload] = useState(false);

	const handleRemove = async (removeId: string) => {
		setRemoveId(removeId);
		await deleteDocument();
		setUploadedFile(null);
		await onChange?.();
		setRemoveId(null);
	};

	const info = useMemo(() => {
		if (!data && !uploadedFile) return null;
		if (uploadedFile && !data)
			return {
				name: uploadedFile.name,
				size: uploadedFile.size,
				document_id: Date.now().toString(),
			};
		if (data)
			return {
				name: data.name,
				document_id: data.name,
				size: data.size,
			};
	}, [uploadedFile, data]);

	return (
		<>
			<DocumentFieldContent
				label={label}
				tooltip={tooltip}
				downloadId={downloadId}
				tooltipWidth={tooltipWidth}
				error={error}
				disabled={disabled}
				className={className}
				files={info ? [info] : []}
				uploadedFiles={uploadedFile ? [uploadedFile] : []}
				onShowUpload={() => setShowUpload(true)}
				removeId={removeId}
				onRemove={handleRemove}
				onDownload={async (documentId) => {
					setDownloadId(documentId);
					await downloadDocument();
					setDownloadId(null);
				}}
				{...rest}
			/>

			<DocumentUploadModal
				title={label}
				isOpen={showUpload}
				description={tooltip}
				multiple={false}
				maxFiles={1}
				showSingleButton
				onClose={() => setShowUpload(false)}
				onConfirmUpload={async () => {
					await onChange?.();
					setShowUpload(false);
				}}
				onCancelUpload={() => {
					abortRequests();
				}}
				onDeleteItem={() => {
					if (data) {
						handleRemove(data.name);
					}
				}}
				onUpload={async (
					index: number,
					file: File | undefined,
					onSetUploadComplete: (index: number, id?: number | string) => void,
					onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
					onUploadError: (error: string, index: number) => void,
				) => {
					if (!file) return;
					try {
						await uploadDocument(file, onSetUploadProgress);
						setUploadedFile(file);
						onSetUploadComplete(index);
						mutate();
					} catch (error) {
						if (error) {
							const { fieldErrors, apiErrors } = getFormErrors(error);
							if (apiErrors.length > 0) {
								onUploadError(apiErrors[0], index);
								return;
							}

							if (fieldErrors.length > 0) {
								onUploadError(fieldErrors[0].message, index);
								return;
							}

							const errorMessage = apiErrors[0] ?? "Upload failed";
							onUploadError(errorMessage, index);
						}
					}
				}}
			/>
		</>
	);
};
