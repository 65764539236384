import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import AlertCircleIcon from "@app/icons/alert-circle.svg?react";

import styles from "./index.module.css";
import { FormErrors } from "@app/utils/get-form-errors";
import { ApiErrors } from "@app/components/api-errors";
import { useState } from "react";

export const ConfirmationDisableOtpModal = ({
	onClose,
	onConfirm,
	closeText = "Back",
	isOpen = true,
}: {
	onClose: () => void;
	closeText?: string;
	// biome-ignore lint: Need to support async and sync callbacks
	onConfirm: () => void | Promise<FormErrors | void>;
	isOpen?: boolean;
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [errors, setErrors] = useState<Array<string>>([]);
	return (
		<Dialog
			isOpen={isOpen}
			title="Disable OTP"
			onClose={closeText !== "Back" ? onClose : undefined}
			description="Your account is more vulnerable to fraud and hacking without OTP authentication enabled."
			actions={
				<>
					<Button
						minWidth={closeText !== "Back" ? 110 : undefined}
						disabled={isSubmitting}
						variant="secondary"
						onClick={onClose}
					>
						{closeText}
					</Button>
					<Button
						disabled={isSubmitting}
						data-testid="disable-otp"
						className={styles.confirmButton}
						onClick={async () => {
							setIsSubmitting(true);
							const errors = await onConfirm();
							setIsSubmitting(false);
							if (errors) setErrors(errors.apiErrors);
						}}
					>
						<AlertCircleIcon width={20} height={20} />
						Disable OTP
					</Button>
				</>
			}
		>
			<p className={styles.description}>Are you sure you want to disable it?</p>
			<ApiErrors className={styles.errors} errors={errors} />
		</Dialog>
	);
};
