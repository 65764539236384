import { arbitrageApiUrl } from "@app/config/env";
import axios from "axios";

export const isValidArbSession = async (token: string) => {
	try {
		await axios.get(`${arbitrageApiUrl}api/auth/users/me/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		});
		return true;
	} catch {
		return false;
	}
};
