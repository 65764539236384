import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@app/components/button";
import { FadeIn } from "@app/components/fade-in";
import { Title } from "@app/components/title";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";

import { Checkbox } from "@app/components/checkbox";
import { EntityType } from "@app/types";
import { setFormErrors } from "@app/utils/set-form-errors";
import { ApiErrors } from "../../../components/api-errors";
import { isValidEmail } from "../is-valid-email";
import { EmailIcon } from "../login/email-icon";
import { LockIcon } from "../login/lock-icon";
import { PasswordToggleButton } from "../password-toggle-button";
import { TextField } from "../text-field";
import styles from "./index.module.css";
import { RegisterComplete } from "./register-complete";
import { RegisterOptions } from "./register-options";
import { RegisterPayload, useRegister } from "./use-register";

type Inputs = {
	email: string;
	password: string;
	confirmPassword?: string;
};

const Register = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const { email, onRegister, onResendEmail } = useRegister();
	const [terms, setTerms] = useState(false);
	const [showTermsError, setShowTermsError] = useState(false);
	const [apiErrors, setApiErrors] = useState<Array<ReactNode>>([]);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [isRegistrationComplete, setRegistrationComplete] = useState(false);
	const paramType = searchParams.get("type") ?? undefined;
	const [registerAs, setRegisterAs] = useState<EntityType | undefined>(
		paramType === "business"
			? "legal_entity"
			: (paramType as EntityType | undefined),
	);

	useEffect(() => {
		if ([undefined, "individual", "legal_entity"].includes(registerAs)) return;
		navigate(paths().login);
	}, [registerAs, navigate]);

	const {
		register,
		handleSubmit,
		watch,
		setError,
		clearErrors,
		formState: { errors },
		reset,
	} = useForm<Inputs>({
		defaultValues: {
			email: "",
			password: "",
			confirmPassword: "",
		},
		mode: "onTouched",
	});

	const onBack = () => setRegisterAs(undefined);

	const onLogin = async () => {
		navigate(paths().login);
	};

	const clearApiFieldErrors = () => {
		for (const key of Object.keys(errors)) {
			if (errors[key as keyof Inputs]?.type === "api")
				clearErrors(key as keyof Inputs);
		}
	};

	const onRegisterAgain = () => {
		reset({
			email: "",
			password: "",
			confirmPassword: "",
		});
		setRegisterAs(undefined);
		setRegistrationComplete(false);
	};

	const handleResend = async () => {
		const errors = await onResendEmail();
		if (errors && errors.length > 0) {
			setApiErrors(errors);
			return false;
		}
		return true;
	};

	return (
		<form
			onSubmit={handleSubmit(async (data) => {
				if (!terms) {
					setShowTermsError(true);
					return;
				}
				clearApiFieldErrors();

				setIsLoading(true);
				const payload: RegisterPayload = {
					email: data.email,
					password: data.password,
					entity_type: registerAs!,
				};
				if (searchParams.get("referral_code")) {
					payload.registration_referral_code =
						searchParams.get("referral_code")!;
				}
				const errors = await onRegister(payload);
				setIsLoading(false);
				if (errors) {
					setFormErrors(errors, setError, setApiErrors, true);
					return;
				}
				setRegistrationComplete(true);
			})}
			noValidate
		>
			{isRegistrationComplete ? (
				<RegisterComplete
					email={email}
					onVerified={onLogin}
					onRegisterAgain={onRegisterAgain}
					onResendEmail={handleResend}
				/>
			) : registerAs === undefined ? (
				<RegisterOptions
					onRegisterAs={(target) => {
						setRegisterAs(target);
						searchParams.set(
							"type",
							target === "legal_entity" ? "business" : "individual",
						);
						navigate({ search: searchParams.toString() });
					}}
				/>
			) : (
				<>
					<Title>
						{registerAs === "individual"
							? "Forex for Individuals"
							: "Forex for Business"}
					</Title>

					<div className={styles.form}>
						<TextField
							register={register}
							name="email"
							label="Email"
							type="email"
							autoComplete="email"
							validate={isValidEmail}
							required
							leftSection={<EmailIcon />}
							error={errors.email}
						/>

						<TextField
							register={register}
							name="password"
							label="Password"
							autoComplete="new-password"
							type={showPassword ? "text" : "password"}
							required
							validate={(value) => {
								// NOTE: Confirm with backend team prior to making any validation changes
								// Current validation requirements:
								// Password must contain at least 8 characters
								// Password must contain at least one upper-case character
								// Password must contain at least one special character
								// Password must match the confirm password field

								if (value.length < 8) {
									return "Password must contain at least 8 characters";
								}

								if (!/[A-Z]/.test(value))
									return "Password must contain at least one upper-case character";

								if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value))
									return "Password must contain at least one special character";

								const confirmPassword = watch("confirmPassword");
								if (confirmPassword && value !== confirmPassword) {
									setError("confirmPassword", {
										type: "manual",
										message: "Password doesn’t match",
									});
								} else if (errors.confirmPassword?.type === "manual") {
									clearErrors("confirmPassword");
								}

								return true;
							}}
							leftSection={<LockIcon />}
							rightSection={
								<PasswordToggleButton
									value={showPassword}
									onChange={setShowPassword}
								/>
							}
							error={errors.password}
						/>
						<TextField
							register={register}
							name="confirmPassword"
							label="Confirm password"
							type={showConfirmPassword ? "text" : "password"}
							required
							leftSection={<LockIcon />}
							validate={(value) => {
								const password = watch("password");

								if (value !== password) {
									return "Password doesn’t match";
								}
								return true;
							}}
							rightSection={
								<PasswordToggleButton
									value={showConfirmPassword}
									onChange={setShowConfirmPassword}
								/>
							}
							error={errors.confirmPassword}
						/>
					</div>

					<div className={styles.termsContainer}>
						<div className={styles.terms}>
							<Checkbox
								id="terms"
								value={terms}
								onChange={(value: boolean) => {
									setTerms(value);
									if (value) setShowTermsError(false);
								}}
								error={showTermsError}
							/>

							<label className={styles.termsLabel} htmlFor="terms">
								I agree to Future Forex's{" "}
								<a
									target="_blank"
									className={styles.link}
									href={links.privacyPolicy}
									rel="noreferrer"
								>
									Privacy Policy
								</a>{" "}
								and{" "}
								<a
									target="_blank"
									className={styles.link}
									href={links.termsOfService}
									rel="noreferrer"
								>
									Terms of Service{" "}
								</a>
								.
							</label>
						</div>
						{showTermsError && (
							<p className={styles.termsError}>
								This is required in order to continue
							</p>
						)}
					</div>

					<FadeIn className={styles.errors} show={apiErrors.length > 0}>
						<ApiErrors errors={apiErrors} />
					</FadeIn>

					<div className={styles.buttons}>
						<Button block centered type="submit" disabled={isLoading}>
							{isLoading ? "Registering" : "Register"}
						</Button>
						<Button
							block
							disabled={isLoading}
							variant="tertiary"
							centered
							onClick={onBack}
						>
							Back
						</Button>
					</div>
				</>
			)}
		</form>
	);
};

export default Register;
