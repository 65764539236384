import { CountryIcon } from "@app/components/country-icon";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useCurrencies } from "@app/hooks/use-currencies";
import { joinClasses } from "@app/utils";
import styles from "./index.module.css";

const COLLAPSE_LENGTH = 3;

export const CurrencyTags = ({
	currencies,
	className,
}: {
	currencies?: string[];
	className?: string;
}) => {
	const { data } = useCurrencies();
	const validCurrencies = currencies?.filter((currency) => !!currency);
	if (!validCurrencies || validCurrencies.length === 0) return null;

	const getCurrencyLabel = (currency: string) => {
		const formatted = currency.toUpperCase();
		const currencyData = data?.currency_mapping.find(
			(item) => item.currency_code === formatted,
		);
		if (!currencyData) return formatted;
		return `${formatted} - ${currencyData.verbose_name}`;
	};

	if (validCurrencies.length <= COLLAPSE_LENGTH) {
		return (
			<div className={joinClasses(styles.container, className)}>
				{validCurrencies.map((currency) => (
					<div key={currency} className={styles.tag}>
						{currency !== "all" ? (
							<CountryIcon width={20} height={20} currencyCode={currency} />
						) : null}
						{getCurrencyLabel(currency)}
					</div>
				))}
			</div>
		);
	}
	return (
		<MoreInfoTooltip
			maxWidth={220}
			name="Currencies"
			indicator={
				<div className={joinClasses(styles.container, className)}>
					<div className={styles.tag}>
						Currencies
						<div className={styles.count}>{validCurrencies.length}</div>
					</div>
				</div>
			}
		>
			<ul className={styles.list}>
				{validCurrencies
					.filter((currency) => currency !== "all")
					.map((currency) => (
						<li className={styles.item} key={currency}>
							<CountryIcon width={16} height={16} currencyCode={currency} />
							{getCurrencyLabel(currency)}
						</li>
					))}
			</ul>
		</MoreInfoTooltip>
	);
};
