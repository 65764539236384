import { CountryIcon } from "@app/components/country-icon";

import styles from "./index.module.css";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";

export const RecipientCurrencies = ({ currncies }: { currncies?: string }) => {
	if (!currncies) return <span className={styles.empty}>-</span>;

	const values = currncies.split(",").filter(Boolean);
	const first = values[0];

	return (
		<div className={styles.container}>
			{first !== "all" && (
				<CountryIcon width={24} height={24} currencyCode={first} />
			)}
			<span className={styles.currency}>{first}</span>
			{values.length > 1 && (
				<MoreInfoTooltip
					indicator={<p className={styles.count}>+{values.length - 1}</p>}
				>
					<ul className={styles.list}>
						{values.slice(1).map((value) => (
							<li className={styles.item} key={value}>
								<CountryIcon width={16} height={16} currencyCode={value} />
								{value.toUpperCase()}
							</li>
						))}
					</ul>
				</MoreInfoTooltip>
			)}
		</div>
	);
};
