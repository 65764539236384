import { joinClasses } from "@app/utils";
import { ReactNode } from "react";
import { FiX } from "react-icons/fi";
import CopyIcon from "@app/icons/copy.svg?react";
import { MdCheck, MdInfoOutline, MdWarningAmber } from "react-icons/md";
import styles from "./toast-variants.module.css";

export type ToastType =
	| "error"
	| "info"
	| "success"
	| "warn"
	| "copy"
	| "unstable"
	| "offline";

export interface ToastTheme {
	icon?: ReactNode;
	className?: string;
	content?: ReactNode;
	persistent?: boolean;
}

export const types: { [key in ToastType]: ToastTheme } = {
	error: {
		icon: <FiX size={20} color="#f04438" />,
		className: joinClasses(styles.toast, "border-red-500", "bg-red-100"),
		content: "Error",
	},
	offline: {
		icon: (
			<svg
				role="presentation"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M21.7003 16.1181C21.8939 15.616 22 15.0704 22 14.5C22 12.1564 20.2085 10.2313 17.9203 10.0194C17.4522 7.17213 14.9798 5 12 5C11.5534 5 11.1183 5.04879 10.6995 5.14132M7.28746 7.28585C6.67317 8.06419 6.24759 8.99838 6.07974 10.0194C3.79151 10.2313 2 12.1564 2 14.5C2 16.9853 4.01472 19 6.5 19H17.5C17.9561 19 18.3963 18.9322 18.8112 18.806M3 3L21 21"
					stroke="#F04438"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		className: joinClasses(styles.toast, "border-red-500", "bg-red-100"),
		content: "Connection Lost",
		persistent: true,
	},
	unstable: {
		icon: (
			<svg
				role="presentation"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 9V13M12 16H12.01M6.5 19C4.01472 19 2 16.9853 2 14.5C2 12.1564 3.79151 10.2313 6.07974 10.0194C6.54781 7.17213 9.02024 5 12 5C14.9798 5 17.4522 7.17213 17.9203 10.0194C20.2085 10.2313 22 12.1564 22 14.5C22 16.9853 19.9853 19 17.5 19C13.1102 19 10.3433 19 6.5 19Z"
					stroke="#FDB022"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		className: joinClasses(styles.toast, "border-yellow-500", "bg-yellow-100"),
		content: "Unstable Connection",
		persistent: true,
	},
	info: {
		icon: <MdInfoOutline size={20} color="#969696" />,
		className: joinClasses(styles.toast, "border-gray-500", "bg-gray-100"),
		content: "Info",
	},
	success: {
		icon: <MdCheck size={20} color="#008e17" />,
		className: joinClasses(styles.toast, "border-teal-550", "bg-teal-50"),
		content: "Success",
	},
	warn: {
		icon: <MdWarningAmber size={20} color="#c9bb22" />,
		className: joinClasses(styles.toast, "border-yellow-500", "bg-yellow-100"),
		content: "Warning",
	},
	copy: {
		icon: <CopyIcon width={24} height={24} />,
		className: joinClasses(styles.toast, "border-teal-550", "bg-teal-50"),
		content: "Copy",
	},
};
