import { type ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { paths } from "@app/constants/paths";
import { useCsrfToken } from "@app/hooks/use-csrf-token";
import { useVerifySession } from "./use-verify-session";

import { PageLoader } from "@app/components/page-loader";
import { preloadClientOnboardingStatus } from "@app/components/signed-in-layout/use-client-onboarding-status";
import { useUnsupportedBrowserRedirect } from "@app/features/unsupported-browser/use-unsupported-browser-redirect";
import { preloadUserProfile } from "@app/features/user-settings/use-user-profile";
import { preloadClientProfile } from "@app/hooks/use-client-profile";
import { useClients } from "@app/hooks/use-clients";
import {
	getOnboardingRouteFromPage,
	preloadFormProgress,
} from "@app/hooks/use-onboarding-status";
import { handleGeneralError } from "@app/utils/handle-general-error";
import { STORAGE_KEYS } from "@app/constants/storage-keys";
import { links } from "@app/constants/links";
import {
	isAccountsEnabled,
	isLoginSelectionEnabled,
} from "@app/constants/feature-flags";
import { isValidArbSession } from "@app/utils/is-valid-arb-session";

const handleGoToArb = (token: string, isAddClient: boolean) => {
	let redirectUrl = links.arbitrage.base;
	if (isAddClient) {
		redirectUrl += "clients?&new=true&login-source=OFP";
		window.sessionStorage.removeItem(STORAGE_KEYS.arbAddClient);
	} else {
		redirectUrl = `${redirectUrl}?login-source=OFP`;
	}

	if (token) {
		redirectUrl += `&auth-token=${token}`;
	}
	window.location.href = redirectUrl;
};

export const Auth = ({ children }: { children: ReactNode }) => {
	useUnsupportedBrowserRedirect();
	const [isInitialised, setIsInitialised] = useState(false);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const location = useLocation();
	const {
		data,
		isLoading: isVerifySessionLoading,
		error: verifySessionError,
	} = useVerifySession();
	const userIsAuthenticated = !!data?.ok;
	const {
		isLoading: isClientsLoading,
		activeClientId,
		count: clientCount,
	} = useClients(userIsAuthenticated);

	useCsrfToken(userIsAuthenticated);

	useEffect(() => {
		if (isVerifySessionLoading) return;
		if (isClientsLoading) return;

		const handleRedirect = async () => {
			const isArbAddClientEnabled =
				searchParams.get("add-client") === "arbitrage";

			const arbToken = window.localStorage.getItem(STORAGE_KEYS.arbToken);
			const arbSessionValid = arbToken && (await isValidArbSession(arbToken));

			if (isArbAddClientEnabled) {
				window.sessionStorage.setItem(STORAGE_KEYS.arbAddClient, "true");
				if (arbSessionValid) {
					handleGoToArb(arbToken, true);
					return;
				}
			}

			if (verifySessionError) {
				handleGeneralError(verifySessionError);
				return;
			}

			if (!userIsAuthenticated) {
				window.sessionStorage.setItem(STORAGE_KEYS.redirect, location.pathname);
				navigate(paths().login);
				return;
			}

			const isRootPath = location.pathname === "/";
			const hasPerformedLoginSelection =
				window.sessionStorage.getItem(STORAGE_KEYS.loginSelection) === "true";
			if (
				isLoginSelectionEnabled &&
				isRootPath &&
				arbSessionValid &&
				userIsAuthenticated &&
				!hasPerformedLoginSelection
			) {
				navigate(paths().selectLogin);
				return;
			}

			const hasMultipleClients =
				typeof clientCount === "number" && clientCount > 1;
			if (isAccountsEnabled && hasMultipleClients && isRootPath) {
				navigate(`${paths().accounts}${location.search}`);
				return;
			}

			const isOnboardingRoute = location.pathname.includes("onboarding");
			const isErrorRoute = location.pathname.includes("error");
			const isOTPCheck = !!searchParams.get("otp");

			const isAccountsPath = location.pathname === "/accounts";
			const isSettingsPath = location.pathname.includes("/user-settings");
			const isAccountsRedirect =
				hasMultipleClients && (isAccountsPath || isSettingsPath);

			if (
				!isOnboardingRoute &&
				!isErrorRoute &&
				!isOTPCheck &&
				!isAccountsRedirect
			) {
				try {
					const userProfile = await preloadUserProfile();
					if (activeClientId) {
						const clientOnboardingStatus =
							await preloadClientOnboardingStatus(activeClientId);
						if (
							["onboarding_individual", "onboarding_business"].includes(
								clientOnboardingStatus?.status,
							)
						) {
							const formProgress = await preloadFormProgress(activeClientId);
							const clientProfile = await preloadClientProfile(activeClientId);

							// Should display outbound getting started
							const showOutbound =
								clientProfile.lead_type === "outbound" &&
								!formProgress.awaiting_submission_details.current_page &&
								formProgress.awaiting_submission_details.steps_completed
									.length === 0;

							const showInboundCaptured =
								searchParams.get("captured") === "true";

							if (!showOutbound && !showInboundCaptured) {
								const path = getOnboardingRouteFromPage(
									formProgress.awaiting_submission_details.current_page,
									clientProfile.entity_type ?? userProfile.register_entity_type,
								);
								navigate(path);
								return;
							}
						}
					}
				} catch {
					setIsInitialised(true);
				}
			}

			setIsInitialised(true);
		};
		handleRedirect();
	}, [
		isVerifySessionLoading,
		isClientsLoading,
		userIsAuthenticated,
		navigate,
		verifySessionError,
		location,
		activeClientId,
		searchParams,
		clientCount,
	]);

	if (!isInitialised) return <PageLoader />;
	if (userIsAuthenticated) return <>{children}</>;
	return null;
};
