import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

import styles from "./index.module.css";
import { useUserProfile } from "../use-user-profile";
import { isChangeEmailAddressEnabled } from "@app/constants/feature-flags";
import dayjs from "dayjs";

export const VerificationLinkSentModal = ({
	isOpen,
	onClose,
	email,
}: {
	isOpen: boolean;
	onClose: () => void;
	email?: string;
}) => {
	const { data } = useUserProfile();

	return (
		<Dialog
			isOpen={isOpen}
			onClose={onClose}
			title="Verification email sent"
			actions={
				<Button centered onClick={onClose}>
					Close
				</Button>
			}
		>
			<div className={styles.content}>
				<p>
					We have sent an email to{" "}
					<strong>
						{email ?? "the email address associated with your account"}
					</strong>{" "}
					to verify your new email address.
				</p>
				{isChangeEmailAddressEnabled && data?.email_change_expires_at && (
					<p>
						The email link will be valid until{" "}
						{dayjs(data.email_change_expires_at).format("HH:mm")}.
					</p>
				)}
				<p>Click the link in the email to update your email address.</p>
			</div>
		</Dialog>
	);
};
