import { useEffect, useState } from "react";
import { ConfirmationDisableOtpModal } from "../modals/confirmation-disable-otp-modal";
import { ChangeOtpPasswordModal } from "../modals/change-otp-password-modal";
import { useOTP } from "../use-otp";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { ConfirmedDisableModal } from "../modals/confirmed-disable-modal";
import { getIsOTPError } from "../get-is-otp-error";

export const DisableOTPDeviceFlow = ({
	onComplete,
	onClose,
	isOpen,
}: {
	onComplete: () => void;
	onClose: () => void;
	isOpen: boolean;
}) => {
	const [startResendTimer, setStartResendTimer] = useState(false);
	const [status, setStatus] = useState<
		| "confirmation"
		| "change-password"
		| "otp"
		| "resend"
		| "confirmed"
		| undefined
	>(undefined);
	const { deleteOTPDevice } = useOTP();
	const [password, setPassword] = useState("");

	useEffect(() => {
		if (isOpen) {
			setStatus("confirmation");
			setStartResendTimer(false);
		} else {
			setStatus(undefined);
		}
	}, [isOpen]);

	return (
		<>
			{/* Step 1: Ask for confirmation */}
			<ConfirmationDisableOtpModal
				isOpen={status === "confirmation"}
				closeText="Cancel"
				onConfirm={() => {
					setStatus("change-password");
				}}
				onClose={onClose}
			/>

			{/* Step 2: Ask for password */}

			<ChangeOtpPasswordModal
				isOpen={status === "change-password"}
				onClose={() => {
					setStatus("confirmation");
				}}
				onConfirm={async (password) => {
					const errors = await deleteOTPDevice(password);
					if (errors && !getIsOTPError(errors.apiErrors)) return errors;
					setPassword(password);
					setStatus("otp");
					setStartResendTimer(true);
				}}
			/>

			{/* Step 3: Ask for OTP */}
			<OTPInputModal
				isOpen={status === "otp"}
				onResend={() => {
					setStatus("resend");
				}}
				onClose={() => {
					setStatus("change-password");
				}}
				onConfirm={async () => {
					const errors = await deleteOTPDevice(password);
					if (errors) return errors;
					setStatus("confirmed");
				}}
			/>

			<ResendOTPModal
				hasOTPBeenSent={startResendTimer}
				isOpen={status === "resend"}
				onConfirm={() => {
					setStatus("otp");
				}}
				onClose={() => {
					setStatus("otp");
				}}
			/>

			{/* Step 4: Confirmation */}
			<ConfirmedDisableModal
				isOpen={status === "confirmed"}
				onClose={onComplete}
			/>
		</>
	);
};
