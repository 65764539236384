import { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { Checkbox } from "@app/components/checkbox";

import styles from "./index.module.css";

export const MinTransactionWarningModal = ({
	isOpen,
	onConfirm,
	onClose,
}: {
	isOpen: boolean;
	onConfirm: () => void;
	onClose: () => void;
}) => {
	const [agree, setAgree] = useState(false);
	const [showError, setShowError] = useState(false);
	const isMobile = useMediaQuery();

	const handleConfirm = () => {
		if (!agree) return setShowError(true);

		onConfirm();
	};

	return (
		<Dialog
			isOpen={isOpen}
			title="Minimum transaction amount"
			onClose={onClose}
			actions={
				<>
					<Button variant="secondary" onClick={onClose}>
						Back
					</Button>
					<Button onClick={handleConfirm}>Confirm</Button>
				</>
			}
		>
			<p className={styles.warningMessage}>
				At present, we only assist clients{" "}
				<strong>transacting R100,000 or more</strong> over the course of a year.
				If you plan to transact less than this, we regrettably cannot assist
				you.
			</p>
			<div className={styles.agreeContainer}>
				<Checkbox
					id="agree"
					value={agree}
					onChange={(value: boolean) => {
						setAgree(value);
						if (value) setShowError(false);
					}}
				/>

				<label className={styles.agreeLabel} htmlFor="agree">
					I intend to transact R100,000{isMobile ? "+" : " or more"}
				</label>
			</div>
			{showError && (
				<p className={styles.error}>
					<FiAlertCircle className={styles.infoIcon} size={13} />
					Note that our minimum transaction amount is R100,000.
				</p>
			)}
		</Dialog>
	);
};
