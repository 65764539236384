import { links } from "@app/constants/links";

import styles from "./index.module.css";
import { isLegalDisclaimerEnabled } from "@app/constants/feature-flags";
import { LegalDisclaimer } from "@app/components/legal-disclaimer";

export const NeedAssistance = () => {
	return (
		<footer className={styles.footer}>
			{isLegalDisclaimerEnabled && (
				<p className={styles.topLink}>
					Need assistance?{" "}
					<a
						className={styles.link}
						href={links.contact}
						target="_blank"
						rel="noreferrer"
					>
						Get in touch
					</a>
				</p>
			)}
			<div className={styles.divider} data-testid="divider" />
			{isLegalDisclaimerEnabled ? (
				<LegalDisclaimer className={styles.legalDisclaimer} />
			) : (
				<p className={styles.bottomLink}>
					Need assistance?{" "}
					<a
						className={styles.link}
						href={links.contact}
						target="_blank"
						rel="noreferrer"
					>
						Get in touch
					</a>
				</p>
			)}
		</footer>
	);
};
