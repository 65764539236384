import { ReactNode } from "react";

import styles from "./index.module.css";

import { NotificationsItem } from "./notifications-item";
import { UserItem } from "./user-item";
import { useClients } from "@app/hooks/use-clients";
import { useLocation } from "react-router-dom";
import { AccountDetail } from "./account-detail";
import { isAccountsEnabled } from "@app/constants/feature-flags";

export const TopBar = ({
	variant = "default",
	isSettings = false,
	leftSection,
	isAddClient,
}: {
	variant?: "default" | "onboarding";
	isSettings?: boolean;
	leftSection?: ReactNode;
	isAddClient?: boolean;
}) => {
	const { count } = useClients();
	const location = useLocation();
	const isAccountsRoute = location.pathname.includes("/accounts");
	const isSettingsRoute = location.pathname.includes("/user-settings");
	const hasMultipleClients =
		isAccountsEnabled && typeof count === "number" && count > 1;

	const showBackNavigation =
		hasMultipleClients && !isAccountsRoute && !isSettingsRoute;

	return (
		<div
			className={styles.container}
			data-multiple-clients={showBackNavigation}
		>
			{showBackNavigation ? (
				<AccountDetail isAddClient={isAddClient} />
			) : (
				(leftSection ?? <div />)
			)}
			<div className={styles.actions}>
				{variant === "default" && <NotificationsItem />}
				<UserItem
					variant={variant}
					isSettings={isSettings}
					hasMultipleClients={hasMultipleClients}
				/>
			</div>
		</div>
	);
};
