import { useNavigate } from "react-router-dom";

import { Button } from "@app/components/button";
import { paths } from "@app/constants/paths";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { getFullName } from "@app/utils/get-full-name";
import { usePersonalInformation } from "@app/features/onboarding/individual-personal-information/use-personal-information";

import ArrowLeft from "./arrow-left.svg?react";
import styles from "./index.module.css";

export const AccountDetail = ({
	isAddClient = false,
}: {
	isAddClient?: boolean;
}) => {
	const navigate = useNavigate();
	const { data: clientProfile } = useClientProfile();
	const { data: personalInformation } = usePersonalInformation();

	const name = !isAddClient
		? clientProfile?.display_name
		: getFullName(personalInformation);

	return (
		<div className={styles.container}>
			<Button onClick={() => navigate(paths().accounts)}>
				<ArrowLeft width={20} height={20} />
				Back to accounts
			</Button>
			<div>
				<p className={styles.label}>Account</p>
				<p className={styles.name}>{name}</p>
			</div>
		</div>
	);
};
