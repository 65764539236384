import { Button } from "@app/components/button";
import { type ElementRef, useEffect, useRef } from "react";

import { Checkbox } from "@app/components/checkbox";
import { WarningNote } from "@app/components/warning-note";
import { paths } from "@app/constants/paths";
import { useTransaction } from "@app/hooks/use-transaction";
import styles from "./index.module.css";
import { STORAGE_KEYS } from "@app/constants/storage-keys";

export const DuplicateTransactionBlock = ({
	transactionId,
	checked,
	onChange,
	showError = false,
}: {
	transactionId?: number | null;
	checked: boolean;
	showError?: boolean;
	onChange: (checked: boolean) => void;
}) => {
	const ref = useRef<ElementRef<"div">>(null);
	const { onGoToTransaction, isLoading } = useTransaction(transactionId);

	useEffect(() => {
		if (ref.current && showError) {
			ref.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [showError]);

	const handleViewTransaction = () => {
		window.sessionStorage.setItem(
			STORAGE_KEYS.resumeLaterPath,
			paths().transactions(),
		);
		onGoToTransaction();
	};

	return (
		<WarningNote
			className={styles.container}
			title="Please note"
			description="This transaction appears to be a duplicate of one booked in the past 24 hours:"
		>
			<div>
				<div>
					<label className={styles.label} htmlFor="duplicate-transaction">
						<Checkbox
							name="duplicate-transaction"
							className={styles.checkbox}
							error={showError}
							onChange={(value: boolean) => onChange(value)}
							value={checked}
						/>
						<span>
							{" "}
							I confirm that this{" "}
							<strong>is not a duplicate transaction</strong> and would like to
							continue.
						</span>
					</label>
					{showError && (
						<p className={styles.error}>
							This is required in order to continue
						</p>
					)}
				</div>
				<Button
					className={styles.button}
					disabled={isLoading}
					onClick={handleViewTransaction}
					variant="tertiary"
					inline
				>
					View transaction
				</Button>
			</div>
		</WarningNote>
	);
};
