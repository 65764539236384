import { PageLoader } from "@app/components/page-loader";
import { isChangeEmailAddressEnabled } from "@app/constants/feature-flags";
import { paths } from "@app/constants/paths";
import { STORAGE_KEYS } from "@app/constants/storage-keys";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { handleGeneralError } from "@app/utils/handle-general-error";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ConfirmEmail = () => {
	const navigate = useNavigate();
	const { token, uid } = useParams<{
		token?: string;
		uid?: string;
	}>();

	useEffect(() => {
		if (!token || !uid) {
			handleGeneralError({
				navigateTo: paths().login,
			});
			return;
		}

		const verifyEmail = async () => {
			try {
				await api.get(`/users/confirm-email/${uid}/${token}/`);
				const targetEmail =
					window.localStorage.getItem(STORAGE_KEYS.registerEmail) ?? "";
				window.localStorage.removeItem(STORAGE_KEYS.registerEmail);
				navigate(`/login?verified=true&email=${targetEmail}`);
			} catch (error) {
				const { apiErrors, fieldErrors } = getFormErrors(error);
				const errorMessage = apiErrors[0] || fieldErrors[0]?.message;
				if (errorMessage) {
					if (isChangeEmailAddressEnabled) {
						navigate("/login?verified=false");
					} else {
						navigate(paths().login, {
							state: { errorMessage },
						});
					}
					return;
				}
				navigate(paths().login);
			}
		};

		verifyEmail();
	}, [token, uid, navigate]);

	return <PageLoader />;
};
