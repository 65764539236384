import { ApiErrors } from "@app/components/api-errors";
import { FadeIn } from "@app/components/fade-in";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { Note } from "@app/components/note";
import { TransactionDirection } from "@app/entities";
import { ValueDates, useValueDates } from "@app/hooks/use-value-dates";
import { toDayjs } from "@app/lib/date";
import { EntityType } from "@app/types";
import clsx from "clsx";
import { ReactNode, useCallback, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { FiAlertCircle } from "react-icons/fi";
import chevronUpSrc from "./chevron-up.svg";
import styles from "./value-date-select.module.css";
import { handleGeneralError } from "@app/utils/handle-general-error";

const ValueDateOptions = ({
	className,
	currentData,
	value,
	onChange,
	variant,
	hasError,
	disabledDates,
}: {
	className?: string;
	currentData: ValueDates;
	value?: string;
	onChange: (value: string) => void;
	variant: EntityType;
	hasError: boolean;
	disabledDates?: string[];
}) => {
	return (
		<ul className={clsx(styles.list, className)} data-variant={variant}>
			{currentData.value_dates.map((valueDate) => {
				const isSelected = valueDate.iso_date === value;
				const key = valueDate.description.toLowerCase();
				const isDisabledDate = disabledDates?.includes(key);

				const isDisabled = !valueDate.is_enabled || isDisabledDate;

				const showPrefundingRequiredTooltip =
					valueDate.is_enabled &&
					isDisabledDate &&
					valueDate.tooltips.prefunding_required;

				const showUnavailableTooltip =
					!valueDate.is_enabled && valueDate.tooltips.unavailable;
				const showTooltip =
					showPrefundingRequiredTooltip || showUnavailableTooltip;

				return (
					<li className={styles.item} key={valueDate.iso_date}>
						<button
							disabled={isDisabled}
							className={styles.button}
							type="button"
							onClick={() => onChange(valueDate.iso_date)}
							data-error={hasError}
							aria-current={isSelected}
						>
							<span className={styles.date}>
								{valueDate.date}
								{showTooltip && (
									<MoreInfoTooltip
										maxWidth={264}
										hasIcon
										indicator={
											<FiAlertCircle
												size={14}
												color={
													showPrefundingRequiredTooltip ? "#FDB022" : "#56A7A2"
												}
											/>
										}
									>
										{showPrefundingRequiredTooltip
											? valueDate.tooltips.prefunding_required
											: valueDate.tooltips.unavailable}
									</MoreInfoTooltip>
								)}
							</span>
							<span>{valueDate.description}</span>
						</button>
					</li>
				);
			})}
			{variant === "legal_entity" && (
				<li className={styles.item}>
					<button
						disabled
						className={styles.button}
						type="button"
						data-error={hasError}
					>
						<span className={styles.date}>FEC</span>
						<span>Coming soon!</span>
					</button>
				</li>
			)}
		</ul>
	);
};

export const ValueDateSelect = ({
	variant,
	onChange,
	value,
	hasError = false,
	errors,
	currency,
	transactionType,
	disabledDates,
}: {
	value?: string;
	onChange: (value: string) => void;
	variant: EntityType;
	hasError?: boolean;
	errors?: Array<ReactNode>;
	disabledDates?: string[];
	transactionType?: TransactionDirection;
	currency?: string;
}) => {
	const [showAdvanced, setShowAdvanced] = useState(false);
	const { data, isLoading, isValidating, error } = useValueDates(currency);

	const hasTransactionType = !!transactionType;
	const hasData = !!data;

	const isSectionLoading = isLoading || isValidating;

	const current = data?.value_dates.find((item) => item.iso_date === value);

	const label = current
		? `${current.description === "Today" ? `Today, ${toDayjs().format("DD MMM YYYY")}` : toDayjs(current.iso_date).format("ddd, DD MMM YYYY")}`
		: "";

	const getIsValueDateEnabled = useCallback(
		(valueDate: ValueDates["value_dates"][number]) => {
			const key = valueDate.description.toLowerCase();
			const isDisabledDate = disabledDates?.includes(key);
			return valueDate.is_enabled && !isDisabledDate;
		},
		[disabledDates],
	);

	useEffect(() => {
		if (error) {
			handleGeneralError(error);
		}
	}, [error]);

	useEffect(() => {
		if (hasData && variant === "individual" && !current) {
			const match = data.value_dates.find(getIsValueDateEnabled);
			if (match) onChange(match.iso_date);
		}
	}, [hasData, variant, current, data, onChange, getIsValueDateEnabled]);

	useEffect(() => {
		if (
			hasData &&
			variant === "individual" &&
			current &&
			!getIsValueDateEnabled(current)
		) {
			const match = data.value_dates.find(getIsValueDateEnabled);
			if (match) onChange(match.iso_date);
		}
	}, [data, variant, getIsValueDateEnabled, current, hasData, onChange]);

	return (
		<div className={styles.container}>
			{isSectionLoading && <div className={styles.loading} />}
			<div
				className={styles.header}
				style={{
					color: hasTransactionType && hasData ? "#262626" : "#b0b0b0",
				}}
			>
				<p>Value Date</p>
				{data?.tooltips.value_dates_tooltip && (
					<MoreInfoTooltip name="Value Date" hasIcon maxWidth={300}>
						{data?.tooltips.value_dates_tooltip}
					</MoreInfoTooltip>
				)}
			</div>

			{hasData && (
				<div className={styles.inner}>
					{variant === "individual" ? (
						<div>
							<div
								className={styles.toggleContainer}
								data-active={showAdvanced}
							>
								{label}
								<button
									className={styles.toggleButton}
									type="button"
									onClick={() => setShowAdvanced((current) => !current)}
								>
									{showAdvanced ? "Hide" : "Show"} advanced
									<img
										className={styles.toggleIcon}
										data-active={showAdvanced}
										src={chevronUpSrc}
										height={20}
										width={20}
										alt=""
									/>
								</button>
							</div>
							<AnimateHeight duration={200} height={showAdvanced ? "auto" : 0}>
								<ValueDateOptions
									className={styles.advancedOptions}
									currentData={data}
									value={value}
									onChange={onChange}
									variant={variant}
									hasError={hasError}
									disabledDates={disabledDates}
								/>
							</AnimateHeight>
						</div>
					) : (
						<ValueDateOptions
							currentData={data}
							value={value}
							onChange={onChange}
							variant={variant}
							hasError={hasError}
							disabledDates={disabledDates}
						/>
					)}
					{data.tooltips.skipped_days && (
						<p className={styles.skipped}>
							See why certain dates are skipped{" "}
							<MoreInfoTooltip
								name="Value dates skipped"
								maxWidth={512}
								indicator={
									<button className={styles.skippedButton} type="button">
										here
									</button>
								}
							>
								<div
									className={styles.skippedTooltip}
									dangerouslySetInnerHTML={{
										__html: data.tooltips.skipped_days,
									}}
								/>
							</MoreInfoTooltip>
						</p>
					)}
				</div>
			)}
			{errors && errors.length > 0 && (
				<div className="mobile:px-6">
					<ApiErrors errors={errors} />
				</div>
			)}
			<div className={styles.inner}>
				{data?.notes.map((note) => (
					<FadeIn key={note} show>
						<Note variant="full">{note}</Note>
					</FadeIn>
				))}
			</div>
		</div>
	);
};
