import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@app/components/button";
import { Paginator } from "@app/components/paginator";
import { Typography } from "@app/components/typography";
import { paths } from "@app/constants/paths";

import type { RecipientTableModel } from "./models/models";

import { SignedInLayout } from "@app/components/signed-in-layout";

import styles from "./index.module.css";

import { MobileHelp } from "@app/components/mobile-help";
import { Notifications } from "@app/components/notifications";
import { Search } from "@app/components/search";
import type { FilterRecipientsByForm } from "@app/entities";
import { MappedCurrency } from "@app/hooks/use-currencies";
import type { Control } from "react-hook-form";
import { FiMoreVertical, FiUserPlus } from "react-icons/fi";
import { RecipientsQueryState } from ".";
import { FilterByModal } from "./filter-by-modal";
import type { RecipientsState } from "./models/recipients-state";
import { RecipientsMenuModal } from "./recipients-menu-modal";
import { RemoveRecipientModal } from "./remove-recipient-modal";
import { SortModal } from "../../components/sort-modal";
import { CountryIcon } from "@app/components/country-icon";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { LegalDisclaimer } from "@app/components/legal-disclaimer";

const CurrencyTag = ({
	currencies,
	name,
}: {
	currencies: string[];
	name: string;
}) => {
	const firstCurrency = currencies[0];

	if (currencies.length === 1) {
		return (
			<li className={styles.tag}>
				<CountryIcon currencyCode={firstCurrency} width={20} height={20} />
				{firstCurrency.toUpperCase()}
			</li>
		);
	}

	return (
		<MoreInfoTooltip
			name={name}
			indicator={
				<li className={styles.tag}>
					<CountryIcon currencyCode={firstCurrency} width={20} height={20} />
					{firstCurrency.toUpperCase()}
					{currencies.length > 1 && (
						<span className={styles.tagCount}>+{currencies.length - 1}</span>
					)}
				</li>
			}
		>
			<p className={styles.tooltipTitle}>Currencies:</p>
			<ul className={styles.tooltipList}>
				{currencies.map((currency) => (
					<li className={styles.tooltipItem} key={currency}>
						<CountryIcon currencyCode={currency} width={24} height={24} />
						{currency.toUpperCase()}
					</li>
				))}
			</ul>
		</MoreInfoTooltip>
	);
};

export const RecipientsViewResponsive = (props: {
	appliedFilters: RecipientsState;
	currentFilters: RecipientsState;
	control: Control<FilterRecipientsByForm, any>;
	currentPage?: number;
	errors?: string[];
	filterGroupSize: number;
	recipients?: RecipientTableModel[];
	loading?: boolean;
	openDeleteModal: boolean;
	openFilterModal: boolean;
	state: RecipientsQueryState;
	total?: number;
	onAdd: () => void;
	onApplyFilters: () => void;
	onNavigatePage: (
		id: number,
		destination: "sendFunds" | "edit" | "history",
	) => void;
	onRemoveRecipient: (id: number) => void;
	onChangeCountryFilters: (value: string[]) => void;
	onChangeCurrencyFilters: (value: MappedCurrency[]) => void;
	onClearFilters: () => void;
	onDelete: () => void;
	onFilterClosed: () => void;
	onFilterByName: (value: string) => void;
	onPageChange: (page: number, rowsPerPage: number) => void;
	onSortByName: (sortOrder: number, sortField: string) => void;
	onRemoveCountryFilterTag: (value?: string, runFilter?: boolean) => void;
	onRemoveCountryFiltersTag: (value?: string[]) => void;
	onRemoveCurrencyFilterTag: (
		value?: MappedCurrency,
		runFilter?: boolean,
	) => void;
	onRemoveCurrencyFiltersTag: (values?: MappedCurrency[]) => void;
	setOpenFilterModal: (value: boolean) => void;
	setOpenDeleteModal: (value: boolean) => void;
}) => {
	const [showSortModal, setShowSortModal] = useState(false);
	const navigate = useNavigate();

	const [selectedListRecipient, setSelectedListRecipient] =
		useState<RecipientTableModel>();

	const appliedFiltersCount = useMemo(
		() =>
			props.appliedFilters.countries.length +
			props.appliedFilters.currencies.length,
		[props.appliedFilters],
	);

	const isEmpty = props.recipients && props.recipients.length === 0;

	return (
		<SignedInLayout
			title="Recipients"
			mobileRightSection={
				<>
					<MobileHelp />
					<Notifications />
				</>
			}
		>
			<div className="min-h-[calc(100vh-88px-8rem)] p-6">
				<div>
					<div className="mx-0 my-0 mb-4 flex h-14 min-h-14 w-full flex-row gap-4">
						<Search
							placeholder="Search recipients"
							onChange={(event) => {
								const { value } = event.target;
								props.onFilterByName(value);
							}}
							value={props.state.recipient_name || ""}
							onClear={() => {
								props.onFilterByName("");
							}}
						/>
						<button
							type="button"
							aria-label="Add recipient"
							className={styles.addRecipientButton}
							onClick={() => props.onAdd()}
						>
							<FiUserPlus size={24} color="inherit" />
						</button>
					</div>
					<div className={styles.filterSortButtons}>
						<Button
							variant="secondary"
							disabled={props.loading}
							onClick={() => props.setOpenFilterModal(true)}
						>
							Filter {appliedFiltersCount > 0 && `(${appliedFiltersCount})`}
						</Button>
						<Button
							variant="secondary"
							disabled={props.loading}
							onClick={() => setShowSortModal(true)}
						>
							Sort
						</Button>
					</div>
				</div>

				<p className={styles.count}>
					{props.total === undefined
						? "Loading recipients..."
						: `${props.total ?? 0} recipient${
								(props.total ?? 0) === 1 ? "" : "s"
							}`}
				</p>

				{isEmpty && (
					<div className="flex flex-col rounded border border-solid border-gray-105 bg-gray-75 p-6">
						{(props.state.recipient_name ?? "").length === 0 && (
							<>
								<div className="mb-3 flex justify-center">
									<FiUserPlus size={32} color="#888" />
								</div>
								<div className="flex justify-center text-center text-base leading-6 text-gray-650">
									You don't have any recipients added. Add your first one by
									tapping the button above.
								</div>
							</>
						)}
						{(props.state.recipient_name ?? "").length > 0 && (
							<>
								<div className="flex justify-center text-center text-base leading-6 text-gray-650">
									No results for '{props.state.recipient_name}'
								</div>
								<div className="flex justify-center text-center text-base leading-6 text-gray-650">
									Try another search term or use the filters to find a specific
									recipient.
								</div>
							</>
						)}
					</div>
				)}
				<div className={styles.list}>
					{props.recipients?.map((data) => {
						const filteredCurrencies = data.currencies
							.split(",")
							.filter((currency) => !!currency);

						const hasCurrencies = filteredCurrencies.length > 0;

						return (
							<div
								key={data.id}
								className={styles.card}
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									navigate(paths().viewRecipient(data.id));
								}}
							>
								<div
									className={styles.viewButton}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setSelectedListRecipient(data as RecipientTableModel);
									}}
								>
									<FiMoreVertical
										className="h-5 w-5"
										size={24}
										color="#56a7a2"
									/>
								</div>

								<div className="relative mb-4 flex">
									<p className={styles.recipientName}>{data.name}</p>
									<div />
								</div>

								<ul className={styles.tags}>
									{hasCurrencies && (
										<CurrencyTag
											name={data.name}
											currencies={filteredCurrencies}
										/>
									)}
									<li className={styles.tag}>{data.country}</li>
								</ul>
								<hr className="mb-3" />

								<div className="flex flex-row justify-between">
									<div className="flex flex-col">
										<Typography theme="textMd" className="font-semibold">
											Last transaction:
										</Typography>
										<div className="flex justify-start text-[0.875rem] font-normal text-gray-650">
											{data.lastTransactionDate
												? data.lastTransactionDate
												: "-"}
										</div>
									</div>
									<div className="flex min-h-100% flex-col justify-end ">
										<div className="text-[0.875rem] font-normal text-gray-650">
											{data.lastTransactionAmount
												? data.lastTransactionAmount
												: "-"}
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>

				<div className="mt-4">
					<Paginator
						loadMoreCount={
							props.state.limit ? Number.parseInt(props.state.limit) : 10
						}
						onNavigatePage={() => {
							const limit = props.state.limit
								? Number.parseInt(props.state.limit)
								: 10;
							props.onPageChange(props.currentPage ?? 1, limit + 10);
						}}
						totalRecords={props.total || 0}
						currentPage={props.currentPage}
					/>
				</div>

				<RecipientsMenuModal
					onClose={() => {
						setSelectedListRecipient(undefined);
					}}
					selected={selectedListRecipient}
					onSelect={(id, destination) => {
						if (destination === "remove") {
							props.onRemoveRecipient(id);
							setSelectedListRecipient(undefined);
						} else {
							props.onNavigatePage(id, destination);
						}
					}}
					isOpen={!!selectedListRecipient}
				/>

				<RemoveRecipientModal
					onClose={() => props.setOpenDeleteModal(false)}
					isOpen={props.openDeleteModal}
					onRemove={props.onDelete}
				/>
			</div>
			<FilterByModal
				selectedCountries={props.currentFilters.countries}
				selectedCurrencies={props.currentFilters.currencies}
				isOpen={props.openFilterModal}
				onApplyFilters={props.onApplyFilters}
				onChangeCountryFilters={props.onChangeCountryFilters}
				onChangeCurrencyFilters={props.onChangeCurrencyFilters}
				onClose={props.onFilterClosed}
				onClearFilters={props.onClearFilters}
				onRemoveCountryFilterTag={props.onRemoveCountryFilterTag}
				onRemoveCurrencyFilterTag={props.onRemoveCurrencyFilterTag}
			/>
			<SortModal
				title="Sort Recipients"
				fieldOptions={[
					{
						label: "Name",
						key: "recipient_name",
						isDefault: true,
					},
					{
						label: "Country",
						key: "country",
						isDefault: false,
					},
					{
						label: "Last transaction",
						key: "last_transaction_date",
						isDefault: false,
					},
				]}
				sortField={props.state.sortField}
				sortOrder={props.state.sortOrder}
				onChangeSort={props.onSortByName}
				onBack={() => setShowSortModal(false)}
				isOpen={showSortModal}
			/>
			<LegalDisclaimer className={styles.legalDisclaimer} />
		</SignedInLayout>
	);
};
