import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { useCallback } from "react";

export const useUserSettings = () => {
	const handleChangeEmail = useCallback(async (token: string) => {
		return api.post("users/change-email/", {
			token,
		});
	}, []);
	return {
		changePassword: async (currentPassword: string, newPassword: string) => {
			try {
				await api.post("users/change-password/", {
					current_password: currentPassword,
					new_password: newPassword,
				});
			} catch (error) {
				return getFormErrors(error);
			}
		},
		resendChangeEmailLink: async () => {
			try {
				await api.post("users/resend-change-email-link/");
			} catch (error) {
				return getFormErrors(error);
			}
		},
		invalidateChangeEmailLink: async () => {
			try {
				await api.post("users/invalidate-change-email-link/");
			} catch (error) {
				return getFormErrors(error);
			}
		},
		sendChangeEmailLink: async (email: string, password?: string) => {
			return await api.post("users/send-change-email-link/", {
				new_email: email,
				password,
			});
		},
		changeEmail: handleChangeEmail,
	};
};
