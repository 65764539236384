import { type ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./index.module.css";
import { isAppBeta } from "@app/config/env";
import { useClients } from "@app/hooks/use-clients";
import { isAccountsEnabled } from "@app/constants/feature-flags";
import { AccountsBanner } from "./accounts-banner";
interface MobileHeaderProps {
	children: ReactNode;
	hasBackButton?: boolean;
	onBack?: () => void;
	variant?: "sticky" | "absolute" | "fixed";
	rightSection?: ReactNode;
	leftSection?: ReactNode;
	isAddClient?: boolean;
}

export const MobileHeader = ({
	children,
	hasBackButton = false,
	onBack,
	variant = "sticky",
	rightSection,
	leftSection,
	isAddClient = false,
}: MobileHeaderProps) => {
	const navigate = useNavigate();
	const handleBack = () => navigate(-1);

	const { count } = useClients();
	const location = useLocation();
	const isAccountsRoute = location.pathname.includes("/accounts");
	const hasMultipleClients =
		isAccountsEnabled &&
		typeof count === "number" &&
		count > 1 &&
		!isAccountsRoute &&
		variant === "sticky";

	const showBack = hasBackButton || onBack;

	return (
		<div data-variant={variant} className={styles.container}>
			{hasMultipleClients && <AccountsBanner isAddClient={isAddClient} />}
			<header className={styles.header}>
				{leftSection ? (
					leftSection
				) : showBack ? (
					<button
						className={styles.backButton}
						type="button"
						data-testid="back"
						aria-label="Back"
						onClick={onBack ?? handleBack}
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M19 12H5M5 12L12 19M5 12L12 5"
								stroke="#D1D1D1"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				) : null}

				<h1 className={styles.title}>
					{children} {isAppBeta && <span className={styles.beta}>Beta</span>}
				</h1>
				{rightSection ? (
					<div className={styles.rightSection}>{rightSection}</div>
				) : showBack || leftSection ? (
					<div className={styles.placeholder} />
				) : null}
			</header>
		</div>
	);
};
