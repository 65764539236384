import { ReactNode, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";

import { apiUrl } from "../../config/env";

import { Menu, type MenuPassThroughOptions } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";

import logo from "@app/assets/images/logo-bright.svg";
import WalletIcon from "@app/assets/images/wallet.svg";
import { Button } from "@app/components/button";

import { FiHome, FiMail, FiPhone, FiUser } from "react-icons/fi";
import AccountsIcon from "./accounts.svg?react";
import TransactionsIcon from "./transactions.svg?react";

import { AccountManager } from "@app/hooks/use-account-manager";
import styles from "./index.module.css";
import "./sidebar.css";
import { ProfilePicture } from "../profile-picture";
import clsx from "clsx";
import { isAccountsEnabled } from "@app/constants/feature-flags";
import { useClients } from "@app/hooks/use-clients";

type SidebarRoute = {
	path: string;
	icon: ReactNode;
	displayName: string;
	disabled?: boolean;
	childPaths?: string[];
};

export const Sidebar = ({
	accountManager,
	content,
	disableLogoLink = false,
}: {
	accountManager?: AccountManager;
	content?: ReactNode;
	disableLogoLink?: boolean;
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { count } = useClients();

	const hasMultipleClients =
		isAccountsEnabled && typeof count === "number" && count > 1;

	const isAccountsRoute = location.pathname === paths().accounts;
	const isSettingsRoute = location.pathname === paths().userSettings();

	const sidebarRoutes = useMemo<SidebarRoute[]>(() => {
		const isUserRoute = isAccountsRoute || isSettingsRoute;
		if (isUserRoute && hasMultipleClients) {
			return [
				{
					path: paths().accounts,
					icon: <AccountsIcon width={20} height={20} color="#B0B0B0" />,
					displayName: "Accounts",
				},
				{
					path: "/placeholder",
					icon: <TransactionsIcon width={20} height={20} color="#B0B0B0" />,
					displayName: "Transactions (coming soon)",
					disabled: true,
				},
			];
		}

		return [
			{
				path: paths().dashboard,
				icon: <FiHome size={16} color="#D1D1D1" />,
				displayName: "Home",
			},
			{
				path: paths().transactions(),
				icon: <img alt="" src={WalletIcon} width={16} />,
				displayName: "Transactions",
			},
			{
				path: paths().recipients,
				icon: <FiUser color="#D1D1D1" size={16} />,
				displayName: "Recipients",
				childPaths: [
					paths().addRecipient,
					paths().viewRecipient(),
					paths().editRecipient(),
				],
			},
		];
	}, [isAccountsRoute, isSettingsRoute, hasMultipleClients]);

	const onSendOrReceiveFunds = () => {
		navigate(paths().sendReceiveFunds());
	};

	const onAddNewAccount = () => {
		navigate(paths().onboarding.individual.addClient);
	};

	const hasAccountManager = !!accountManager?.name;

	const menuItems: MenuItem[] = useMemo(() => {
		return sidebarRoutes.map((route) => {
			const isActive =
				route.path === location.pathname ||
				route.childPaths?.some((childPath) =>
					location.pathname.startsWith(childPath),
				);
			return {
				url: route.path,
				label: route.displayName || "",
				icon: route.icon,
				disabled: route.disabled,
				command: (event) => {
					if (event.item.url) {
						event.originalEvent.preventDefault();
						event.originalEvent.stopPropagation();
						navigate(event.item.url, {
							preventScrollReset: false,
						});
					}
				},
				className: clsx(isActive && "p-menuitem-active"),
			};
		});
	}, [navigate, sidebarRoutes, location.pathname]);

	const accountManagerPhotoUrl =
		accountManager?.profile_photo && apiUrl
			? apiUrl + accountManager.profile_photo.replace(/^\//, "")
			: "";

	return (
		<div className="side-menu-root">
			{disableLogoLink ? (
				<img className={styles.logo} src={logo} alt="Future Forex" />
			) : (
				<Link
					className={styles.logo}
					to={isAccountsRoute ? paths().accounts : paths().dashboard}
				>
					<img src={logo} alt="Future Forex" />
				</Link>
			)}
			{content ?? (
				<Menu
					pt={
						{
							root: "sidebar-root",
							label: "sidebar-label",
							menuitem: "sidebar-menuitem ",
						} as MenuPassThroughOptions
					}
					model={menuItems}
					id="popup_menu_left"
				/>
			)}

			<div className="margined-section">
				<div>
					{!content && (
						<>
							{isAccountsRoute || isSettingsRoute ? (
								<Button block onClick={onAddNewAccount}>
									Add new account
								</Button>
							) : (
								<Button
									onClick={() => {
										onSendOrReceiveFunds();
									}}
									block
								>
									Send/Receive funds
								</Button>
							)}
						</>
					)}
				</div>

				<div>
					<div
						className="naming-section"
						data-no-account-manager={!hasAccountManager}
					>
						{!hasAccountManager ? null : (
							<ProfilePicture size={40} src={accountManagerPhotoUrl} />
						)}
						<div>
							<p className={styles.name}>
								{hasAccountManager ? accountManager?.name : "Contact us"}
							</p>
							{hasAccountManager && accountManager?.title && (
								<p className={styles.title}>{accountManager?.title}</p>
							)}
						</div>
					</div>
					<div className={styles.contactSection}>
						<a
							className={styles.contactLink}
							href={`mailto:${accountManager?.email ?? "info@futureforex.com"}`}
						>
							<FiMail className={"icon-filled"} size={16} color="#D1D1D1" />
							{accountManager?.email ?? "info@futureforex.com"}
						</a>

						<a
							className={styles.contactLink}
							href={`tel:${accountManager?.contact_number ?? "021 518 0558"}`}
						>
							<FiPhone className={"icon-filled"} size={16} color="#D1D1D1" />
							{accountManager?.contact_number ?? "021 518 0558"}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
