import useSWR from "swr";
import { useClients } from "./use-clients";
import { isAccountsEnabled } from "@app/constants/feature-flags";
import { useLocation } from "react-router-dom";

export type AccountManager = {
	name: string;
	title: string;
	email: string;
	contact_number: string;
	profile_photo: string;
};

export const tempAccountManagerMapping = (data?: AccountManager) => {
	if (!data) return undefined;
	return {
		name: data.name,
		title: data.title,
		email: data.email,
		contactNumber: data.contact_number,
		profilePhoto: data.profile_photo,
	};
};

export const useAccountManager = () => {
	const { activeClientId, count } = useClients();
	const location = useLocation();

	const isAccountsRoute = location.pathname.includes("/accounts");
	const isSettingsRoute = location.pathname.includes("/user-settings");
	const hasMultipleClients =
		isAccountsEnabled && typeof count === "number" && count > 1;

	const shouldShowUserManager =
		hasMultipleClients && (isAccountsRoute || isSettingsRoute);

	return useSWR<AccountManager>(
		shouldShowUserManager
			? "/users/account_manager/"
			: activeClientId
				? `/clients/${activeClientId}/account_manager/`
				: null,
	);
};
