import type { ToastType } from "@app/components/toasts";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	show: boolean;
	type: ToastType;
	message?: string;
	toastId?: string;
};

export const defaultState: State = {
	show: false,
	type: "success",
};

const slice = createSlice({
	name: "toast",
	initialState: defaultState,
	reducers: {
		setShowToast(
			state: State,
			action: PayloadAction<{
				show: boolean;
				type: ToastType;
				message: string;
				toastId?: string;
			}>,
		) {
			state.message = action.payload.message;
			state.show = action.payload.show;
			state.type = action.payload.type;
			state.toastId =
				action.payload.toastId ?? Math.random().toString(16).slice(2);
		},
		setHideToast(state: State) {
			state.message = undefined;
			state.show = false;
			state.type = "success";
			state.toastId = undefined;
		},
	},
});

export const { setHideToast, setShowToast } = slice.actions;

export default slice.reducer;
