import { Dialog } from "@app/components/dialog";
import Lottie from "react-lottie";
import successData from "./success.json";

import { Button } from "@app/components/button";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { ReactNode, useEffect, useState } from "react";
import styles from "./index.module.css";
import ErrorIcon from "./error.svg?react";

const DESKTOP_ICON_SIZE = 74;
const MOBILE_ICON_SIZE = 50;

/**
 * A confirmation modal component that displays a success animation, title, and optional description.
 * @param title - The title to display in the modal.
 * @param description - The optional description to display in the modal.
 * @param onClose - A callback function to be called when the modal is closed.
 */
export const ConfirmedModal = ({
	title,
	description,
	onClose,
	onBack,
	completeText = "Done",
	iconVariant = "success",
	hasMinWidth = true,
	noCloseButton = false,
	isOpen = true,
}: {
	title: string;
	description?: ReactNode;
	onClose: () => void;
	onBack?: () => void;
	completeText?: string;
	hasMinWidth?: boolean;
	iconVariant?: "success" | "error";
	noCloseButton?: boolean;
	isOpen?: boolean;
}) => {
	const isMobile = useMediaQuery();
	const [showAnimation, setShowAnimation] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowAnimation(true);
		}, 500);
		return () => clearTimeout(timeout);
	}, []);

	return (
		<Dialog
			isOpen={isOpen}
			size="md"
			onClose={noCloseButton ? undefined : onClose}
			actions={
				<>
					{onBack && (
						<Button
							minWidth={hasMinWidth ? 110 : undefined}
							variant="secondary"
							onClick={onBack}
						>
							Back
						</Button>
					)}
					<Button
						minWidth={hasMinWidth ? 110 : undefined}
						centered={!onBack}
						onClick={onClose}
					>
						{completeText}
					</Button>
				</>
			}
		>
			<div className={styles.container}>
				{iconVariant === "error" ? (
					<ErrorIcon
						height={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
						width={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
					/>
				) : (
					<Lottie
						options={{
							loop: false,
							autoplay: showAnimation,
							animationData: successData,
						}}
						height={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
						width={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
					/>
				)}
				<h1 className={styles.title}>{title}</h1>
				{description && (
					<>
						{typeof description === "string" ? (
							<p className={styles.description}>{description}</p>
						) : (
							description
						)}
					</>
				)}
			</div>
		</Dialog>
	);
};
