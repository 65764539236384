import removeIconSrc from "@app/assets/images/trash.svg";
import { Dialog } from "@app/components/dialog";
import { FiCalendar, FiEdit2, FiSend } from "react-icons/fi";
import { RecipientTableModel } from "../models/models";

import styles from "./index.module.css";
import { useEffect, useState } from "react";

export const RecipientsMenuModal = ({
	isOpen,
	onClose,
	selected,
	onSelect,
}: {
	isOpen: boolean;
	onClose: () => void;
	selected?: RecipientTableModel;
	onSelect: (
		id: number,
		destination: "sendFunds" | "edit" | "history" | "remove",
	) => void;
}) => {
	const [currentSelected, setCurrentSelected] = useState(selected);

	useEffect(() => {
		if (selected) {
			setCurrentSelected(selected);
		}
	}, [selected]);

	return (
		<Dialog
			bottomsheet
			title={currentSelected?.name}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ul className={styles.list}>
				<li>
					<button
						className={styles.button}
						type="button"
						onClick={() => {
							if (currentSelected) {
								onSelect(currentSelected.id, "sendFunds");
							}
						}}
					>
						<FiSend size={20} color="#56A7A2" />
						Send Funds
					</button>
				</li>
				<li>
					<button
						className={styles.button}
						type="button"
						onClick={() => {
							if (currentSelected) {
								onSelect(currentSelected.id, "edit");
							}
						}}
					>
						<FiEdit2 size={20} color="#56A7A2" />
						Edit
					</button>
				</li>
				<li>
					<button
						className={styles.button}
						type="button"
						onClick={() => {
							if (currentSelected) {
								onSelect(currentSelected.id, "history");
							}
						}}
					>
						<FiCalendar size={20} color="#56A7A2" />
						History
					</button>
				</li>
				<li className={styles.divider} />
				<li>
					<button
						className={styles.button}
						type="button"
						onClick={() => {
							if (currentSelected) {
								onSelect(currentSelected.id, "remove");
							}
						}}
					>
						<img src={removeIconSrc} alt="" width={20} height={20} />
						Remove
					</button>
				</li>
			</ul>
		</Dialog>
	);
};
