import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { useState } from "react";
import { ApiErrors } from "@app/components/api-errors";
import { useTrustDevice } from "@app/features/user-settings/otp-authentication/use-trust-device";

export const ConfirmationDeviceUntrustedModal = ({
	onCancel,
	onConfirm,
	isOpen,
}: {
	onCancel: () => void;
	onConfirm: () => void;
	isOpen: boolean;
}) => {
	const { untrustDevice } = useTrustDevice();
	const [errors, setErrors] = useState<Array<string>>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleConfirm = async () => {
		setIsSubmitting(true);
		const errors = await untrustDevice();
		setIsSubmitting(false);
		if (errors) {
			setErrors(errors.apiErrors);
		} else {
			onConfirm();
		}
	};

	return (
		<Dialog
			isOpen={isOpen}
			size={504}
			title="Revoke trust for this device?"
			description="If you revoke trust for this device, OTP authentication will be required for all future logins."
			actions={
				<>
					<Button
						minWidth={110}
						variant="secondary"
						onClick={onCancel}
						disabled={isSubmitting}
					>
						Cancel
					</Button>
					<Button
						minWidth={110}
						onClick={handleConfirm}
						disabled={isSubmitting}
					>
						Revoke trust
					</Button>
				</>
			}
		>
			<ApiErrors errors={errors} />
		</Dialog>
	);
};
