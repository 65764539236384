import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { ReactNode } from "react";

import styles from "./content-tooltip.module.css";

export const ContentTooltip = ({
	documentName,
	tooltip,
	isEmpty,
}: {
	documentName: string;
	tooltip?: ReactNode;
	isEmpty: boolean;
}) => {
	if (!tooltip) return null;
	if (typeof tooltip !== "string") return <>{tooltip}</>;

	if (isEmpty) {
		return <p className={styles.tooltipDescription}>{tooltip}</p>;
	}

	return (
		<MoreInfoTooltip maxWidth={200} name={documentName}>
			{tooltip}
		</MoreInfoTooltip>
	);
};
