import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { ApiErrors } from "@app/components/api-errors";
import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { FormErrors } from "@app/utils/get-form-errors";
import { formatAndObfuscatePhoneNumber } from "@app/utils/format-and-obfuscate-phone-number";
import loaderSrc from "@app/assets/images/loading.gif";

import successData from "./success.json";

import { DebugOTP } from "../../debug-otp";
import { OTPMethod, useOTP } from "../../use-otp";
import { OTPInput } from "./otp-input";
import styles from "./index.module.css";
import Lottie from "react-lottie";

const OTP_LENGTH = 6;
const SUCCESS_DELAY = 1000;

export const OTPInputModal = ({
	onConfirm,
	onResend,
	onClose,
	contactNumber,
	variant = "standard",
	method,
	title = "Enter OTP",
	isOpen = true,
	description,
}: {
	// biome-ignore lint: Need to support async and sync callbacks
	onConfirm: (token?: string) => void | Promise<FormErrors | void>;
	onClose: () => void;
	onResend: () => void;
	contactNumber?: string;
	variant?: "device" | "standard" | "unauthed";
	method?: OTPMethod;
	title?: string;
	isOpen?: boolean;
	description?: string;
}) => {
	const { otpDetails, verifyOTP, confirmOTPDevice } = useOTP();
	const [errors, setErrors] = useState<Array<string>>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [otp, setOtp] = useState("");
	const isMobile = useMediaQuery();

	const hasErrors = errors.length > 0;
	const contact = contactNumber ?? otpDetails?.contact_number;
	const methodName = method ?? otpDetails?.message_channel;

	const displayMethodName = methodName === "whatsapp" ? "WhatsApp" : "SMS";
	const formattedContact = contact
		? formatAndObfuscatePhoneNumber(contact)
		: null;

	const handleSubmit = useCallback(
		async (token: string) => {
			setIsSubmitting(true);

			if (variant === "unauthed") {
				const errors = await onConfirm(token);
				if (errors) {
					handleResetOnError(errors);
				} else {
					setIsSuccess(true);
				}
				return;
			}

			const request = variant === "standard" ? verifyOTP : confirmOTPDevice;
			const errors = await request(token);

			if (errors) {
				handleResetOnError(errors);
			} else {
				setIsSuccess(true);
				setTimeout(async () => {
					const errors = await onConfirm(token);
					if (errors) handleResetOnError(errors);
				}, SUCCESS_DELAY);
			}
		},
		[variant, verifyOTP, confirmOTPDevice, onConfirm],
	);

	const handleResetOnError = useCallback((errors: FormErrors) => {
		setErrors(errors.apiErrors);
		setIsSubmitting(false);
		setOtp("");
	}, []);

	useEffect(() => {
		if (otp.length === OTP_LENGTH && !isSubmitting && !hasErrors) {
			handleSubmit(otp);
		}
	}, [otp, handleSubmit, isSubmitting, hasErrors]);

	return (
		<Dialog
			isOpen={isOpen}
			size="fit"
			fullscreen={isMobile}
			showTopbar={isMobile}
			onBack={isMobile ? onClose : undefined}
			title={title}
			description={
				description ? (
					<span dangerouslySetInnerHTML={{ __html: description }} />
				) : formattedContact && methodName ? (
					<>
						We have sent an OTP via {displayMethodName} to{" "}
						<strong>{formattedContact}</strong>
					</>
				) : (
					"We have sent an OTP to your number."
				)
			}
			actions={
				<Button
					disabled={isSubmitting}
					centered
					onClick={onClose}
					minWidth={110}
				>
					Back
				</Button>
			}
		>
			<div className={styles.content}>
				<OTPInput
					value={otp}
					onChange={(value) => {
						setErrors([]);
						setOtp(value);
					}}
					containerStyle={clsx(styles.otpInput, hasErrors && styles.error)}
					inputType="number"
					numInputs={OTP_LENGTH}
					skipDefaultStyles
					shouldAutoFocus={hasErrors || otp.length === 0}
					inputStyle={clsx(styles.otpField, hasErrors && styles.errorInput)}
					renderInput={(props) => <input disabled={isSubmitting} {...props} />}
				/>

				<ApiErrors className={styles.errorText} errors={errors} />

				{isSuccess ? (
					<p className={styles.submitting}>
						<Lottie
							style={{ margin: 0 }}
							options={{
								loop: false,
								autoplay: true,
								animationData: successData,
							}}
							height={20}
							width={20}
						/>
						OTP Successful
					</p>
				) : isSubmitting ? (
					<p className={styles.submitting}>
						<img src={loaderSrc} alt="" width={22} height={22} />
						Checking OTP
					</p>
				) : (
					<Button
						className={styles.resend}
						centered
						variant="tertiary"
						noPadding
						onClick={onResend}
					>
						Didn't receive an OTP?
					</Button>
				)}
			</div>
			<DebugOTP />
		</Dialog>
	);
};
