import { OnboardingLayout } from "../onboarding-layout";

import { Card } from "../card";

import { ApiErrors } from "@app/components/api-errors";
import { Checkbox } from "@app/components/checkbox";
import { Dropdown } from "@app/components/dropdown";
import { FieldError } from "@app/components/field-error";
import { Label } from "@app/components/label";
import { paths } from "@app/constants/paths";
import { useCountries } from "@app/hooks/use-countries";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DocumentField } from "../document-field";
import { OnboardingFooter } from "../onboarding-footer";
import styles from "./form.module.css";
import {
	AddressInformation,
	AddressInformationUpdate,
	useAddressInformation,
} from "./use-address-information";

import { fetcher } from "@app/fetcher";
import { useFormErrors } from "@app/hooks/use-form-errors";
import { api } from "@app/services";
import { preload } from "swr";
import { ProvinceField } from "../province-field";
import { Row } from "../row";
import { TextField } from "../text-field";

type Inputs = {
	same_addresses: boolean;
	residential_address_address_1: string;
	residential_address_address_2: string;
	residential_address_city: string;
	residential_address_province: string;
	residential_address_other_province: string;
	residential_address_postal_code: string;
	residential_address_country: string;
	postal_address_address_1: string;
	postal_address_address_2: string;
	postal_address_city: string;
	postal_address_province: string;
	postal_address_other_province: string;
	postal_address_postal_code: string;
	postal_address_country: string;
	proof_of_address: string;
};

const mapFormToDTO = (data: Inputs): AddressInformationUpdate => {
	return {
		same_addresses: data.same_addresses,
		residential_address: {
			address_1: data.residential_address_address_1,
			address_2: data.residential_address_address_2,
			city: data.residential_address_city,
			province: data.residential_address_province || undefined,
			country: data.residential_address_country,
			postal_code: data.residential_address_postal_code,
			other_province: data.residential_address_other_province,
		},
		proof_of_address: data.proof_of_address,
		postal_address: !data.same_addresses
			? {
					address_1: data.postal_address_address_1,
					address_2: data.postal_address_address_2,
					city: data.postal_address_city,
					province: data.postal_address_province || undefined,
					country: data.postal_address_country,
					postal_code: data.postal_address_postal_code,
					other_province: data.postal_address_other_province,
				}
			: null,
	};
};

export const Form = ({ activeClientId }: { activeClientId: number }) => {
	const navigate = useNavigate();
	const [showGeneralError, setShowGeneralError] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const { update, submit } = useAddressInformation();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { data: countries } = useCountries();

	const methods = useForm<Inputs>({
		shouldFocusError: false,
		defaultValues: async () => {
			try {
				const { data } = await api.get<AddressInformation>(
					`/onboarding/${activeClientId}/address-information/`,
				);

				const countries = await preload<Array<string>>(
					"/utilities/countries/",
					fetcher,
				);

				return {
					same_addresses: data.same_addresses,
					residential_address_address_1: data.residential_address.address_1,
					residential_address_address_2: data.residential_address.address_2,
					residential_address_city: data.residential_address.city,
					residential_address_province: data.residential_address.province,
					residential_address_other_province:
						data.residential_address.other_province,
					residential_address_postal_code: data.residential_address.postal_code,
					residential_address_country:
						data.residential_address.country ?? countries[0],
					postal_address_address_1: data.postal_address?.address_1,
					postal_address_address_2: data.postal_address?.address_2,
					postal_address_city: data.postal_address?.city,
					postal_address_province: data.postal_address?.province,
					postal_address_other_province: data.postal_address?.other_province,
					postal_address_postal_code: data.postal_address?.postal_code,
					postal_address_country: data.postal_address?.country,
					proof_of_address: data.proof_of_address,
				} as Inputs;
			} catch {
				setShowGeneralError(true);
				return {} as Inputs;
			}
		},
		mode: "onTouched",
	});
	const { register, handleSubmit, watch, setValue, getValues } = methods;
	const {
		errors,
		apiErrors,
		clearApiFieldErrors,
		onErrors,
		clearErrors,
		onInvalid,
	} = useFormErrors(methods);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		clearApiFieldErrors();
		setIsSubmitting(true);
		setIsSavingChanges(true);

		const errors = await submit(mapFormToDTO(data));
		if (errors) {
			onErrors(errors, true);
		} else {
			setIsSaved(true);
			navigate(paths().onboarding.individual.additionalDetails);
		}
		setIsSavingChanges(false);
		setIsSubmitting(false);
	};

	const handlePartialSave = async () => {
		const values = getValues();
		const data = mapFormToDTO(values);
		if (Object.keys(data).length === 0) return;
		setIsSavingChanges(true);

		const errors = await update(data);
		clearApiFieldErrors();
		if (errors) {
			onErrors(errors);
		} else {
			setIsSaved(true);
		}
		setIsSavingChanges(false);
	};

	const handleNavigate = async (path: string) => {
		if (!path) return;
		if (path === paths().onboarding.individual.personalInformation) {
			navigate(paths().onboarding.individual.personalInformation);
			return;
		}

		const data = getValues();
		const errors = await submit(mapFormToDTO(data));

		if (errors) {
			onErrors(errors, true);
			return;
		}
		navigate(path);
	};

	register("proof_of_address", {
		required: "This field is required",
	});

	return (
		<OnboardingLayout
			step={1}
			onStepNavigate={handleNavigate}
			showError={showGeneralError}
		>
			<FormProvider {...methods}>
				<form
					className={styles.form}
					id="address"
					onSubmit={handleSubmit(onSubmit, onInvalid)}
				>
					<Card
						title="Residential Address"
						actions={
							<div className={styles.sameAsCheckbox}>
								<Checkbox
									id="same-as"
									value={watch("same_addresses") ?? true}
									onChange={(isSameAddress: boolean) => {
										setValue("same_addresses", isSameAddress);
										if (!isSameAddress) {
											setValue("postal_address_address_1", "");
											setValue("postal_address_address_2", "");
											setValue("postal_address_city", "");
											setValue("postal_address_province", "");
											setValue("postal_address_other_province", "");
										}
										handlePartialSave();
									}}
								/>
								<label htmlFor="same-as">This is also my postal address</label>
							</div>
						}
					>
						<Row>
							<TextField
								label="Address line 1*"
								placeholder="Enter address"
								error={errors.residential_address_address_1}
								{...register("residential_address_address_1", {
									required: "This field is required",
									onBlur: handlePartialSave,
								})}
							/>

							<TextField
								label="Address line 2 (optional)"
								placeholder="Enter address"
								error={errors.residential_address_address_2}
								{...register("residential_address_address_2", {
									onBlur: handlePartialSave,
								})}
							/>
						</Row>

						<Row>
							<TextField
								label="City*"
								placeholder="Enter a city"
								{...register("residential_address_city", {
									required: "This field is required",
									onBlur: handlePartialSave,
								})}
								error={errors.residential_address_city}
							/>

							<ProvinceField
								provinceFieldName="residential_address_province"
								otherFieldName="residential_address_other_province"
								countryFieldName="residential_address_country"
								error={
									errors.residential_address_province ||
									errors.residential_address_other_province
								}
								onChange={handlePartialSave}
							/>
						</Row>

						<Row>
							<TextField
								label="Postal code*"
								type="text"
								placeholder="Enter postal code"
								error={errors.residential_address_postal_code}
								{...register("residential_address_postal_code", {
									required: "This field is required",
									onBlur: handlePartialSave,
								})}
							/>

							<div>
								<Label htmlFor="residential_address_country">Country*</Label>
								<Dropdown
									{...register("residential_address_country", {
										required: "This field is required",
										onChange: (event) => {
											setValue("residential_address_country", event.value);
											if (event.value) {
												clearErrors("residential_address_country");
												handlePartialSave();
											}
										},
									})}
									loading={!countries}
									value={watch("residential_address_country")}
									invalid={!!errors.residential_address_country}
									placeholder="Select an country"
									options={countries ?? []}
								/>
								{errors.residential_address_country && (
									<FieldError>
										{errors.residential_address_country.message}
									</FieldError>
								)}
							</div>
						</Row>

						<DocumentField
							style={{ marginBottom: 0 }}
							type="proof_of_address"
							value={watch("proof_of_address")}
							className={styles.proofOfAddressField}
							onChange={async () => {
								if (!activeClientId) return;
								const result = await api.get(
									`/onboarding/${activeClientId}/address-information/`,
								);
								clearErrors("proof_of_address");
								setValue("proof_of_address", result.data.proof_of_address);
							}}
							label="Proof of residential address"
							error={errors.proof_of_address}
						/>
						{watch("same_addresses") && (
							<ApiErrors className={styles.apiErrors} errors={apiErrors} />
						)}
					</Card>
					{watch("same_addresses") ? null : (
						<Card title="Postal Address">
							<Row>
								<TextField
									label="Address line 1*"
									placeholder="Enter address"
									error={errors.postal_address_address_1}
									{...register("postal_address_address_1", {
										required: "This field is required",
										onBlur: handlePartialSave,
									})}
								/>

								<TextField
									label="Address line 2 (optional)"
									placeholder="Enter address"
									error={errors.postal_address_address_2}
									{...register("postal_address_address_2", {
										onBlur: handlePartialSave,
									})}
								/>
							</Row>

							<Row>
								<TextField
									label="City*"
									placeholder="Enter a city"
									{...register("postal_address_city", {
										required: "This field is required",
										onBlur: handlePartialSave,
									})}
									error={errors.postal_address_city}
								/>

								<ProvinceField
									provinceFieldName="postal_address_province"
									otherFieldName="postal_address_other_province"
									countryFieldName="postal_address_country"
									error={
										errors.postal_address_province ||
										errors.postal_address_other_province
									}
									onChange={handlePartialSave}
								/>
							</Row>

							<Row>
								<TextField
									label="Postal code*"
									type="text"
									placeholder="Enter postal code"
									error={errors.postal_address_postal_code}
									{...register("postal_address_postal_code", {
										required: "This field is required",
										onBlur: handlePartialSave,
									})}
								/>

								<div>
									<Label htmlFor="postal_address_country">Country*</Label>
									<Dropdown
										{...register("postal_address_country", {
											required: "This field is required",
											onChange: (event) => {
												setValue("postal_address_country", event.value);
												if (event.value) {
													clearErrors("postal_address_country");
													handlePartialSave();
												}
											},
										})}
										loading={!countries}
										value={watch("postal_address_country")}
										invalid={!!errors.postal_address_country}
										placeholder="Select an country"
										options={countries ?? []}
									/>
									{errors.postal_address_country && (
										<FieldError>
											{errors.postal_address_country.message}
										</FieldError>
									)}
								</div>
							</Row>
							<ApiErrors className={styles.apiErrors} errors={apiErrors} />
						</Card>
					)}
				</form>
			</FormProvider>
			<OnboardingFooter
				onBack={() => {
					handlePartialSave();
					navigate(paths().onboarding.individual.personalInformation);
				}}
				isSubmitting={isSubmitting}
				formId="address"
				isSaving={isSavingChanges}
				hasSaved={isSaved}
			/>
		</OnboardingLayout>
	);
};
