import { type ReactNode, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { pathParams, paths } from "@app/constants/paths";
import type {
	CurrencyOption,
	RecipientForm,
	RecipientType,
	UpdateRecipient,
} from "@app/entities";
import { useShowToast } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { MappedReasons } from "@app/services";

import { AddEditRecipientsViewResponsive } from "./add-edit-recipients-responsive";
import { AddEditRecipientsView } from "./add-edit-recipients-view";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { useClients } from "@app/hooks/use-clients";
import { useCountries } from "@app/hooks/use-countries";
import {
	tempCurrenciesMapping,
	useCurrencies,
} from "@app/hooks/use-currencies";
import { handleScrollToFirstError } from "@app/hooks/use-form-errors";
import { tempMapRecipient, useRecipient } from "@app/hooks/use-recipient";
import { useRecipients } from "@app/hooks/use-recipients";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { EntityType } from "@app/types";
import { ConfirmChangesModal } from "./confirm-changes-modal";
import { useRecipientTypeOptions } from "./use-recipient-type-options";
import { StandardOTPFlow } from "../otp/standard-otp-flow";
import { FormInputProps } from "@app/components/form-builder/types";
import { getIsOTPError } from "../otp/get-is-otp-error";
import { useOTP } from "../otp/use-otp";
import { isOTPAuthEnabled } from "@app/constants/feature-flags";
import { RecipientSuccessModal } from "./recipient-success-modal";
import { getSwiftCodeMode, SwiftCodeMode } from "./get-swift-code-mode";

interface RecipientState {
	mappedReasons?: MappedReasons;
	recipientType?: string;
	paramId?: number;
}

const AddEditRecipients = ({
	variant = "default",
}: { variant?: "default" | "payment-details" }) => {
	const { otpDetails } = useOTP();
	const [successRedirectPath, setSuccessRedirectPath] = useState<
		string | undefined
	>(undefined);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const isMobile = useMediaQuery();
	const [tempRecipient, setTempRecipient] = useState<RecipientForm | undefined>(
		undefined,
	);

	const isOTPSet = isOTPAuthEnabled && otpDetails?.status !== "unknown";

	const { createRecipient } = useRecipients();

	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty },
		reset,
		trigger,
		getValues,
	} = useForm<RecipientForm>({
		mode: "onTouched",
	});

	const [showStandardOTPFlow, setShowStandardOTPFlow] = useState(false);

	const [showToast] = useShowToast();

	const params = useParams();

	const paramId = params[pathParams.id];

	const isEdit = paramId !== undefined && variant !== "payment-details";

	const navigate = useNavigate();

	const { activeClientId } = useClients();
	const {
		data: rawRecipient,
		isLoading: isRecipientLoading,
		mutate,
		updateRecipient,
	} = useRecipient(isEdit ? paramId : undefined);
	const { data: clientProfile } = useClientProfile();

	const { data, isLoading: isRecipientTypeOptionsLoading } =
		useRecipientTypeOptions(activeClientId);
	const entityType = clientProfile?.entity_type;

	const transactionId = useTransactionId();
	const recipient = useMemo(
		() => (rawRecipient ? tempMapRecipient(rawRecipient) : undefined),
		[rawRecipient],
	);

	const recipientTypeOptions = useMemo(() => {
		if (!data) return [];
		return Object.keys(data).map((key) => {
			return {
				type: key,
				label: data[key].label,
				icon: data[key].icon,
			};
		});
	}, [data]);

	const { data: countries } = useCountries();
	const { data: currencies } = useCurrencies();

	const [state, setState] = useState<RecipientState>({
		recipientType: undefined,
		paramId: undefined,
	});

	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);
	const [swiftCodeMode, setSwiftCodeMode] = useState<SwiftCodeMode>("none");

	const getAccountNumberFieldLabel = () => {
		if (isEdit && recipient?.bankDetails?.accountNumber) {
			return "Account Number";
		}
		if (isEdit && recipient?.bankDetails?.iban) {
			return "IBAN";
		}
		return "Account number / IBAN";
	};

	const getAddressFormInputs = () =>
		state.recipientType ? addressInputFields : emptyInputFields;

	const getDetailsFormInputs = () => {
		switch (state.recipientType) {
			case "someone_else":
				switch (swiftCodeMode) {
					case "routingNumber": {
						return someoneElseRoutingInputFields;
					}
					case "sortCode": {
						return someoneElseSortInputFields;
					}
					default: {
						return someoneElseInputFields;
					}
				}
			case "another_company":
			case "your_company":
			case "a_company":
				switch (swiftCodeMode) {
					case "routingNumber": {
						return aCompanyRoutingInputFields;
					}
					case "sortCode": {
						return aCompanySortInputFields;
					}
					default: {
						return aCompanyInputFields;
					}
				}
			case "an_individual":
			case "myself":
				switch (swiftCodeMode) {
					case "routingNumber": {
						return myselfInputRoutingFields;
					}
					case "sortCode": {
						return myselfInputSortFields;
					}
					default: {
						return myselfInputFields;
					}
				}
			default:
				return emptyInputFields;
		}
	};

	const getUserNameAndSurname = () => {
		if (clientProfile) {
			const values = getValues();
			reset({
				...values,
				firstName: clientProfile.first_name,
				lastName: clientProfile.last_name,
			});
		}

		return undefined;
	};

	const formatRecipient = (values: RecipientForm) => {
		const companyTypes: string[] = [
			"a_company",
			"another_company",
			"your_company",
		];

		const recipient: UpdateRecipient = {
			address: {
				addressLine1: values.addressLine1,
				addressLine2: values.addressLine2,
				city: values.city,
				country: values.country,
				postalCode: values.postalCode,
				province: values.province,
			},
			bankDetails: {
				accountNumber: values.accountNumberIban,
				swiftCode: values.swiftCode,
			},
			currencies: values.currencies
				? values.currencies.map((x) => x.currencyCode.toLowerCase()).join(",")
				: "",
			entityType: companyTypes.some((x) => x === state.recipientType)
				? "legal_entity"
				: "individual",
			recipientId: isEdit ? +paramId : undefined,
		};

		if (recipient.bankDetails) {
			if (swiftCodeMode === "sortCode") {
				recipient.bankDetails.sortCode = values.sortCode;
			} else if (swiftCodeMode === "routingNumber") {
				recipient.bankDetails.routingNumber = values.routingNumber;
			}
		}

		if (
			state.recipientType === "a_company" ||
			state.recipientType === "another_company" ||
			state.recipientType === "your_company"
		) {
			recipient.legalEntityName = values.companyName;
		} else if (
			state.recipientType === "an_individual" ||
			state.recipientType === "myself" ||
			state.recipientType === "someone_else"
		) {
			recipient.firstName = values.firstName;
			recipient.lastName = values.lastName;
		}

		const recipientTypeConverted: RecipientType =
			state.recipientType as RecipientType;

		if (
			recipientTypeConverted === "myself" ||
			recipientTypeConverted === "your_company"
		) {
			recipient.isClient = true;
		} else {
			recipient.isClient = false;
		}

		return recipient;
	};

	const handleCreate = async (values: RecipientForm) => {
		setIsSubmitting(true);
		const recipient = formatRecipient(values);
		const [result, errors] = await createRecipient(recipient);

		setIsSubmitting(false);
		if (errors) {
			if (getIsOTPError(errors.apiErrors)) {
				setShowStandardOTPFlow(true);
				setIsSubmitting(false);
				return;
			}

			setApiErrors(errors.apiErrors);
			setState({
				...state,
				mappedReasons: errors.mappedReasons,
			});
			handleScrollToFirstError();
		} else {
			setApiErrors([]);
			setState({
				...state,
				mappedReasons: undefined,
			});

			const path =
				variant === "payment-details" && transactionId
					? paths().paymentDetails(transactionId, result?.id)
					: paths().recipients;

			mutate();
			if (isOTPSet) {
				setSuccessRedirectPath(path);
			} else {
				showToast("Recipient added", "success");
				navigate(path);
			}
		}
	};

	const onBack = () => {
		navigate(
			variant === "payment-details" && transactionId
				? paths().paymentDetails(transactionId)
				: paths().recipients,
		);
	};

	const onCancel = () => {
		if (variant === "payment-details" && transactionId) {
			navigate(paths().paymentDetails(transactionId));
		} else {
			navigate(-1);
		}
	};

	const onClearError = () => setApiErrors([]);

	const handleUpdate = async (values: RecipientForm) => {
		const paramIdExists = paramId !== undefined;

		if (
			paramIdExists &&
			state.paramId &&
			!Number.isNaN(state.paramId) &&
			state.paramId > -1
		) {
			const recipient = formatRecipient(values);
			setApiErrors([]);
			const errors = await updateRecipient(recipient);
			setIsSubmitting(false);

			if (errors) {
				if (getIsOTPError(errors.apiErrors)) {
					setShowStandardOTPFlow(true);
					setIsSubmitting(false);
					return;
				}

				if (errors.apiErrors) setApiErrors(errors.apiErrors);
				setState({
					...state,
					mappedReasons: errors.mappedReasons,
				});
				handleScrollToFirstError();
				return;
			}
			setState({
				...state,
				mappedReasons: undefined,
			});

			const path =
				variant === "payment-details" && transactionId
					? paths().paymentDetails(transactionId)
					: paths().recipients;

			mutate();
			if (isOTPSet) {
				setSuccessRedirectPath(path);
			} else {
				showToast("Changes saved", "success");
				navigate(path);
			}
		} else {
			setIsSubmitting(false);
		}
	};

	const onSelectRecipientType = (value: string) => {
		setState({ ...state, recipientType: value });
	};

	const onChangeSwiftCode = (swiftCode: string) =>
		setSwiftCodeMode(getSwiftCodeMode(swiftCode));

	useEffect(() => {
		const values = getValues();

		if (state.recipientType === "myself") {
			getUserNameAndSurname();
		} else if (state.recipientType === "your_company") {
			reset({
				...values,
				companyName: clientProfile?.display_name ?? "",
			});
		} else if (
			state.recipientType === "a_company" ||
			state.recipientType === "another_company"
		) {
			reset({
				...values,
				companyName: isEdit ? recipient?.companyName : "",
			});
		} else {
			if (!(values.firstName && values.lastName)) {
				reset({
					...values,
					firstName: isEdit ? recipient?.firstName : "",
					lastName: isEdit ? recipient?.lastName : "",
				});
			}
		}
	}, [state.recipientType]);

	useEffect(() => {
		const paramIdAsNumber = isEdit ? +paramId : -1;
		if (Number.isNaN(paramIdAsNumber) || paramIdAsNumber < 0) {
		} else {
			setState((state) => ({ ...state, paramId: paramIdAsNumber }));
		}
	}, [paramId, isEdit]);

	useEffect(() => {
		if (recipient && currencies && entityType) {
			const fetchedCurrencies: CurrencyOption[] = [];
			const mapped =
				currencies?.currency_mapping.map(tempCurrenciesMapping) ?? [];
			recipient.currencies?.split(",").map((x) => {
				const foundCurrency = mapped.find(
					(y) => y.currencyCode.toLowerCase() === x.toLowerCase(),
				);

				if (foundCurrency) {
					fetchedCurrencies.push({
						...foundCurrency,
					});
				}
			});

			reset({
				accountNumberIban:
					recipient?.bankDetails?.accountNumber ?? recipient?.bankDetails?.iban,
				addressLine1: recipient?.address?.addressLine1,
				addressLine2: recipient?.address?.addressLine2,
				city: recipient?.address?.city,
				companyName: recipient?.companyName,
				country: recipient?.address?.country,
				currencies: fetchedCurrencies,
				firstName: recipient?.firstName,
				lastName: recipient?.lastName,
				postalCode: recipient?.address?.postalCode,
				province: recipient?.address?.province,
				routingNumber: recipient?.bankDetails?.routingNumber,
				sortCode: recipient?.bankDetails?.sortCode,
				swiftCode: recipient?.bankDetails?.swiftCode,
			});

			let recipientType: RecipientType | undefined;

			switch (entityType) {
				case "individual": {
					if (recipient.entityType === "individual") {
						if (recipient.isClient) {
							recipientType = "myself";
						} else {
							recipientType = "someone_else";
						}
					} else if (recipient.entityType === "legal_entity") {
						recipientType = "a_company";
					}
					break;
				}
				case "legal_entity": {
					if (recipient.entityType === "individual") {
						recipientType = "an_individual";
					} else if (recipient.entityType === "legal_entity") {
						if (recipient.isClient) {
							recipientType = "your_company";
						} else {
							recipientType = "another_company";
						}
					}
					break;
				}
				default: {
					break;
				}
			}

			setState((state) => ({ ...state, recipientType }));
			onChangeSwiftCode(recipient?.bankDetails?.swiftCode ?? "");
		}
	}, [recipient, currencies, entityType, reset]);

	const accountNumberField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		name: "accountNumberIban",
		mappedName: "accountNumber",
		placeholder: "Enter an account number / IBAN",
		required: true,
		showLabel: true,
		theme: "none",
		title: getAccountNumberFieldLabel(),
		onChange: onClearError,
	};

	const addressLine1Field: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		name: "addressLine1",
		placeholder: "Enter address",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Address Line 1",
		onChange: onClearError,
	};

	const addressLine2Field: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		name: "addressLine2",
		placeholder: "Enter address",
		required: false,
		showLabel: true,
		theme: "none",
		title: "Address Line 2 (optional)",
		onChange: onClearError,
	};

	const cityField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		name: "city",
		placeholder: "Enter a city",
		required: true,
		showLabel: true,
		theme: "none",
		title: "City",
		onChange: onClearError,
	};

	const companyNameField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		disabled: state.recipientType === "your_company",
		hideAsterisk: state.recipientType === "your_company",
		iconSize: 24,
		name: "companyName",
		placeholder: "Enter a company name",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Registered Company Name",
		onChange: onClearError,
	};

	const countryField: FormInputProps = {
		filter: true,
		name: "country",
		options: countries ?? [],
		placeholder: "Select a country",
		required: true,
		showLabel: true,
		title: "Country",
		type: "dropdown",
		panelClassName: "country-dropdown-panel",
		onChange: onClearError,
	};

	const currencyField: FormInputProps = {
		name: "currencies",
		optionLabel: "code",
		placeholder: "Select currencies",
		popover: (
			<MoreInfoTooltip hasIcon maxWidth={300} name="Currency">
				Select currencies typically sent to this recipient to easily identify
				and find them.
			</MoreInfoTooltip>
		),
		showLabel: true,
		title: "Preferred Currencies (optional)",
		type: "multiselect-currency",
		onChange: onClearError,
	};

	const firstNameField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		disabled: state.recipientType === "myself",
		hideAsterisk: state.recipientType === "myself",
		iconSize: 24,
		name: "firstName",
		placeholder: "Enter first name",
		required: true,
		showLabel: true,
		theme: "none",
		title: "First Name",
		onChange: onClearError,
	};

	const lastNameField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		disabled: state.recipientType === "myself",
		hideAsterisk: state.recipientType === "myself",
		iconSize: 24,
		name: "lastName",
		placeholder: "Enter last name",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Last Name",
		onChange: onClearError,
	};

	const postalCodeField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		name: "postalCode",
		placeholder: "Enter postal code",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Postal code",
		onChange: onClearError,
	};

	const provinceField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		name: "province",
		placeholder: "Enter a State / Province",
		required: true,
		showLabel: true,
		theme: "none",
		title: "State / Province",
		onChange: onClearError,
	};

	const routingNumberField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		name: "routingNumber",
		placeholder: "Enter a routing number",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Routing Number",
		onChange: onClearError,
	};

	const spacer: FormInputProps = {
		title: "",
		name: "",
		type: "spacer",
	};

	const sortCodeField: FormInputProps = {
		name: "sortCode",
		title: "Sort Code",
		showLabel: true,
		required: true,
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		placeholder: "Enter a sort code",
		theme: "none",
		onChange: onClearError,
	};

	const swiftCodeField: FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		name: "swiftCode",
		placeholder: "Enter a SWIFT code",
		required: true,
		showLabel: true,
		theme: "none",
		title: "SWIFT Code",
		onCustomValidationRule: (value) => {
			const isSwiftCodeValid = value.length === 8 || value.length === 11;
			return isSwiftCodeValid;
		},
		customErrorMessage: "SWIFT code can only be 8 or 11 letters/numbers",
		onChange: onChangeSwiftCode,
	};

	const addressInputFields: FormInputProps[][] = [
		[addressLine1Field, addressLine2Field],
		[cityField, provinceField],
		[postalCodeField, countryField],
	];

	const emptyInputFields: FormInputProps[][] = [];

	const myselfInputFields: FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		isMobile ? [swiftCodeField] : [swiftCodeField, spacer],
	];

	const myselfInputRoutingFields: FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, routingNumberField],
	];

	const myselfInputSortFields: FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, sortCodeField],
	];

	const someoneElseInputFields: FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, spacer],
	];

	const someoneElseRoutingInputFields: FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, routingNumberField],
	];

	const someoneElseSortInputFields: FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, sortCodeField],
	];

	const aCompanyInputFields: FormInputProps[][] = [
		[companyNameField, currencyField],
		[accountNumberField, swiftCodeField],
	];

	const aCompanyRoutingInputFields: FormInputProps[][] = [
		[companyNameField, currencyField],
		[accountNumberField, swiftCodeField],
		[routingNumberField, spacer],
	];

	const aCompanySortInputFields: FormInputProps[][] = [
		[companyNameField, currencyField],
		[accountNumberField, swiftCodeField],
		[sortCodeField, spacer],
	];

	const detailsFormInputs = getDetailsFormInputs();

	const viewProps = {
		recipientTypeOptions,
		addressFormInputs: getAddressFormInputs(),
		apiErrors,
		detailsFormInputs,
		errors: errors,
		trigger,
		formControl: control,
		isEdit,
		isSubmitting,
		isIndividual: (entityType as EntityType) === "individual",
		isValid: isValid,
		isDirty: isDirty,
		loading:
			(isSubmitting && !tempRecipient) ||
			isRecipientLoading ||
			isRecipientTypeOptionsLoading,
		mappedReasons: state.mappedReasons,
		recipientType: state.recipientType,
		title: "addEditRecipient",
		onBack,
		onCancel,
		onSelectRecipientType,
	};

	const onSubmit = (data: RecipientForm) => {
		setIsSubmitting(true);
		if (isEdit) {
			setTempRecipient(data);
		} else {
			handleCreate(data);
		}
	};

	return (
		<>
			<form
				id="add-edit-recipient-form"
				onSubmit={handleSubmit(onSubmit)}
				noValidate
			>
				{isMobile ? (
					<AddEditRecipientsViewResponsive variant={variant} {...viewProps} />
				) : (
					<AddEditRecipientsView variant={variant} {...viewProps} />
				)}
			</form>

			<ConfirmChangesModal
				isOpen={!!tempRecipient}
				onConfirm={() => {
					handleUpdate(tempRecipient);
					setTempRecipient(undefined);
				}}
				onBack={() => {
					setTempRecipient(undefined);
					setIsSubmitting(false);
				}}
			/>

			{showStandardOTPFlow && (
				<StandardOTPFlow
					onClose={() => setShowStandardOTPFlow(false)}
					onComplete={async () => {
						const data = getValues();
						if (isEdit) {
							await handleUpdate(data);
						} else {
							await handleCreate(data);
						}
						setShowStandardOTPFlow(false);
					}}
				/>
			)}
			{successRedirectPath && (
				<RecipientSuccessModal
					variant={isEdit ? "edit" : "add"}
					onClose={() => {
						navigate(successRedirectPath);
						setSuccessRedirectPath(undefined);
					}}
				/>
			)}
		</>
	);
};

export default AddEditRecipients;
