import { ReactNode, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { ToastPosition, ToastTransition, toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import { useHideToast } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";

import { types } from "./toast-variants";
import { ToastView } from "./toast-view";

export type { ToastType } from "./toast-variants";

export const Toast = (props: {
	autoClose?: number;
	className?: string;
	content?: ReactNode;
	icon?: ReactNode;
	iconClassName?: string;
	showCloseButton?: boolean;
	transition?: ToastTransition;
	onClose?: () => void;
}) => {
	const [hideToast] = useHideToast();
	const isMobile = useMediaQuery();

	const { show, type, message, toastId } = useSelector(
		(state: RootState) => state.toast,
	);

	const onToastClose = useCallback(() => hideToast(), [hideToast]);

	const theme = types[(type as keyof typeof types) ?? "success"];
	const viewProps = useMemo(
		() => ({
			...props,
			content: <span>{message}</span>,
			theme,
			type,
		}),
		[message, props, theme, type],
	);

	const toastOptions = useMemo(
		() => ({
			autoClose: theme.persistent ? false : (props.autoClose ?? 5000),
			className: twMerge(theme.className, props.className),
			closeButton: theme.persistent ? false : (props.showCloseButton ?? false),
			hideProgressBar: true,
			draggable: !theme.persistent,
			closeOnClick: !theme.persistent,
			icon: false,
			position: (isMobile ? "bottom-center" : "top-right") as ToastPosition,
			transition: props.transition,
			toastId,
			onClose: () => {
				onToastClose();
				props.onClose?.();
			},
		}),
		[
			theme.persistent,
			theme.className,
			props.autoClose,
			props.className,
			props.showCloseButton,
			props.transition,
			props.onClose,
			isMobile,
			toastId,
			onToastClose,
		],
	);

	useEffect(() => {
		if (show && toastId) {
			if (!toast.isActive(toastId)) {
				toast.info(<ToastView {...viewProps} />, toastOptions);
			}
		}
	}, [show, toastId, viewProps, toastOptions]);

	return <></>;
};
