import { Button } from "../button";

import { Dropdown } from "@app/components/controls";

import styles from "./index.module.css";

export const Pagination = ({
	limit,
	page,
	count,
	onNavigatePage,
}: {
	limit: number;
	page: number;
	count: number;
	onNavigatePage: (page: number) => void;
}) => {
	const totalPages = Math.ceil(count / limit);

	const items = [...Array(totalPages)].map((_, index) => {
		const pageNumber = index + 1;
		return pageNumber;
	});

	return (
		<div className={styles.container}>
			<Button
				inline
				size="sm"
				variant="secondary"
				className={styles.button}
				onClick={() => onNavigatePage(page - 1)}
				disabled={page <= 1}
			>
				Previous
			</Button>

			<div className={styles.inner}>
				<span>Page</span>
				<Dropdown
					className={styles.dropdown}
					value={page}
					options={items}
					onChange={(value) => onNavigatePage(value)}
				/>
				<span>of {totalPages}</span>
			</div>

			<Button
				inline
				size="sm"
				variant="secondary"
				className={styles.button}
				onClick={() => onNavigatePage(page + 1)}
				disabled={page >= totalPages}
			>
				Next
			</Button>
		</div>
	);
};
