import type { ToastType } from "@app/components/toasts";

import type { AppThunk } from "../store";
import { setHideToast, setShowToast } from "./toast-slice";

export const showToastAction =
	(message: string, type: ToastType, toastId?: string): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(
				setShowToast({
					message: message,
					show: true,
					type: type,
					toastId: toastId,
				}),
			);
		} catch (error: any) {
			dispatch(
				setShowToast({
					message: error,
					show: true,
					type: "error",
				}),
			);
		}
	};

export const hideToastAction = (): AppThunk => async (dispatch) => {
	try {
		dispatch(setHideToast());
	} catch (error: any) {
		dispatch(
			setShowToast({
				message: error,
				show: true,
				type: "error",
			}),
		);
	}
};
