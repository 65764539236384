import { hasFirstAndLastName } from "@app/utils/has-first-and-last-name";

export const getFullName = (personalInformation: any): string => {
	if (hasFirstAndLastName(personalInformation)) {
		const { first_name, middle_names, last_name } = personalInformation;
		return `${first_name} ${middle_names ? `${middle_names} ` : ""}${last_name}`;
	}

	return "New Account";
};
