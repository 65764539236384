import { ConfirmedModal } from "@app/components/confirmed-modal";

export const ConfirmedDisableModal = ({
	onClose,
	isOpen = true,
}: {
	onClose: () => void;
	isOpen?: boolean;
}) => {
	return (
		<ConfirmedModal
			isOpen={isOpen}
			onClose={onClose}
			title="OTP Disabled"
			iconVariant="error"
			description="If you wish to enable OTP authentication in future to secure your account, you can do so in your settings."
		/>
	);
};
