import { isLocalOrTest, isTest2, isProd, isDemo } from "@app/config/env";

export const isPrimaryShareholdersEnabled = false;

export const isOTPAuthEnabled = true;
export const isOTPErrorFromAPIEnabled = true;
export const isUpdatedResentModalEnabled = true;
export const isRelatedEntityDocumentsEnabled = true;
export const isChangeEmailAddressEnabled = true;
export const isUpdateResendOTPModalEnabled = true;
export const isSuperTooltipEnabled = true;
export const isAccountsEnabled = true;
export const isTagSizingUpdateEnabled = true;
export const isHowDidYouHearAboutUsHiddenForReferringCommissionEarner = true;
export const isLegalDisclaimerEnabled = true;
export const isAccountMenuEnabled = true;

export const isOTPErrorTypeEnabled = isTest2 || isProd || isDemo; //BE has not pushed to the other test env's yet but have pushed to prod

export const isConnectionMonitorEnabled = isLocalOrTest;
export const isTrustDeviceSettingsEnabled = isLocalOrTest;
export const isLoginSelectionEnabled = isLocalOrTest;
export const isSSOEnabled = isLocalOrTest;
