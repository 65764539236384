import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

import alertIconSrc from "./alert.svg";

import styles from "./index.module.css";

export const VerifyEmailLinkInvalidModal = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	return (
		<Dialog
			isOpen={isOpen}
			size="lg"
			onClose={onClose}
			actions={
				<Button centered className={styles.dialogButton} onClick={onClose}>
					Done
				</Button>
			}
		>
			<div className={styles.dialogContent}>
				<img
					className={styles.dialogIcon}
					src={alertIconSrc}
					alt=""
					width={60}
					height={60}
				/>
				<h1 className={styles.dialogTitle}>
					Email Verification - Invalid Link
				</h1>
				<p className={styles.dialogDescription}>
					Your activation link appears to be invalid. Please contact us.
				</p>
			</div>
		</Dialog>
	);
};
