import { createRef } from "react";

import { Menu, type MenuPassThroughOptions } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import { FiMoreVertical } from "react-icons/fi";
import { IconButton } from "../icon-button";
import "./hamburger-menu.css";
import clsx from "clsx";

export const HamburgerMenu = ({
	items,
	disabled,
	className,
	iconVariant = "borderless",
}: {
	items: MenuItem[];
	disabled?: boolean;
	className?: string;
	iconVariant?: "default" | "borderless" | "primary";
}) => {
	const menu = createRef<Menu>();

	return (
		<>
			<Menu
				className={clsx("hamburger-menu", className)}
				pt={
					{
						menuitem: "hamburger-menu-menuitem",
						menu: "hamburger-menu-menu",
						root: "hamburger-menu-root",
					} as MenuPassThroughOptions
				}
				popup
				popupAlignment="right"
				ref={menu}
				model={items}
			/>
			<IconButton
				size="sm"
				aria-controls="popup_menu_left"
				disabled={disabled}
				aria-haspopup
				variant={iconVariant}
				onClick={(event) => {
					menu?.current?.toggle(event);
				}}
			>
				<FiMoreVertical size={20} color="inherit" />
			</IconButton>
		</>
	);
};
