export const getPlaceholders = (hasMultipleClients: boolean) => {
	const key = hasMultipleClients ? "thirdPerson" : "firstPerson";

	const pronoun = {
		firstPerson: "your",
		thirdPerson: "their",
	}[key];

	return {
		firstName: `Enter ${pronoun} first name`,
		lastName: `Enter ${pronoun} last name`,
		emailAddress: `Enter ${pronoun} email address`,
		contactNumber: `Enter ${pronoun} contact number`,
		idNumber: `Enter ${pronoun} ID number`,
		taxNumber: `Enter ${pronoun} tax number`,
	};
};
