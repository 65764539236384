import { PageLoader } from "@app/components/page-loader";
import { isChangeEmailAddressEnabled } from "@app/constants/feature-flags";
import { paths } from "@app/constants/paths";
import { useClients } from "@app/hooks/use-clients";
import { useVerifySession } from "@app/wrappers/auth/use-verify-session";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ConfirmEmailUpdate = () => {
	const {
		data,
		isLoading: isVerifySessionLoading,
		error: verifySessionError,
	} = useVerifySession();
	const userIsAuthenticated = !!data?.ok;
	const { isLoading: isClientsLoading, error: clientsErrors } =
		useClients(userIsAuthenticated);
	const navigate = useNavigate();
	const { token } = useParams<{
		token?: string;
	}>();

	useEffect(() => {
		if (isVerifySessionLoading) return;
		if (isClientsLoading) return;

		if (!token) {
			window.location.href = paths().root;
			return;
		}

		if (verifySessionError || clientsErrors || !userIsAuthenticated) {
			navigate(
				`${paths().login}${
					isChangeEmailAddressEnabled ? `?change-email=${token}` : ""
				}`,
			);
			return;
		}

		navigate(paths().userSettings(token));
	}, [
		token,
		userIsAuthenticated,
		isVerifySessionLoading,
		isClientsLoading,
		navigate,
		verifySessionError,
		clientsErrors,
	]);

	return <PageLoader />;
};
