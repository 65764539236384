import { isTagSizingUpdateEnabled } from "@app/constants/feature-flags";
import styles from "./index.module.css";

import receiveSrc from "./receive.svg";
import sendSrc from "./send.svg";

export const PaymentTypeCell = ({ type }: { type?: string }) => {
	if (!type) return null;
	return (
		<div
			data-type={type}
			className={styles.cell}
			data-large={isTagSizingUpdateEnabled}
		>
			{type === "receive" && (
				<img src={receiveSrc} alt="" width={16} height={16} />
			)}
			{type === "send" ? "Send" : "Receive"}
			{type === "send" && <img src={sendSrc} alt="" width={16} height={16} />}
		</div>
	);
};
