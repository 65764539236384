import { ConfirmedModal } from "@app/components/confirmed-modal";

export const ConfirmedDeviceUntrustedModal = ({
	onClose,
	isOpen = true,
}: {
	onClose: () => void;
	isOpen?: boolean;
}) => {
	return (
		<ConfirmedModal
			isOpen={isOpen}
			iconVariant="error"
			onClose={onClose}
			title="Trust revoked"
			description="OTP authentication will be required for future logins on this device."
		/>
	);
};
