import { GenericFailureResponse } from "@app/services";

export const getReasons = (exception: any) => {
	if (exception.detail && typeof exception.detail === "string") {
		return [exception.detail];
	}

	const data = exception.data as GenericFailureResponse;
	if (!data) return ["An unexpected error occurred."];

	if (typeof data === "string") {
		return [data];
	}

	if (data.genericErrors) {
		return [...data.genericErrors];
	}

	if (data.detail) {
		if (typeof data.detail === "string")
			return [data.detail, ...(data.type ? [data.type] : [])];

		if (Array.isArray(data.detail)) {
			if (data.detail.length === 1) return [data.detail[0].msg];
			return [];
		}
	}

	if (data.message) {
		return [data.message];
	}

	if (data.type) {
		return [data.type];
	}

	return ["An unexpected error occurred."];
};
