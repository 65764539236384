import { Dialog } from "@app/components/dialog";
import { AccountStatus, AccountTransactionStatus } from "../use-accounts";
import { useEffect, useState } from "react";
import { Button } from "@app/components/button";

import styles from "./index.module.css";
import { Label } from "@app/components/label";
import { MultiSelectNew } from "@app/features/onboarding/business-company-details/multi-select-new";
import { STATUS_OPTIONS, TRANSACTION_STATUS_OPTIONS } from "../constants";
import { StatusCell } from "../status-cell";
import { TransactionStatusCell } from "../transaction-status-cell";

export const FilterModal = ({
	isOpen,
	onClose,
	status,
	transactionStatus,
	onClearFilters,
	onApplyFilter,
}: {
	onClose: () => void;
	status?: Array<AccountStatus>;
	transactionStatus?: Array<AccountTransactionStatus>;
	isOpen: boolean;
	onClearFilters: () => void;
	onApplyFilter: (
		status: Array<AccountStatus> | undefined,
		transactionStatus: Array<AccountTransactionStatus> | undefined,
	) => void;
}) => {
	const [currentStatus, setCurrentStatus] = useState<
		Array<AccountStatus> | undefined
	>(status);
	const [currentTransactionStatus, setCurrentTransactionStatus] = useState<
		Array<AccountTransactionStatus> | undefined
	>(transactionStatus);

	useEffect(() => {
		setCurrentStatus(status);
		setCurrentTransactionStatus(transactionStatus);
	}, [status, transactionStatus]);

	return (
		<Dialog
			isOpen={isOpen}
			onClose={onClose}
			showTopbar
			fullscreen
			title="Filter"
			actions={
				<>
					<Button variant="secondary" onClick={onClearFilters}>
						Clear all
					</Button>
					<Button
						variant="primary"
						onClick={() =>
							onApplyFilter(currentStatus, currentTransactionStatus)
						}
					>
						Apply
					</Button>
				</>
			}
		>
			<div className={styles.fields}>
				<div>
					<Label htmlFor="status">Status</Label>
					<MultiSelectNew
						confirmText="Done"
						searchable={false}
						mobileHeading="Select a status"
						placeholder="Select a status"
						options={STATUS_OPTIONS.map((status) => ({
							id: status,
							name: status,
							render: <StatusCell>{status}</StatusCell>,
						}))}
						striped={false}
						tagPadding={56}
						onChange={(value) => {
							setCurrentStatus(
								value.map((current) => current.id as AccountStatus),
							);
						}}
						value={
							currentStatus
								? currentStatus.map((current) => ({
										id: current,
										name: current,
										render: <StatusCell>{current}</StatusCell>,
									}))
								: undefined
						}
					/>
				</div>
				<div>
					<Label htmlFor="transaction-status">Transaction status</Label>
					<MultiSelectNew
						searchable={false}
						striped={false}
						tagPadding={72}
						confirmText="Done"
						mobileHeading="Select a transaction status"
						placeholder="Select a transaction status"
						options={TRANSACTION_STATUS_OPTIONS.map((status) => ({
							id: status,
							name: status,
							render: (
								<TransactionStatusCell variant="default">
									{status}
								</TransactionStatusCell>
							),
						}))}
						onChange={(value) => {
							setCurrentTransactionStatus(
								value.map((current) => current.id as AccountTransactionStatus),
							);
						}}
						value={
							currentTransactionStatus
								? currentTransactionStatus.map((current) => ({
										id: current,
										name: current,
										render: (
											<TransactionStatusCell variant="default">
												{current}
											</TransactionStatusCell>
										),
									}))
								: undefined
						}
					/>
				</div>
			</div>
		</Dialog>
	);
};
