import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import checkedSrc from "./checked.svg";
import uncheckedSrc from "./unchecked.svg";

import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import styles from "./index.module.css";

const orderOptions = [
	{
		label: "Ascending",
		key: 1,
	},
	{
		label: "Descending",
		key: -1,
	},
];

export const SortModal = ({
	onBack,
	sortField,
	sortOrder,
	onChangeSort,
	isOpen,
	title,
	fieldOptions,
	confirmText = "Done",
}: {
	fieldOptions: {
		label: string;
		key: string;
		isDefault: boolean;
	}[];
	title: string;
	onBack: () => void;
	sortOrder: number;
	sortField: string;
	confirmText?: string;
	onChangeSort: (sortOrder: number, sortField: string) => void;
	isOpen: boolean;
}) => {
	const isMobile = useMediaQuery();
	return (
		<Dialog
			onClose={onBack}
			fullscreen={isMobile}
			title={title}
			isOpen={isOpen}
			actions={
				<>
					<Button variant="secondary" onClick={onBack}>
						Cancel
					</Button>
					<Button onClick={onBack}>{confirmText}</Button>
				</>
			}
		>
			<ul>
				{fieldOptions.map((option) => (
					<li key={option.key}>
						<button
							className={styles.item}
							aria-current={option.key === sortField}
							type="button"
							onClick={() => onChangeSort(sortOrder, option.key)}
						>
							<p>
								{option.label}{" "}
								{option.isDefault && (
									<span className={styles.default}>(default)</span>
								)}
							</p>
							<img
								src={option.key === sortField ? checkedSrc : uncheckedSrc}
								alt=""
							/>
						</button>
					</li>
				))}
			</ul>
			<div className={styles.divider} />
			<ul>
				{orderOptions.map((option) => (
					<li key={option.key}>
						<button
							className={styles.item}
							type="button"
							aria-current={option.key === sortOrder}
							onClick={() => onChangeSort(option.key, sortField)}
						>
							<p>{option.label}</p>
							<div className={styles.inner}>
								{option.key === 1 ? (
									<FiArrowUp
										size={20}
										color={option.key === sortOrder ? "#56A7A2" : "#B0B0B0"}
									/>
								) : (
									<FiArrowDown
										size={20}
										color={option.key === sortOrder ? "#56A7A2" : "#B0B0B0"}
									/>
								)}
								<img
									src={option.key === sortOrder ? checkedSrc : uncheckedSrc}
									alt=""
								/>
							</div>
						</button>
					</li>
				))}
			</ul>
		</Dialog>
	);
};
