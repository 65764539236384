import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { api } from "@app/services";
import { paths } from "@app/constants/paths";
import { useUserProfile } from "@app/features/user-settings/use-user-profile";
import { useFormErrors } from "@app/hooks/use-form-errors";
import { useClients } from "@app/hooks/use-clients";
import { ApiErrors } from "@app/components/api-errors";
import { Label } from "@app/components/label";
import { NewDocumentField } from "@app/components/new-document-field";
import { hasFirstAndLastName } from "@app/utils/has-first-and-last-name";

import { Card } from "../card";
import { OnboardingFooter } from "../onboarding-footer";
import { OnboardingLayout } from "../onboarding-layout";
import { ContactNumberField } from "../../../components/contact-number-field";
import { DocumentField } from "../document-field";
import { Row } from "../row";
import { TextField } from "../text-field";
import { getPlaceholders } from "./get-placeholders";
import {
	PersonalInformation,
	PersonalInformationUpdate,
	usePersonalInformation,
} from "./use-personal-information";

import styles from "./form.module.css";

type Inputs = {
	first_name: string;
	middle_names: string;
	last_name: string;
	contact_number: string;
	email_address: string;
	id_number: string;
	tax_number: string;
	id_document: string;
	id_selfie: string;
};

export const Form = ({
	isAddClient,
	activeClientId,
}: {
	isAddClient: boolean;
	activeClientId?: number;
}) => {
	const navigate = useNavigate();
	const [showGeneralErrors, setShowGeneralErrors] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isCreated, setIsCreated] = useState(!isAddClient);
	const [isSaved, setIsSaved] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { create, update, submit } = usePersonalInformation();
	const { count } = useClients();
	const { data: userProfile } = useUserProfile();
	const [searchParams] = useSearchParams();

	const hasMultipleClients = typeof count === "number" && count > 1;
	const placeholders = getPlaceholders(hasMultipleClients);

	const mapFormToDTO = (data: Inputs): PersonalInformationUpdate => {
		return {
			...data,
			middle_names: data.middle_names ?? "",
			email_address: data.email_address
				? data.email_address
				: (userProfile?.email ?? ""),
		};
	};

	const methods = useForm<Inputs>({
		shouldFocusError: false,
		defaultValues: async () => {
			try {
				const currentClientId = searchParams.get("clientId") ?? activeClientId;
				if (!currentClientId) return {} as PersonalInformation;

				const { data } = await api.get<PersonalInformation>(
					`/onboarding/${currentClientId}/personal-information/`,
				);
				return data;
			} catch {
				setShowGeneralErrors(true);
				return {} as PersonalInformation;
			}
		},
		mode: "onTouched",
	});
	const { register, handleSubmit, watch, setValue, getValues, trigger } =
		methods;

	const {
		errors,
		clearApiFieldErrors,
		onErrors,
		clearErrors,
		apiErrors,
		onInvalid,
	} = useFormErrors(methods);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		clearApiFieldErrors();
		setIsSubmitting(true);
		setIsSavingChanges(true);

		const errors = await submit(mapFormToDTO(data));

		if (errors) {
			onErrors(errors, true);
		} else {
			const destination = !isAddClient
				? paths().onboarding.individual.addressInformation
				: paths().onboarding.individual.addressInformationWithId(
						searchParams.get("clientId")!,
					);

			setIsSaved(true);
			navigate(destination);
		}
		setIsSavingChanges(false);
		setIsSubmitting(false);
	};

	const handlePartialSave = async () => {
		if (!isCreated) return handleCreate();

		const data = mapFormToDTO(getValues());
		if (Object.keys(data).length === 0) return;
		setIsSavingChanges(true);

		const errors = await update(data);
		clearApiFieldErrors();
		if (errors) {
			onErrors(errors);
		} else {
			setIsSaved(true);
		}
		setIsSavingChanges(false);
	};

	const handleCreate = async () => {
		const values = getValues();
		if (!hasFirstAndLastName(values)) return;

		setIsSavingChanges(true);

		const errors = await create(mapFormToDTO(values));

		clearApiFieldErrors();
		if (errors) {
			onErrors(errors);
		} else {
			setIsCreated(true);
			setIsSaved(true);
		}
		setIsSavingChanges(false);
	};

	const handleNavigate = async (path: string) => {
		if (!path) return;
		const data = getValues();
		const errors = await submit(mapFormToDTO(data));
		if (errors) {
			onErrors(errors, true);
			return;
		}
		navigate(path);
	};

	register("id_document", {
		required: "This field is required",
	});

	register("id_selfie", {
		required: "This field is required",
	});

	return (
		<OnboardingLayout
			step={0}
			onStepNavigate={handleNavigate}
			showError={showGeneralErrors}
			isAddClient={isAddClient}
		>
			<FormProvider {...methods}>
				<Card>
					<form
						id="personal"
						onSubmit={(event) => {
							event.preventDefault();
							const isValid = true;

							if (!isValid) {
								trigger();
								onInvalid();
								return;
							}

							handleSubmit(onSubmit, onInvalid)(event);
						}}
					>
						<Label htmlFor="first_name">Full name*</Label>
						<Row className={styles.nameRow}>
							<TextField
								error={errors.first_name}
								autoComplete="given-name"
								placeholder={placeholders.firstName}
								{...register("first_name", {
									onBlur: handlePartialSave,
								})}
							/>

							<TextField
								error={errors.middle_names}
								placeholder="Enter middle name (optional)"
								{...register("middle_names", {
									onBlur: handlePartialSave,
								})}
							/>

							<TextField
								error={errors.last_name}
								autoComplete="family-name"
								placeholder={placeholders.lastName}
								{...register("last_name", {
									required: "This field is required",
									onBlur: handlePartialSave,
								})}
							/>
						</Row>
						<Row>
							<TextField
								label="Email*"
								error={errors.email_address}
								type="email"
								placeholder={placeholders.emailAddress}
								defaultValue={
									hasMultipleClients
										? watch("email_address")
										: (watch("email_address") ?? userProfile?.email)
								}
								{...register("email_address", {
									required: "This field is required",
									onBlur: handlePartialSave,
								})}
							/>

							<ContactNumberField
								placeholder={placeholders.contactNumber}
								error={errors.contact_number}
								onBlur={() => handlePartialSave()}
							/>
						</Row>
						<Row>
							<TextField
								error={errors.id_number}
								label="ID number*"
								type="number"
								placeholder={placeholders.idNumber}
								{...register("id_number", {
									required: "This field is required",
									validate: (value) => {
										const idNumber = value.replace(/\s/g, "");
										if (idNumber.length !== 13) {
											return "ID number must be 13 characters long";
										}
										if (!/^\d+$/.test(idNumber)) {
											return "ID number must only contain numbers";
										}
										return true;
									},
									onBlur: handlePartialSave,
								})}
							/>

							<TextField
								error={errors.tax_number}
								label="Tax number*"
								type="number"
								placeholder={placeholders.taxNumber}
								{...register("tax_number", {
									required: "This field is required",
									validate: (value) => {
										const taxNumber = value.replace(/\s/g, "");
										if (taxNumber.length !== 10) {
											return "Tax number must be 10 characters long";
										}
										if (!/^\d+$/.test(taxNumber)) {
											return "Tax number must only contain numbers";
										}
										return true;
									},
									onChange: (event) => {
										const value = event.target.value.replace(/\D/g, "");
										setValue("tax_number", value);
									},
									onBlur: handlePartialSave,
								})}
								{...register("tax_number", {
									required: "This field is required",
									validate: (value) => {
										const taxNumber = value.replace(/\s/g, "");
										if (taxNumber.length !== 10) {
											return "Tax number must be 10 characters long";
										}
										if (!/^\d+$/.test(taxNumber)) {
											return "Tax number must only contain numbers";
										}
										return true;
									},
									onChange: (event) => {
										const value = event.target.value.replace(/\D/g, "");
										setValue("tax_number", value);
									},
									onBlur: handlePartialSave,
								})}
							/>
						</Row>

						<NewDocumentField
							className={styles.idDocumentField}
							type="id_document"
							multiple
							label="Certified ID Document"
							tooltip={
								!hasMultipleClients && (
									<span>
										Upload a certified copy (not older than 3 months) of your ID
										Book or <strong>both sides</strong> of your ID Card.
									</span>
								)
							}
							value={watch("id_document")}
							onChange={async () => {
								const clientId = searchParams.get("clientId") ?? activeClientId;
								if (!clientId) return;
								const result = await api.get<PersonalInformation>(
									`/onboarding/${clientId}/personal-information/`,
								);
								setValue("id_document", result.data.id_document);
								clearErrors("id_document");
							}}
							error={errors.id_document}
							disabled={!isCreated}
						/>

						<DocumentField
							className={styles.idSelfieField}
							type="id_selfie"
							tooltip={
								!hasMultipleClients && (
									<>
										Upload a picture of yourself holding your ID document (must
										be legible and clear).
									</>
								)
							}
							tooltipWidth={224}
							value={watch("id_selfie")}
							onChange={async () => {
								const clientId =
									searchParams.get("clientId")! ?? activeClientId;
								if (!clientId) return;
								const result = await api.get<PersonalInformation>(
									`/onboarding/${clientId}/personal-information/`,
								);
								setValue("id_selfie", result.data.id_selfie);
								clearErrors("id_selfie");
							}}
							label="Selfie with ID"
							error={errors.id_selfie}
							disabled={!isCreated}
						/>
						<ApiErrors className={styles.apiErrors} errors={apiErrors} />
					</form>
				</Card>
			</FormProvider>
			<OnboardingFooter
				isSubmitting={isSubmitting}
				formId="personal"
				isSaving={isSavingChanges}
				hasSaved={isSaved}
			/>
		</OnboardingLayout>
	);
};
