import { useEffect, useState } from "react";
import { DeviceModal } from "../modals/device-modal";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { TrustDeviceFlow } from "../trust-device-flow";
import { OTPMethod } from "../use-otp";
import { ConfirmedModal } from "@app/components/confirmed-modal";

export const InitOTPDeviceFlow = ({
	onComplete,
	onClose,
	isOpen,
}: {
	onComplete: () => void;
	onClose: () => void;
	isOpen: boolean;
}) => {
	const [startResendTimer, setStartResendTimer] = useState(false);
	const [methodName, setMethodName] = useState<OTPMethod>();
	const [contactNumber, setContactNumber] = useState("");
	const [step, setStep] = useState<
		"device" | "otp" | "resend" | "success" | "trust" | undefined
	>(undefined);

	useEffect(() => {
		if (isOpen) {
			setStep("device");
			setStartResendTimer(false);
		} else {
			setStep(undefined);
		}
	}, [isOpen]);

	return (
		<>
			<DeviceModal
				isOpen={step === "device"}
				onClose={onClose}
				onConfirm={(contactNumber, methodName) => {
					setContactNumber(contactNumber);
					setMethodName(methodName);
					setStep("otp");
					setStartResendTimer(true);
				}}
			/>

			<OTPInputModal
				isOpen={step === "otp"}
				method={methodName}
				contactNumber={contactNumber}
				onResend={() => {
					setStep("resend");
				}}
				variant="device"
				onClose={() => {
					setStep("device");
				}}
				onConfirm={() => {
					setStep("success");
				}}
			/>

			<ResendOTPModal
				hasOTPBeenSent={startResendTimer}
				isOpen={step === "resend"}
				contactNumber={contactNumber}
				variant="device"
				onConfirm={() => {
					setStep("otp");
				}}
				onClose={() => {
					setStep("otp");
				}}
			/>

			<ConfirmedModal
				isOpen={step === "success"}
				noCloseButton
				onClose={() => {
					setStep("trust");
				}}
				completeText="Continue"
				title="OTP enabled"
			/>

			<TrustDeviceFlow isOpen={step === "trust"} onComplete={onComplete} />
		</>
	);
};
