import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useBalance } from "@app/features/dashboard/use-balance";
import { useClients } from "@app/hooks/use-clients";
import { toDayjs } from "@app/lib/date";

import styles from "./index.module.css";

export const AvailableBalanceDisplay = () => {
	const { activeBankAccountId, isLoading: isClientsLoading } = useClients();
	const { data: balance, isLoading: isBalanceLoading } =
		useBalance(activeBankAccountId);
	if (isClientsLoading || isBalanceLoading || !balance) return null;

	return (
		<div className={styles.container}>
			<p>{balance.net_available_less_swift}</p>
			<p className={styles.available}>Avail.</p>
			<MoreInfoTooltip
				hasIcon
				name="Available Balance"
				maxWidth={280}
				size={18}
			>
				<div>
					Your available balance is the amount you have available considering
					all pending transactions and less R500 for SWIFT fees.
					{balance.last_updated && (
						<p className={styles.lastUpdated}>
							Last updated{" "}
							{toDayjs(balance.last_updated).format("HH:mm on D MMM YYYY")}
						</p>
					)}
				</div>
			</MoreInfoTooltip>
		</div>
	);
};
