import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { Typography } from "@app/components/typography";

export const BopChangedModal = (props: {
	isOpen: boolean;
	onBack: () => void;
	onConfirm: () => void;
}) => {
	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onBack}
			title="Confirm BoP change"
			actions={
				<>
					<Button variant="secondary" onClick={props.onBack}>
						Back
					</Button>
					<Button onClick={props.onConfirm}>Confirm</Button>
				</>
			}
		>
			<div className="flex flex-col gap-y-6 text-center">
				<Typography theme="textMd">
					Note that changing the BoP code may result in different documents
					being required.
				</Typography>
				<Typography theme="textMd">
					Any documents that you had already uploaded that are no longer
					required will be moved to the <strong>Additional Documents</strong>{" "}
					section.
				</Typography>
				<Typography theme="textMd">Would you like to proceed?</Typography>
			</div>
		</Dialog>
	);
};
