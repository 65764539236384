import { useBalance } from "@app/features/dashboard/use-balance";
import { useClients } from "@app/hooks/use-clients";

import styles from "./index.module.css";

const getValue = (value?: string) => {
	if (!value) return undefined;
	const val = value.split(" ")[1];
	return val.replace(/,/g, "");
};

export const MaxAction = ({
	current,
	onChange,
}: {
	current?: string;
	onChange: (value: string) => void;
}) => {
	const { activeBankAccountId, isLoading: isClientsLoading } = useClients();
	const { data: balance, isLoading: isBalanceLoading } =
		useBalance(activeBankAccountId);
	if (isClientsLoading || isBalanceLoading || !balance) return null;

	const maxValue = getValue(balance?.net_available_less_swift);
	const isDisabled = !!current && current === maxValue;

	return (
		<button
			className={styles.button}
			disabled={isDisabled}
			type="button"
			onClick={() => {
				if (maxValue) onChange(maxValue);
			}}
		>
			Max
		</button>
	);
};
