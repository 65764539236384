export const STORAGE_KEYS = {
	ref: "ref",
	refName: "refName",
	arbAddClient: "arb-add-client",
	redirect: "redirect",
	registerEmail: "register-email",
	recentUsername: "recent-username",
	resumeLaterPath: "resume-later-path",
	arbToken: "arb-token",
	loginSelection: "login-selection",
	unverifiedEmail: "unverified-email",
};
