import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@app/components/button";
import { paths } from "@app/constants/paths";
import { useShowToast } from "@app/helpers";
import { useAuth } from "@app/hooks/use-auth";
import { getErrorMessage } from "@app/utils/get-error-message";

import { Title } from "@app/components/title";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { isValidEmail } from "../is-valid-email";
import { EmailIcon } from "../login/email-icon";
import { ResendEmail } from "../resend-email";
import { TextField } from "../text-field";
import styles from "./index.module.css";

interface ForgotPasswordForm {
	username: string;
}

const ForgotPassword = () => {
	const [searchParams] = useSearchParams();
	const [showToast] = useShowToast();
	const [isLoading, setLoading] = useState(false);
	const isMobile = useMediaQuery();
	const { onForgotPassword, onArbitrageForgotPassword } = useAuth();
	const navigate = useNavigate();
	const [email, setEmail] = useState<string | undefined>();

	const isArb = searchParams.get("variant") === "arb";

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ForgotPasswordForm>({
		mode: "onTouched",
	});

	const [isResetCompleted, setResetCompleted] = useState(false);

	const onBack = () => setResetCompleted(false);

	const onCancel = () => {
		navigate(paths().login);
	};

	const handleResendResetLink = async () => {
		if (!email) return false;
		try {
			if (isArb) {
				await onArbitrageForgotPassword(email);
			} else {
				await onForgotPassword(email);
			}
			return true;
		} catch (error) {
			showToast(getErrorMessage(error), "error");
			return false;
		}
	};

	const handleSendResetLink = async (formData: ForgotPasswordForm) => {
		try {
			setLoading(true);
			const email = formData.username;
			if (isArb) {
				await onArbitrageForgotPassword(email);
			} else {
				await onForgotPassword(email);
			}
			setEmail(formData.username);
			setResetCompleted(true);
		} catch (error) {
			showToast(getErrorMessage(error), "error");
		} finally {
			setLoading(false);
		}
	};

	return (
		<form
			onSubmit={handleSubmit((data) => {
				handleSendResetLink(data);
			})}
			noValidate
		>
			{isResetCompleted ? (
				<>
					<Title>
						{isMobile ? "Reset email sent" : "Password reset email sent"}
					</Title>
					<p className={styles.resendDescription}>
						We have sent an email to {isMobile && <br />}
						<strong>{email}</strong>.<br /> Please click the link in your email
						to reset your password.
					</p>

					<p className={styles.resendTitle}>Haven't received an email yet?</p>

					<div className={styles.buttons}>
						<ResendEmail
							email={email}
							onResend={handleResendResetLink}
							variant="password-reset"
						/>
						<Button onClick={onBack} variant="tertiary">
							Back
						</Button>
					</div>
				</>
			) : (
				<>
					<Title>Reset your password</Title>
					<p className={styles.description}>
						Enter your registered email address and we'll send a link to reset
						your password.
					</p>
					<div className={styles.form}>
						<TextField
							register={register}
							name="username"
							label="Email"
							type="email"
							validate={isValidEmail}
							required
							leftSection={<EmailIcon />}
							error={errors.username}
						/>
					</div>
					<div className={styles.buttons}>
						<Button type="submit" block disabled={isLoading}>
							{isLoading ? "Sending" : "Send reset email"}
						</Button>
						<Button
							variant="tertiary"
							onClick={onCancel}
							disabled={isLoading}
							block
						>
							Cancel
						</Button>
					</div>
				</>
			)}
		</form>
	);
};

export default ForgotPassword;
