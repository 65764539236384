import { MappedReasons } from "@app/services";
import { getMappedReasons } from "./get-mapped-reasons";

export type FormErrors = {
	apiErrors: Array<string>;
	fieldErrors: Array<{
		name: string;
		message: string;
	}>;
	mappedReasons?: MappedReasons;
};

export const getFormErrors = (
	error: any,
	fieldNameMapping?: (current: string, parts: string[]) => string,
): FormErrors => {
	console.error(error);

	if (!error?.data?.detail) {
		if (error?.message) {
			return {
				apiErrors: [error.message],
				fieldErrors: [],
				mappedReasons: {},
			};
		}
		return {
			apiErrors: ["An unexpected error occurred. Please try again."],
			fieldErrors: [],
			mappedReasons: {},
		};
	}

	const apiErrors: string[] =
		typeof error.data?.detail === "string" ? [error.data.detail] : [];

	if (typeof error.data?.type === "string") apiErrors.push(error.data.type);

	const fieldErrors: FormErrors["fieldErrors"] = [];

	if (Array.isArray(error.data.detail)) {
		for (const current of error.data.detail) {
			const key = current.loc[current.loc.length - 1];
			const name = fieldNameMapping ? fieldNameMapping(key, current.loc) : key;
			let message = current.msg;
			if (current.type === "value_error.any_str.min_length") {
				message = "This field is required";
			}
			fieldErrors.push({
				name,
				message,
			});
		}
	}

	return {
		apiErrors,
		fieldErrors,
		mappedReasons: getMappedReasons(error.data),
	};
};
