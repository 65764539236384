import { useDispatch } from "react-redux";

import type { ToastType } from "@app/components/toasts";
import type { AppDispatch } from "@app/redux";
import {
	hideToastAction,
	showToastAction,
} from "@app/redux/toast/toast-actions";
import { useCallback } from "react";

export const useHideToast = () => {
	const dispatch: AppDispatch = useDispatch();

	const hideToast = () => {
		dispatch(hideToastAction());
	};

	return [hideToast];
};

export const useShowToast = () => {
	const dispatch: AppDispatch = useDispatch();

	const showToast = useCallback(
		(message: string, type: ToastType, toastId?: string) => {
			dispatch(showToastAction(message, type, toastId));
		},
		[dispatch],
	);

	return [showToast];
};
