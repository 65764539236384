import { useEffect, useState } from "react";
import { ChangeOtpPasswordModal } from "../modals/change-otp-password-modal";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { ConfirmedOTPNumberChangedModal } from "../modals/confirmed-otp-number-changed-modal";
import { ChangeOTPNumberModal } from "../modals/change-otp-number-modal";
import { useOTP } from "../use-otp";
import { getIsOTPError } from "../get-is-otp-error";

export const ChangeOTPNumberFlow = ({
	onComplete,
	onClose,
	isOpen,
}: {
	onComplete: () => void;
	onClose: () => void;
	isOpen: boolean;
}) => {
	const [startResendTimer, setStartResendTimer] = useState(false);
	const { validateCurrentNumber, changeOTPNumber, verifyOTP, otpDetails } =
		useOTP();
	const [password, setPassword] = useState("");
	const [contactNumber, setContactNumber] = useState("");
	const [status, setStatus] = useState<
		| "password"
		| "otp"
		| "resend"
		| "number"
		| "verify"
		| "verify-resend"
		| "confirmed"
		| undefined
	>(undefined);

	useEffect(() => {
		if (isOpen) {
			setStatus("password");
			setStartResendTimer(false);
		} else {
			setStatus(undefined);
		}
	}, [isOpen]);

	return (
		<>
			<ChangeOtpPasswordModal
				isOpen={status === "password"}
				title="Change OTP number"
				description="This will change the mobile number used for OTP authentication. Please enter your password to proceed."
				onConfirm={async (password) => {
					const errors = await validateCurrentNumber(password);
					if (errors && !getIsOTPError(errors.apiErrors)) return errors;
					setPassword(password);
					setStatus("otp");
					setStartResendTimer(true);
				}}
				onClose={onClose}
			/>

			<OTPInputModal
				isOpen={status === "otp"}
				onResend={() => {
					setStatus("resend");
				}}
				onClose={() => {
					setStatus("password");
				}}
				onConfirm={async () => {
					const errors = await validateCurrentNumber(password);
					if (errors) return errors;
					setStatus("number");
				}}
			/>

			<ResendOTPModal
				hasOTPBeenSent={startResendTimer}
				isOpen={status === "resend"}
				onConfirm={() => {
					setStatus("otp");
				}}
				onClose={() => {
					setStatus("otp");
				}}
			/>

			<ChangeOTPNumberModal
				isOpen={status === "number"}
				onClose={onClose}
				onConfirm={async (contactNumber) => {
					const errors = await changeOTPNumber({
						contact_number: contactNumber,
					});
					if (errors && !getIsOTPError(errors.apiErrors)) return errors;
					setContactNumber(contactNumber);
					setStatus("verify");
				}}
			/>

			<OTPInputModal
				isOpen={status === "verify"}
				method={otpDetails?.message_channel}
				title="Verify your new number"
				variant="unauthed"
				contactNumber={contactNumber}
				onResend={() => {
					setStatus("verify-resend");
				}}
				onClose={() => setStatus("number")}
				onConfirm={async (token) => {
					if (!token) return;
					const verifyErrors = await verifyOTP(token);
					if (verifyErrors) return verifyErrors;
					const errors = await changeOTPNumber({
						contact_number: contactNumber,
					});
					if (errors) return errors;
					setStatus("confirmed");
				}}
			/>

			<ResendOTPModal
				hasOTPBeenSent={status === "verify"}
				isOpen={status === "verify-resend"}
				onConfirm={() => {
					setStatus("verify");
				}}
				onClose={() => {
					setStatus("verify");
				}}
			/>

			<ConfirmedOTPNumberChangedModal
				isOpen={status === "confirmed"}
				onClose={onComplete}
			/>
		</>
	);
};
