import { useClientProfile } from "@app/hooks/use-client-profile";
import { paths } from "@app/constants/paths";
import { getFullName } from "@app/utils/get-full-name";
import { usePersonalInformation } from "@app/features/onboarding/individual-personal-information/use-personal-information";

import styles from "./index.module.css";

export const AccountsBanner = ({
	isAddClient = false,
}: {
	isAddClient?: boolean;
}) => {
	const { data: clientProfile } = useClientProfile();
	const { data: personalInformation } = usePersonalInformation();

	const name = !isAddClient
		? clientProfile?.display_name
		: getFullName(personalInformation);

	return (
		<div className={styles.container}>
			<p className={styles.account}>
				<span>Account: </span>
				<span className={styles.name}>
					{name} (
					<button
						className={styles.button}
						onClick={() => {
							window.location.href = paths().accounts;
						}}
						type="button"
					>
						change
					</button>
					)
				</span>
			</p>
		</div>
	);
};
