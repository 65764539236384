import { Button } from "@app/components/button";
import { NoTransactionsBlock } from "@app/components/no-transactions-block";

import { Account } from "../use-accounts";
import { StatusCell } from "../status-cell";
import { TransactionStatusCell } from "../transaction-status-cell";
import { OverflowMenu } from "../overflow-menu";

import styles from "./index.module.css";

export const AccountsList = ({ items }: { items?: Array<Account> }) => {
	if (!items) return <div className={styles.loading} />;

	if (items.length === 0)
		return <NoTransactionsBlock text="No accounts to display" />;

	return (
		<ol className={styles.list}>
			{items.map((item) => {
				const isOnboarding = item.status === "Onboarding";
				return (
					<li className={styles.item} key={item.client_id}>
						<header className={styles.header}>
							<div>
								<p className={styles.name}>{item.name}</p>
								<StatusCell>{item.status}</StatusCell>
							</div>
							<OverflowMenu account={item} isMenuDisabled={isOnboarding} />
						</header>
						{!isOnboarding && (
							<ul className={styles.content}>
								<li>
									<p className={styles.contentLabel}>Balance:</p>
									<p>{item.balance}</p>
								</li>
								<li>
									<p className={styles.contentLabel}>Transaction status:</p>
									<TransactionStatusCell>
										{item.transaction_status}
									</TransactionStatusCell>
								</li>
							</ul>
						)}
						<Button
							block
							onClick={() => {
								window.location.href = `/${item.client_id}`;
							}}
						>
							View {isOnboarding ? "onboarding" : "account"}
						</Button>
					</li>
				);
			})}
		</ol>
	);
};
