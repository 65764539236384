import { useParams, useSearchParams } from "react-router-dom";
import { AxiosProgressEvent } from "axios";
import { saveAs } from "file-saver";
import useSWR from "swr";

import { api } from "@app/services";
import { getFilenameFromHeader } from "@app/utils/get-filename-from-header";
import { getFormErrors } from "@app/utils/get-form-errors";

export type OnboardingDocumentType =
	| "id_document"
	| "id_selfie"
	| "proof_of_address"
	| "registration_document"
	| "organogram_document"
	| "trading_proof_of_address";

export const useOnboardingDocument = (
	type: OnboardingDocumentType,
	relatedEntityId?: number,
) => {
	const [searchParams] = useSearchParams();
	const { clientId } = useParams();
	const activeClientId = searchParams.get("clientId") ?? clientId;

	const pathPrefix = relatedEntityId
		? `/onboarding/related-entity/${relatedEntityId}`
		: `/onboarding/${activeClientId}`;

	const { data, mutate, error } = useSWR<{
		name: "string";
		size: 0;
		extension: "string";
	}>(activeClientId && type ? `${pathPrefix}/document/${type}/details/` : null);

	return {
		data: error ? undefined : data,
		mutate,
		downloadDocument: async () => {
			const { data, headers } = await api.get(
				`${pathPrefix}/document/${type}/`,
				{
					responseType: "blob",
				},
			);
			const fileName = getFilenameFromHeader(headers, `${type}.pdf`);
			saveAs(data, fileName);
		},
		uploadDocument: async (
			file: File,
			onUploadProgress: (event: AxiosProgressEvent) => void,
		) => {
			const formData = new FormData();
			formData.append("file", file);
			await api.post(`${pathPrefix}/document/${type}/`, formData, {
				onUploadProgress,
			});
		},
		deleteDocument: async () => {
			try {
				await api.delete(`${pathPrefix}/document/${type}/`);
				await mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
