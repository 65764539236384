import { ExchangeDetailsBlock } from "@app/components/exchange-details-block";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { paths } from "@app/constants/paths";
import { useSetRecipientId } from "@app/helpers";
import { useGetForexQuoteWebsocket } from "@app/hooks/use-forex-quote-web-socket";
import type { RootState } from "@app/redux";
import * as Sentry from "@sentry/browser";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Note } from "@app/components/note";
import { WarningNote } from "@app/components/warning-note";
import { useClients } from "@app/hooks/use-clients";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { tempCompletedTransactionMapping } from "@app/hooks/use-completed-transactions";
import { useQuoteOptions } from "@app/hooks/use-quote-options";
import { useSystemStatus } from "@app/hooks/use-system-status";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { toDayjs } from "@app/lib/date";
import { sleep } from "@app/utils/sleep";
import { handleGeneralError } from "@app/utils/handle-general-error";
import { isBusinessClient } from "@app/utils/is-business-client";

import { ProcessingModal } from "../../../components/processing-modal";
import { TransactionDetailsFooter } from "../transaction-details-footer";
import { DuplicateTransactionBlock } from "./duplicate-transaction-block";
import { TransactionErrorCard } from "./transaction-error-card";
import { UpNextSection } from "./up-next-section";
import { useQuote } from "./use-quote";
import styles from "./index.module.css";
import { preload } from "swr";
import { fetcher } from "@app/fetcher";
import AnimateHeight from "react-animate-height";
import { LegalDisclaimer } from "@app/components/legal-disclaimer";

const getShowNote = () => {
	const hourWindowToShow = 17;
	const minutesMinWindowToShow = 30;
	const minutesMaxWindowToShow = 30;
	const hour = toDayjs().hour();
	const minutes = toDayjs().minute();
	return (
		hour === hourWindowToShow &&
		minutes >= minutesMinWindowToShow &&
		minutes <= minutesMaxWindowToShow
	);
};

const ConfirmPayment = () => {
	const transactionId = useTransactionId();
	const { activeClientId } = useClients();
	const { data: clientProfile } = useClientProfile();
	const [searchParams] = useSearchParams();
	const [duplicateTransactionId, setDuplicateTransactionId] = useState(
		searchParams.get("duplicateTransactionId"),
	);
	const systemStatusResult = useSystemStatus();
	const [errorResult, setErrorResult] = useState<
		| {
				title: string;
				message: string;
				contactEmail?: string;
				contactNumber?: string;
		  }
		| undefined
	>(undefined);
	const [confirmDuplicate, setConfirmDuplicate] = useState(false);
	const [showConfirmDuplicateError, setShowConfirmDuplicateError] =
		useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [quoteOptions, setQuoteOptions] = useQuoteOptions();

	const { confirmQuote } = useQuote();

	const { recipientId } = useSelector((state: RootState) => state.recipients);

	const navigate = useNavigate();

	const {
		transaction,
		allTransactions,
		isLoading: isLoadingTransactions,
	} = useTransaction(transactionId);
	const forexQuoteValues = useGetForexQuoteWebsocket(
		activeClientId || 0,
		quoteOptions,
	);
	const [setRecipientId] = useSetRecipientId();

	const isTransactionConfirmed = !!transactionId;
	const hasDuplicate = !!duplicateTransactionId && !isTransactionConfirmed;
	const displayNote = getShowNote();

	const isFirstTransaction = allTransactions.length === 0;
	const showFirstTransactionWarning =
		!isLoadingTransactions &&
		!isTransactionConfirmed &&
		isFirstTransaction &&
		isBusinessClient(clientProfile?.entity_type);

	const recipient = useRef<number | undefined>(undefined);

	const onBack = () => {
		if (transactionId) {
			navigate(paths().transactions());
		} else {
			navigate(-1);
		}
	};

	const handleNext = () => {
		if (!transactionId) return;
		Sentry.addBreadcrumb({
			category: "transaction",
			level: "info",
			message: "Has reviewed transaction details",
			data: {
				transactionId,
			},
		});
		navigate(paths().paymentDetails(transactionId));
	};

	const handleBooking = async () => {
		setShowConfirmDuplicateError(false);

		if (hasDuplicate && !confirmDuplicate) {
			setShowConfirmDuplicateError(true);
			return;
		}

		recipient.current = recipientId;

		setIsLoading(true);

		if (!forexQuoteValues.value?.quoteId) {
			Sentry.addBreadcrumb({
				level: "error",
				category: "transaction",
				message: "No quoteId",
				data: {
					transactionId,
				},
			});
			handleGeneralError();
			return;
		}

		const duplicateTransactionResult = await preload<{
			transaction_id: number | null;
		}>(
			`/transactions/quotes/${forexQuoteValues.value.quoteId}/duplicate-trx/`,
			fetcher,
		);

		if (duplicateTransactionResult?.transaction_id && !confirmDuplicate) {
			setDuplicateTransactionId(
				duplicateTransactionResult.transaction_id.toString(),
			);
			setIsLoading(false);
			return;
		}

		const startTime = Date.now();

		const [result, error] = await confirmQuote(forexQuoteValues.value.quoteId, {
			valueDate: quoteOptions?.valueDate || "",
			duplicateTransactionId:
				hasDuplicate && isDuplicateTransactionCheckEnabled
					? Number.parseInt(duplicateTransactionId, 10)
					: undefined,
		});

		Sentry.addBreadcrumb({
			category: "transaction",
			level: "info",
			message: "Has confirmed quote",
			data: {
				quoteId: forexQuoteValues.value.quoteId,
				valueDate: quoteOptions?.valueDate || "",
			},
		});

		const pastTime = Date.now() - startTime;

		if (pastTime < 2000) {
			await sleep(2000 - pastTime);
		}

		if (error) {
			setIsLoading(false);

			if (error?.title && error?.message) {
				setErrorResult(error);
				return;
			}
			handleGeneralError(error);
			return;
		}

		if (!result?.transaction_id) {
			Sentry.addBreadcrumb({
				level: "error",
				category: "transaction",
				message: "No transaction_id in response",
				data: {
					...result,
				},
			});
			handleGeneralError();
			return;
		}

		setShowSuccess(true);
		setTimeout(() => {
			setIsLoading(false);
			setShowSuccess(false);
			navigate(
				paths().confirmPayment({
					id: result.transaction_id,
				}),
			);
		}, 4000);
	};

	useEffect(() => {
		if (systemStatusResult.data) {
			if (!systemStatusResult.data.transactions_enabled && transactionId) {
				navigate(paths().error.fundConfirmBankClosed(transactionId));
			}
		}
	}, [systemStatusResult.data, navigate, transactionId]);

	useEffect(() => {
		return () => {
			if (!recipient.current) {
				setRecipientId(undefined);
			}
		};
	}, []);

	useEffect(() => {
		if (quoteOptions === undefined && !transactionId && !isLoading) {
			navigate(-1);
		}
	}, [quoteOptions, transactionId, navigate, isLoading]);

	useEffect(() => {
		if (transactionId) {
			setQuoteOptions(undefined);
		}
	}, [transactionId, setQuoteOptions]);

	const isActionsDisabled =
		isLoading || (!isTransactionConfirmed && !forexQuoteValues.value?.quoteId);

	return (
		<SignedInLayout
			hasBackButton
			hideBottomNav
			title="Send/Receive funds"
			footer={
				<TransactionDetailsFooter
					isFailed={!!errorResult}
					isLoading={isActionsDisabled}
					onBack={onBack}
					onNext={isTransactionConfirmed ? handleNext : handleBooking}
					resumeLaterPath={paths().transactions()}
					isResumeLaterSecondaryAction={isTransactionConfirmed}
					customNextLabel={isTransactionConfirmed ? "Next" : "Confirm exchange"}
				/>
			}
		>
			<div className={styles.contentContainer}>
				{showFirstTransactionWarning && (
					<WarningNote
						title="Please note"
						className={styles.firstTransactionWarning}
					>
						Submitting incorrect or insufficient supporting documentation may
						result in the cancellation of your transaction and additional fees
						being incurred.
					</WarningNote>
				)}

				{errorResult ? (
					<TransactionErrorCard {...errorResult}>
						<ExchangeDetailsBlock
							displayHeadingAsMain
							overrideHeading={
								<div>
									<h3 className={styles.failedTitle}>Transaction details</h3>
									<p className={styles.failedDescription}>
										Please take note of these details should you wish to start a
										new transaction
									</p>
								</div>
							}
							isLoading={isLoading}
							showAsOpen={!transactionId}
							overrideShowPaymentDetails={transactionId ? undefined : true}
							transaction={
								transaction
									? tempCompletedTransactionMapping(transaction)
									: undefined
							}
							forexQuoteValues={forexQuoteValues.value}
							quoteOptions={quoteOptions}
						/>
					</TransactionErrorCard>
				) : (
					<div className={styles.container}>
						<AnimateHeight
							duration={300}
							height={
								duplicateTransactionId && !isTransactionConfirmed ? "auto" : 0
							}
						>
							<DuplicateTransactionBlock
								transactionId={
									duplicateTransactionId
										? Number.parseInt(duplicateTransactionId, 10)
										: undefined
								}
								checked={confirmDuplicate}
								showError={showConfirmDuplicateError}
								onChange={setConfirmDuplicate}
							/>
						</AnimateHeight>
						<ExchangeDetailsBlock
							variant={isTransactionConfirmed ? "confirmation" : "booking"}
							displayHeadingAsMain
							showAsOpen={!transactionId}
							overrideShowPaymentDetails={transactionId ? undefined : true}
							transaction={
								transaction
									? tempCompletedTransactionMapping(transaction)
									: undefined
							}
							forexQuoteValues={forexQuoteValues.value}
							quoteOptions={quoteOptions}
						/>
						{displayNote && (
							<Note variant="fit">
								Transactions can be processed up till 17h30. If you'd like to
								proceed, please confirm before then.
							</Note>
						)}
						{transactionId && <UpNextSection />}
					</div>
				)}
				<ProcessingModal
					isOpen={isLoading || showSuccess}
					showSuccess={showSuccess}
				/>
			</div>
			<LegalDisclaimer className={styles.legalDisclaimer} />
		</SignedInLayout>
	);
};

export default ConfirmPayment;
